import styled from "styled-components";
import { FLEX } from "styles/flex";
import {
  COLORS,
  LIGHT_THEME_COLORS,
  MAP_STATUS_COLORS,
  statusToColor,
} from "styles/colors";
import { calcRem } from "styles/theme";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import {
  DashboardAlertData,
  DashboardData,
} from "api/interfaces/dashboardInterface.interface";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { convertType } from "utils/functions";

interface InfoViewProps {
  system: DashboardData;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export default function RecorderInfoView(props: InfoViewProps): JSX.Element {
  const total: DashboardAlertData[] = [
    ...props.system.alert,
    ...props.system.assign,
    ...props.system.critical,
    ...props.system.warning,
  ];

  return (
    <Wrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <RecorderName>
        <div className="recorder-text type">
          {convertType(props.system.type)}
        </div>
        <div className="recorder-text name">
          {props.system.mergedSystemName}
        </div>
        {props.system.type === "Spectrum" && (
          <div className="merged-name">{props.system.name}</div>
        )}
      </RecorderName>
      <RecorderImage>
        <VideoThumbnail
          videoLoss={false}
          thumbnailUrl={props.system.thumbnail}
        />
      </RecorderImage>
      <AlertList>
        {/* {total.length === 0 && (
          <AlertStatus status="RESOLVED">No Alerts</AlertStatus>
        )} */}
        {/* {props.system.warning !== undefined &&
          props.system.warning.length > 0 && (
            <AlertStatus status="WARNING">
              <BsFillExclamationCircleFill
                size={13}
                color={MAP_STATUS_COLORS.WARNING}
              />
              {props.system.warning.length}
            </AlertStatus>
          )} */}
        {/* {props.system.assign !== undefined &&
          props.system.assign.length > 0 && (
            <AlertStatus status="ASSIGNED">
              <BsFillExclamationCircleFill
                size={13}
                color={MAP_STATUS_COLORS.ASSIGNED}
              />
              {props.system.assign.length}
            </AlertStatus>
          )} */}
        {props.system.alert !== undefined && props.system.alert.length > 0 && (
          <AlertStatus status="ALERT">
            <BsFillExclamationCircleFill
              size={13}
              color={MAP_STATUS_COLORS.ALERT}
            />
            {props.system.alert.length}{" "}
          </AlertStatus>
        )}
        {props.system.critical !== undefined &&
          props.system.critical.length > 0 && (
            <AlertStatus status="CRITICAL">
              <BsFillExclamationCircleFill
                size={13}
                color={MAP_STATUS_COLORS.CRITICAL}
              />
              {props.system.critical.length}
            </AlertStatus>
          )}
        {(total.length === undefined || total.length === 0) && (
          <AlertStatus status="RESOLVED">No Alerts</AlertStatus>
        )}
      </AlertList>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.value === "light" ? LIGHT_THEME_COLORS.FILTERITEM : COLORS.PRIMARY1};
  border-radius: 8px;
  padding: 14px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;
  width: 200px;
`;

const RecorderName = styled.div`
  width: 100%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 3px;

  line-height: ${calcRem(16)};

  .recorder-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${calcRem(13)};
    white-space: nowrap;
    font-weight: 500;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .name {
    font-size: ${calcRem(17)};
  }

  .type {
    color: ${({ theme }) => theme.COLORS.SELECTED};
  }

  .merged-name {
    font-size: ${calcRem(15)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

const RecorderImage = styled.div`
  width: 100%;
`;

const AlertList = styled.ul`
  ${FLEX.FlexStartCenter};
  gap: 8px;
`;
const AlertStatus = styled.li<{ status: string }>`
  ${FLEX.FlexStartCenter};
  gap: 4px;
  color: ${({ status }) => MAP_STATUS_COLORS[status]};
  font-size: ${calcRem(13)};
  flex: 1;
`;
