import * as s from "./ButtonStyled";
import PlusCircleIcon from "assets/icons/plus_in_circle.svg";
import MapIcon from "assets/icons/map.svg";

type PrimaryButtonProps = {
  buttonType?: string;
  add?: boolean;
  map?: boolean;
  label: string;
  icon?: any;
  onClickBtn: () => void;
  disabled?:boolean;
};

function PrimaryButton({
  add,
  map,
  label,
  icon,
  onClickBtn,
  buttonType,
  disabled
}: PrimaryButtonProps): JSX.Element {
  return (
    <s.Container
      disabled={disabled}
      buttonType={buttonType ? buttonType : "primary"}
      onClick={onClickBtn}
    >
      {add && <img alt="add button" src={PlusCircleIcon} />}
      {map && <img alt="map button" src={MapIcon} />}
      <s.ButtonLabel>{label}</s.ButtonLabel>
      {icon && icon}
    </s.Container>
  );
}

export default PrimaryButton;
