import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { DataRow, UsersPresenter } from "./../UsersPresenter";
import * as s from "./../UsersStyled";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";

import { getGroupList } from "api/userAPI";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { Group } from "api/interfaces/userInterface.interface";
import { createGroup, deleteGroup } from "api/userAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { FaRegUser } from "react-icons/fa";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { isCheckPermission } from "utils/AccountUtil";
import { useAuth } from "components/Auth";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import useApiError from "hook/useApiError";
import { useLocation, useNavigate } from "react-router-dom";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";

export function GroupsContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const { handleError } = useApiError();
  const menuType: string = "group";
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const auth = useAuth();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [status, setStatus] = useState({
    totalTitle: "Total Groups",
    totalValue: 0,
  });

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageUsersAndGroups", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageUsersAndGroups", selectedAccount)
      );
    }
  }, [selectedAccount]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setGroupQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setGroupQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "name",
          sortDirection: SORT_DIRECTION.ASC,
        };
      });
    }
  }, [location]);

  const [pending, setPending] = useState<boolean>(true);

  const [selectedEdit, setSelectedEdit] = useState<Group>();
  const [groups, setGroups] = useState<DataRow[]>([]);
  const [groupQueryInfo, setGroupQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  // user roles
  const columns: TableColumn<DataRow>[] = [
    {
      id: "name",
      name: "Name",
      grow: 3,
      cell: (row) => (
        <s.Row isAdmin={!row.enableDelete}>
          <div>
            <FaRegUser size={16} />
          </div>
          {row.name}
        </s.Row>
      ),
      sortable: true,
      sortField: "name",
    },
    {
      name: "Host",
      grow: 5,
      cell: (row) => (
        <s.Text isDeleted={!row.hostValidation}>
          {row.enableDelete
            ? row.hostValidation
              ? row.host!
              : `${row.host} - Delete User`
            : "Provided by MyDW"}
        </s.Text>
      ),
      sortable: true,
      sortField: "hostName",
    },
    {
      name: "Users",
      grow: 1,
      selector: (row) => row.userCnt!,
      center: true,
      // maxWidth: "50px",
    },
    {
      name: "Description",
      grow: 5,
      selector: (row) => row.description,
      sortable: true,
      sortField: "description",
      hide: Media.MD,
    },
    // 마지막 버튼
    {
      name: "",
      // minWidth: "150px",
      cell: (row) => (
        <RBACWrapper requiredPermissions="manageUsersAndGroups">
          <s.Row>
            <span className="row-click-button">
              <TableButton
                label={
                  selectedAccount.isAdmin || row.email === auth.user.email
                    ? "Edit"
                    : "View"
                }
                onClickBtn={() => {
                  setSelectedEdit(row.raw as Group);
                  onModal("edit");
                }}
              />
            </span>
          </s.Row>
        </RBACWrapper>
      ),
      right: true,
    },
  ];

  const onRowClicked = (row: DataRow) => {
    if (isCheckPermission("manageUsersAndGroups", selectedAccount)) {
      setSelectedEdit(row.raw as Group);
      onModal("edit");
    }
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("group");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("group");
      }
      setIsEditModal(!isEditModal);
    }
  };

  const { error, refetch } = useQuery(
    ["group", groupQueryInfo],
    () =>
      getGroupList({
        payload: groupQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setGroups([]);
          setStatus((info) => {
            return { ...info, totalValue: 0 };
          });
          return;
        }

        setGroups(
          res.result.map((group: any) => {
            return {
              id: group.groupId as string,
              name: group.name,
              host:
                group.hostName !== undefined
                  ? group.hostName +
                    (group.hostEmail !== undefined
                      ? " (" + group.hostEmail + ")"
                      : "")
                  : "",
              email: group.hostEmail,
              userCnt: group.hasUsers,
              description: group.description,
              enableDelete: group.enableDelete,
              hostValidation: group.hostValidation,
              raw: group,
            };
          })
        );
        setStatus({
          totalTitle: "Total Groups",
          totalValue: res.page.total,
        });
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.userGroup.notify.list.fail",
            defaultMessage: "User group search error.",
          })
        );
        setGroups([]);
        setStatus((info) => {
          return { ...info, totalValue: 0 };
        });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const mutationDeleteGroup = useMutation(deleteGroup, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.userGroup.notify.delete.success",
          defaultMessage: "User group deleted successfully.",
        })
      );
      queryClient.invalidateQueries("group");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.userGroup.notify.delete.fail",
          defaultMessage: "Fail to delete User group.",
        })
      );
      // notify(
      //   "error",
      //   intl.formatMessage({
      //     id: "label.userGroup.notify.delete.fail",
      //     defaultMessage: "Fail to delete User group.",
      //   })
      // );
    },
  });

  const mutationCreateGroup = useMutation(createGroup, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.userGroup.notify.add.success",
          defaultMessage: "User group added successfully.",
        })
      );
      queryClient.invalidateQueries("group");
      setIsAddModal(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.userGroup.notify.add.fail",
          defaultMessage: "Fail to add User group.",
        })
      );
    },
  });

  const onClickSave = useCallback(
    (group: Group) => {
      mutationCreateGroup.mutate({
        accountId: selectedAccount.accountId,
        payload: group,
      });
    },
    [mutationCreateGroup, selectedAccount.accountId]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);
      if (page !== groupQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: groupQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: groupQueryInfo.keyword as string,
            sortType: groupQueryInfo.sortType,
            sortDirection: groupQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
        // setGroupQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      groupQueryInfo.keyword,
      groupQueryInfo.pageLimit,
      groupQueryInfo.pageNum,
      groupQueryInfo.sortDirection,
      groupQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if(groupQueryInfo.keyword !== keyword){
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: groupQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: groupQueryInfo.sortType,
            sortDirection: groupQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      }else{
        refetch();
      }
      setPending(true);
      // setGroupQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [groupQueryInfo.keyword, groupQueryInfo.pageLimit, groupQueryInfo.sortDirection, groupQueryInfo.sortType, location.pathname, navigate, refetch, selectedAccount]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setGroupQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };

  return (
    <UsersPresenter
      menuType={menuType}
      columns={columns}
      data={groups}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      status={status}
      onClickSave={onClickSave}
      onChangePage={onChangePage}
      selectedEdit={selectedEdit}
      onClickSearch={onClickSearch}
      onRowClicked={onRowClicked}
      handleSort={handleSort}
      isRowClickEditable={isRowClickEditable}
      initialPending={pending}
      paginationInfo={groupQueryInfo}
      isSwitchModal={false}
      isCreateLoading={mutationCreateGroup.isLoading}
      isUpdateLoading={mutationCreateGroup.isLoading}
    />
  );
}
