import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";

import Toggle from "components/atoms/toggle/Toggle";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
  Validate,
} from "api/interfaces/commonInterface.interface";
import { Group, UserDto } from "api/interfaces/userInterface.interface";
import {
  deactiveProfile,
  deletableUser,
  deleteUser,
  getGroupList,
  getGroupListForEdit,
  getUserDetail,
} from "api/userAPI";
import type { Option } from "utils/options";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

import Deactivate from "../deactivate";
import { notify } from "components/atoms/notification/Notification";

import SelectsCombo from "components/atoms/select/SelectCombo";
import { MultiValue } from "react-select";
import { isEmpty } from "lodash";
import { checkEmailRegEx } from "utils/regEx";
import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { useAuth } from "components/Auth";
import { isPhoneNumberValid } from "utils/validHelper";
import Spinner from "components/atoms/loader/Spinner";
import styled from "styled-components";

interface Props {
  isEdit?: boolean; // 편집 모드일 때
  onModal: (type?: string) => void;
  onClickSave: (userDto: UserDto, update?: boolean) => void;
  selectedUser: UserDto | undefined;
  onClickDeactive?: (userId: string) => void;
  isLoading: boolean;
}

export enum UPDATE_TYPE {
  NAME,
  EMAIL,
  GROUP,
  COMPANY_NAME,
  COMPANY_TEL,
  DEPARTMENT,
  USER_GROUP,
  DESC,
}

function AddUser(props: Props): JSX.Element {
  const intl = useIntl();
  const { user, logout } = useAuth();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isActivate, setIsActivate] = useState<boolean>(true);
  const [isDeactivateMode, setIsDeactivateMode] = useState<boolean>(false);
  // 토글 상태
  // 우선 한가지로 통일함. ** 추후 상태 지정해줘야함
  const [isAlertToggled, setisAlertToggled] = useState(true);
  const [isReportToggled, setisReportToggled] = useState(true);
  const [isPushToggled, setisPushToggled] = useState(true);

  const [isSelfDeActive, setIsSelfDeActive] = useState<boolean>(false);

  // 나라 선택
  const [isFlagSelected, setIsFlagSelected] = useState("US");

  const [selectGroup, setSelectGroup] = useState<Option[]>([]);

  const [validate, setValidate] = useState<Validate>({
    name: false,
    email: false,
    group: false,
    companyPhone: false,
    description: false,
  });
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    name: "",
    email: "",
    group: "",
    companyPhone: "",
    description: "",
  });

  // 토글 onchange 함수
  const onChange1 = (checked: boolean) => {
    setUserInfo((info) => {
      return { ...info, notifyAlertMail: checked as boolean };
    });
    setisAlertToggled(checked);
  };

  const onChange2 = (checked: boolean) => {
    setUserInfo((info) => {
      return { ...info, notifyReportMail: checked as boolean };
    });
    setisReportToggled(checked);
  };

  const onChange3 = (checked: boolean) => {
    setUserInfo((info) => {
      return { ...info, notifySms: checked as boolean };
    });
    setisPushToggled(checked);
  };

  // 나라 선택 함수
  const onSelectFlag = (code: string) => {
    setIsFlagSelected(code);
  };

  const [userDto, setUserInfo] = useState<UserDto>({
    userId: "",
    name: "",
    email: "",
    parentAccount: selectedAccount.accountId,
    companyName: "",
    companyPhoneNumber: "",
    department: "",
    description: "",
    notifyAlertMail: isAlertToggled,
    notifyReportMail: isReportToggled,
    notifySms: isPushToggled,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    // uerRole: {
    //   roleId: "",
    // },
    startPage: 1,
    userGroups: [],
  });

  useEffect(() => {
    if (props.isEdit && props.selectedUser !== undefined) {
      setUserInfo(props.selectedUser);
      // if(props.selectedUser.userGroups !== undefined && props.selectedUser.userGroups.length>0 ){
      //   const list = props.selectedUser.userGroups.map((group)=> group.groupId);
      //   setSelectedGroup(list as string[]);
      // }
      setisAlertToggled(props.selectedUser.notifyAlertMail);
      setisReportToggled(props.selectedUser.notifyReportMail);
      setisPushToggled(props.selectedUser.notifySms);
      if (props.selectedUser.isActivate !== undefined) {
        setIsActivate(props.selectedUser.isActivate as boolean);
      }
    }
  }, [props.isEdit, props.selectedUser]);

  const onChangeUserInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | string[] | MultiValue<Option>
    ) => {
      if (type === UPDATE_TYPE.NAME) {
        setUserInfo((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.EMAIL) {
        // level 설정 client/server 상위 선택못하게 막아야 함
        setUserInfo((info) => {
          return { ...info, email: (value as string).trim() };
        });
      } else if (type === UPDATE_TYPE.GROUP) {
        setUserInfo((info) => {
          // setSelectGroup(value.);
          const onSelectValue = new Array<Group>();
          (value as MultiValue<any>).forEach((option) => {
            const obj = {} as Group;
            obj.groupId = option.value;
            onSelectValue.push(obj);
          });
          return { ...info, userGroups: onSelectValue as Group[] };
        });

        const onSetValue = new Array<Option>();
        (value as MultiValue<any>).forEach((option) => {
          onSetValue.push(option);
        });
        setSelectGroup(onSetValue);
      } else if (type === UPDATE_TYPE.COMPANY_NAME) {
        setUserInfo((info) => {
          return { ...info, companyName: value as string };
        });
      } else if (type === UPDATE_TYPE.COMPANY_TEL) {
        setUserInfo((info) => {
          return { ...info, companyPhoneNumber: value as string };
        });
      } else if (type === UPDATE_TYPE.DEPARTMENT) {
        setUserInfo((info) => {
          return { ...info, department: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setUserInfo((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const [isDeletable, setIsDeletable] = useState<boolean>(true); 

  const deletable = useQuery(
    ["userDeletable", props.selectedUser],
    () =>
      deletableUser({
       accountId: selectedAccount.accountId,
       userId : props.selectedUser?.userId as string
      }),
    {
      retry: 0,
      enabled : props.selectedUser?.userId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setIsDeletable(false);
          return;
        }
        setIsDeletable(res.result);
      },
      onError: (e: any) => {
        setIsDeletable(false);
      }
    }
  );
  
  const queryInfo: BasePagination = {
    total: 0,
    pageNum: 0,
    pageLimit: 1000,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  };

  const [groups, setGroups] = useState<Option[]>([]);
  const { error } = useQuery(
    ["group", queryInfo],
    () =>
      getGroupListForEdit({
        payload: queryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          return;
        }
        setGroups(
          res.result.map((group: any) => {
            const { groupId, name, enableEdit } = group;
            return {
              value: groupId as string,
              label: name,
              isDisabled: enableEdit !== undefined ? !enableEdit : false,
            };
          })
        );

        if (userDto.userGroups !== undefined) {
          const idList = userDto.userGroups.map((group) => group.groupId);

          setSelectGroup(
            res.result
              .map((group: any) => {
                const { groupId, name } = group;
                return {
                  value: groupId as string,
                  label: name,
                };
              })
              .filter((option: any) => idList.includes(option.value))
          );
        }
      },
      onError: (e: any) => {
        console.info(e);
      },
    }
  );

  const mutationDeleteUser = useMutation(deleteUser, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.user.notify.delete.success",
          defaultMessage: "User removed successfully.",
        })
      );
      if (isSelfDeActive) {
        props.onModal("deActive");
      } else {
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.notify.delete.fail",
          defaultMessage: "Failed to remove user.",
        })
      );
    },
  });

  const mutationDeactiveProfile = useMutation(deactiveProfile, {
    onSuccess: () => {
      logout();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.deactivate.fail",
          defaultMessage: "Fail to deactivate profile.",
        })
      );
    },
  });

  const onClickDelete = useCallback(
    (result: boolean) => {
      setIsDeleteConfirm(false);
      if (result) {
        if (user.userId === userDto.userId) {
          //Self DeActive
          setIsSelfDeActive(true);
          getUserDetail()
            .then((response) => {
              if (
                response.totalAccount !== undefined &&
                response.totalAccount === 1
              ) {
                //profile DeActive
                mutationDeactiveProfile.mutate({
                  userId: userDto.userId,
                });
              } else {
                //only account DeActive
                mutationDeleteUser.mutate({
                  accountId: selectedAccount.accountId,
                  userId: userDto.userId,
                });
              }
            })
            .catch((error: CustomError) => {});
        } else {
          mutationDeleteUser.mutate({
            accountId: selectedAccount.accountId,
            userId: userDto.userId,
          });
        }
      }
    },
    [
      mutationDeactiveProfile,
      mutationDeleteUser,
      selectedAccount.accountId,
      user.userId,
      userDto.userId,
    ]
  );

  const onSaveBefore = useCallback(() => {
    setValidate((info) => {
      return {
        ...info,
        name: false,
        email: false,
        group: false,
        companyPhone: false,
        description: false,
      };
    });
    setValidateMsg((info) => {
      return {
        ...info,
        name: "",
        email: "",
        group: "",
        companyPhone: "",
        description: "",
      };
    });

    let checkedFlag = false;

    if (!checkEmailRegEx(userDto.email)) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, email: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          email: intl.formatMessage({
            id: "validateMsg.user.email",
            defaultMessage: "Email you entered is incorrect format",
          }) as string,
        };
      });
    }

    if (userDto.userGroups === undefined || userDto.userGroups.length === 0) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, group: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          group: intl.formatMessage({
            id: "validateMsg.user.group",
            defaultMessage: "Select one or more User Group",
          }) as string,
        };
      });
    }
    if (
      !isEmpty(userDto.companyPhoneNumber) &&
      !isPhoneNumberValid(userDto.companyPhoneNumber)
    ) {
      setValidate((info) => {
        return { ...info, companyPhone: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          companyPhone: intl.formatMessage({
            id: "validateMsg.profile.phone.validate",
            defaultMessage: "Please try again with the correct format.",
          }) as string,
        };
      });
      return;
    }

    if (!isEmpty(userDto.description) && userDto.description.length > 256) {
      setValidate((info) => {
        return { ...info, description: false };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          description: intl.formatMessage({
            id: "validateMsg.profile.description",
            defaultMessage: "Please enter within 255 characters.",
          }) as string,
        };
      });
      return;
    }
    if (checkedFlag) {
      return;
    }

    props.onClickSave(userDto, props.isEdit);
  }, [props, userDto]);

  const onClickDeactiveBefore = useCallback(() => {
    if (user.userId === userDto.userId) {
      getUserDetail()
        .then((response) => {
          if (
            response.totalAccount !== undefined &&
            response.totalAccount === 1
          ) {
            //profile DeActive
            setIsDeactivateMode(true);
          } else {
            //only account DeActive
            setIsDeactivateMode(false);
          }
          setIsDeleteConfirm(true);
        })
        .catch((err: CustomError) => {
          handleError(
            err,
            intl.formatMessage({
              id: "label.user.notify.delete.fail",
              defaultMessage: "Failed to remove user.",
            })
          );
        });
    } else {
      setIsDeactivateMode(false);
      setIsDeleteConfirm(true);
    }
  }, [handleError, intl, user.userId, userDto.userId]);

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit User" : "Add User"}
      onModal={() => props.onModal(props.isEdit ? "edit" : "add")}
    >
      <Inner>
        {props.isLoading && (
          <s.LoadingWrapper>
            <Spinner />
          </s.LoadingWrapper>
        )}
        {props.isEdit && (
          <ModalInput label="User Name" essential>
            <Input
              value={userDto.name}
              placeholder="Enter a name for this user."
              disabled={!isActivate}
              maxLength={100}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.NAME, e.target.value);
              }}
              isValidated={validate.name}
            />
            <ValidateWord>{validateMsg.name}</ValidateWord>
          </ModalInput>
        )}
        <s.Row>
          <ModalInput label="Email" essential half>
            <Input
              autoComplete="off"
              type="search"
              placeholder="Enter this user's email address."
              disabled={props.isEdit || (!isActivate as boolean)}
              pattern="[A-Za-z]+"
              maxLength={100}
              value={userDto.email}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.EMAIL, e.target.value);
              }}
              isValidated={validate.email}
            />
            <ValidateWord>{validateMsg.email}</ValidateWord>
          </ModalInput>
          <ModalInput label="User Groups" essential half>
            <SelectsCombo
              options={groups}
              value={selectGroup}
              isDisabled={!isActivate as boolean}
              // isDisabled={props.isEdit}
              // onChange={(value: string) => {
              //   console.log("role select=" + value);
              //   onChangeUserInfo(UPDATE_TYPE.GROUP, value);
              // }}
              onChange={(value: MultiValue<Option>) => {
                onChangeUserInfo(UPDATE_TYPE.GROUP, value);
                console.log({ value });
              }}
            />
            <ValidateWord>{validateMsg.group}</ValidateWord>
          </ModalInput>
        </s.Row>
        <s.Row>
          <ModalInput label="Company Name" half={!props.isEdit}>
            <Input
              value={userDto.companyName}
              placeholder="Enter a name their company."
              disabled={!isActivate as boolean}
              maxLength={100}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.COMPANY_NAME, e.target.value);
              }}
            />
          </ModalInput>
          {!props.isEdit && (
            <ModalInput label="Company Telephone" half>
              <PhoneInput
                selected={userDto.companyPhoneNumber}
                disabled={!isActivate as boolean}
                onSelect={onSelectFlag}                
                placeholder="Enter number"
                onChange={(value: string) => {
                  onChangeUserInfo(UPDATE_TYPE.COMPANY_TEL, value);
                }}
                isValidated={validate.companyPhone}
              />
              <ValidateWord>{validateMsg.companyPhone}</ValidateWord>
            </ModalInput>
          )}
        </s.Row>
        <s.Row>
          <ModalInput label="Department" half>
            <Input
              value={userDto.department}
              placeholder="Enter department."
              disabled={!isActivate as boolean}
              maxLength={100}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.DEPARTMENT, e.target.value);
              }}
            />
          </ModalInput>
          {props.isEdit && (
            <ModalInput
              label="Company Telephone"
              half
              isValidated={validate.companyPhone}
            >
              <PhoneInput
                selected={userDto.companyPhoneNumber}
                onSelect={onSelectFlag}
                disabled={!isActivate as boolean}
                placeholder="Enter number"
                onChange={(value: string) => {
                  onChangeUserInfo(UPDATE_TYPE.COMPANY_TEL, value);
                }}
              />
              <ValidateWord>{validateMsg.companyPhone}</ValidateWord>
            </ModalInput>
          )}
        </s.Row>
        <ModalInput label="Description" isValidated={validate.description}>
          <TextArea
            value={userDto.description}
            placeholder="Enter description"
            disabled={!isActivate as boolean}
            maxLength={200}
            onChange={(e) => {
              onChangeUserInfo(UPDATE_TYPE.DESC, e.target.value);
            }}
          />
          <ValidateWord>{validateMsg.description}</ValidateWord>
        </ModalInput>
        <s.ToggleWrapper>
          <s.ToggleItem>
            Receive alert emails
            <Toggle
              isToggled={isAlertToggled}
              checked={isAlertToggled}
              onChange={(checked) => onChange1(checked)}
              disabled={!isActivate as boolean}
            />
          </s.ToggleItem>
          <s.ToggleItem>
            Receive report emails
            <Toggle
              isToggled={isReportToggled}
              checked={isReportToggled}
              onChange={(checked) => onChange2(checked)}
              disabled={!isActivate as boolean}
            />
          </s.ToggleItem>
          <s.ToggleItem>
            Receive push notifications
            <Toggle
              isToggled={isPushToggled}
              checked={isPushToggled}
              onChange={(checked) => onChange3(checked)}
              disabled={!isActivate as boolean}
            />
          </s.ToggleItem>
        </s.ToggleWrapper>

        <s.ButtonWrapper>
          {props.isEdit && (
            <BigButton
              buttonType="warning"
              label="Remove from Org"
              disabled={!isDeletable}
              onClickBtn={() => onClickDeactiveBefore()}
            />
          )}
          <BigButton
            label="Save"
            onClickBtn={onSaveBefore}
            disabled={!isActivate as boolean}
          />
        </s.ButtonWrapper>
        {props.isEdit && isDeleteConfirm && (
          <Deactivate
            onModal={onClickDelete}
            type="user"
            targetId={userDto.email}
            isDeactivateMode={isDeactivateMode}
          />
        )}
      </Inner>
    </BigPopupTemplate>
  );
}

export default AddUser;

const Inner = styled.div`
  position: relative;
  width: 100%;
`;
