import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import {
  GetVideoShareListResponse,
  VideoBasePagination,
  VideoShareResult,
} from "api/interfaces/videoshareInterface.interface";
import { useQuery } from "react-query";
import { getVideoShareAllList, getVideoShareHashTag } from "api/videoshareAPI";
import { VideoShareFilter } from "api/interfaces/videoshareInterface.interface";
import {
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getStorageUsage } from "api/storageAPI";
import { StorageProps } from "components/blocks/storage/StorageLabel";
import { Location, useLocation, useNavigate } from "react-router-dom";
import VideoShare from "./VideoSharePresenter";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";

export default function VideoShareContainer(): JSX.Element {
  const intl = useIntl();
  const location: Location = useLocation();
  const navigate = useNavigate();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isStatusFilter, setIsStatusFilter] = useState<VideoShareFilter>({
    Open: true,
    Ongoing: true,
    Closed: true,
  });

  const [storageUsage, setStorageUsage] = useState<StorageProps>({
    totalStorage: 0,
    usedStorage: 0,
  });

  const [videoList, setVideoList] = useState<VideoShareResult[]>([]);

  const [tagFilters, setTagFilters] = useState<string[]>([]);

  const [selectedTag, setSelectedTag] = useState<string[]>([]);

  const [isSharePopup, setIsSharePopup] = useState<string>("");

  const [isProcessUpload, setIsProcessUpload] = useState<boolean>(true);

  //BUG [RND-382]
  // useEffect(() => {
  //   // execute on location change
  //   refetch();
  //   console.log("Location changed!", location.pathname);
  // }, [location]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);

    if (changeParam.size > 0) {
      
      setVideoShareQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 100
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "date"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          tags: isNull(changeParam.get("tags"))
            ? null
            : (changeParam.get("tags") as string),
        };
      });
    } else {
      setSelectedTag([]);
      setVideoShareQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 100,
          keyword: "",
          sortType: "date",
          sortDirection: SORT_DIRECTION.DESC,
          tags: null,
        };
      });
    }
  }, [location]);

  const [videoShareQueryInfo, setVideoShareQueryInfo] =
    useState<VideoBasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 100,
      keyword: "",
      sortType: "date",
      sortDirection: SORT_DIRECTION.DESC,
      // status: Object.keys(isStatusFilter)
      //   .filter((obj) => isStatusFilter[obj] === true)
      //   .join(","),
      tags: null,
    });

  const [videoTotal, setVideoTotal] = useState<number>(0);

  const onChangeStatusFilter = (value: string) => {
    setIsStatusFilter((statFilter) => {
      return {
        ...statFilter,
        [value]: !statFilter[value],
      };
    });
  };

  // useEffect(() => {
  //   setVideoShareQueryInfo((query) => {
  //     return {
  //       ...query,
  //       status: Object.keys(isStatusFilter)
  //         .filter((obj) => isStatusFilter[obj] === true)
  //         .join(","),
  //     };
  //   });
  // }, [isStatusFilter]);

  const hashtagQuery = useQuery(
    ["videoShareHashTag", videoShareQueryInfo.keyword],
    () =>
      getVideoShareHashTag({
        accountId: selectedAccount.accountId,
        search: videoShareQueryInfo.keyword as string,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error === 0 && res.result !== undefined) {
          setSelectedTag([]);
          setTagFilters(
            res.result.filter(
              (r: string) => r !== "" && r !== undefined && r !== " "
            )
          );
          const changeParam = new URLSearchParams(location.search);
          if(!isNull(changeParam.get("tags"))){
            setSelectedTag((changeParam.get("tags") as string).split(","));
          }
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const storageQuery = useQuery(
    ["storageStatus"],
    () =>
      getStorageUsage({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res);
        if (res.error === 0 && res.result !== undefined) {
          setStorageUsage({
            totalStorage: res.result.totalStorage,
            usedStorage: res.result.usedStorage,
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const { data, error, refetch } = useQuery(
    ["videoShare", videoShareQueryInfo, isProcessUpload],
    () =>
      getVideoShareAllList({
        accountId: selectedAccount.accountId,
        payload: videoShareQueryInfo,
      }),
    {
      retry: 0,
      refetchInterval: 10000,
      enable: isProcessUpload,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoShareListResponse) => {
        if (res.result) {
          setVideoList(res.result);
          setVideoTotal(res.page.total);
          // const totalList = res.page.total;
          // setVideoShareQueryInfo((info) => {
          //   return { ...info, total: totalList as number };
          // });
          // let tempTagArr: string[] = ["All"];
          // res.result.map((res: any) => {
          //   res.tags.map((tag) => tempTagArr.push(tag.tag));
          // });
          // setTagFilters([...new Set(tempTagArr)]);
        } else {
          setVideoList([]);
          setVideoTotal(0);
        }
      },
      onError: (e: CustomError) => {
        if (e.response?.data.error !== 5001) {
          notify(
            "error",
            intl.formatMessage({
              id: "label.videoshare.notify.search.error",
              defaultMessage: "Video share search error",
            })
          );
        }
        setVideoList([]);
        setVideoTotal(0);
      },
      // select: (res: any) => {
      //   if (res.result) {
      //     let filterArr = res.result.filter((videoRes) => {
      //       return isStatusFilter[videoRes.status];
      //     });
      //     return {
      //       ...res,
      //       result: filterArr,
      //     };
      //   } else return res;
      // },
    }
  );

  // useEffect(() => {
  //   if (data) {
  //     setVideoList(data.result);
  //   }
  // }, [data]);

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== videoShareQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: videoShareQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: videoShareQueryInfo.keyword as string,
            sortType: videoShareQueryInfo.sortType,
            sortDirection: videoShareQueryInfo.sortDirection as SORT_DIRECTION,
            tags: videoShareQueryInfo.tags,
          },
          selectedAccount
        );
        // setVideoShareQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      location.pathname,
      navigate,
      selectedAccount,
      videoShareQueryInfo.keyword,
      videoShareQueryInfo.pageLimit,
      videoShareQueryInfo.pageNum,
      videoShareQueryInfo.sortDirection,
      videoShareQueryInfo.sortType,
      videoShareQueryInfo.tags,
    ]
  );

  //BUG
  const onClickSearch = useCallback(
    (keyword: string) => {
      //setSelectedTag([]);
      if(videoShareQueryInfo.keyword !== keyword){
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: videoShareQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: videoShareQueryInfo.sortType,
            sortDirection: videoShareQueryInfo.sortDirection as SORT_DIRECTION,
            tags: null,
          },
          selectedAccount
        );
      }else{
        refetch();
      }
      // setVideoShareQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
      // hashtagQuery.refetch();
    },
    [location.pathname, navigate, refetch, selectedAccount, videoShareQueryInfo.keyword, videoShareQueryInfo.pageLimit, videoShareQueryInfo.sortDirection, videoShareQueryInfo.sortType]
  );

  //BUG RND-281
  useEffect(() => {
    if (selectedTag !== null && selectedTag.length !== 0) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: videoShareQueryInfo.pageLimit,
          pageNum: 0,
          keyword: videoShareQueryInfo.keyword as string,
          sortType: videoShareQueryInfo.sortType,
          sortDirection: videoShareQueryInfo.sortDirection as SORT_DIRECTION,
          tags: selectedTag.length === 0 ? null : selectedTag.join(","),
        },
        selectedAccount
      );
      // setVideoShareQueryInfo((query) => {
      //   return {
      //     ...query,
      //     pageNum: 0,
      //     tags: selectedTag.length === 0 ? null : selectedTag.join(","),
      //   };
      // });
    }
  }, [selectedTag]);

  const onClickTag = useCallback(
    (item: string) => {
      if (
        item === "All" ||
        (selectedTag.includes(item) && selectedTag.length === 1)
      ) {
        setSelectedTag([]);
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: videoShareQueryInfo.pageLimit,
            pageNum: 0,
            keyword: videoShareQueryInfo.keyword as string,
            sortType: videoShareQueryInfo.sortType,
            sortDirection: videoShareQueryInfo.sortDirection as SORT_DIRECTION,
            tags: null,
          },
          selectedAccount
        );
        return;
      }

      if (selectedTag.includes(item)) {
        setSelectedTag((orders) =>
          selectedTag.filter((order) => order !== item)
        );
        return;
      }

      if (selectedTag.includes("All")) {
        setSelectedTag((orders) =>
          selectedTag.filter((order) => order !== "All")
        );
      }

      setSelectedTag((orders) => [...selectedTag, item]);
    },
    [selectedTag]
  );

  const onClickShare = useCallback((videoId: string, isOpen: boolean) => {
    if (isOpen) {
      setIsSharePopup(videoId);
    } else {
      setIsSharePopup("");
    }
  }, []);

  return (
    <VideoShare
      videoList={videoList}
      videoTotal={videoTotal}
      isStatusFilter={isStatusFilter}
      onChangeStatusFilter={onChangeStatusFilter}
      tagFilters={tagFilters}
      selectedTag={selectedTag}
      listQueryInfo={videoShareQueryInfo}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      onClickTag={onClickTag}
      isSharePopup={isSharePopup}
      onClickShare={onClickShare}
      storageUsage={storageUsage}
      selectedAccount={selectedAccount}
    />
  );
}
