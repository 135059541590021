import {
  ActiveAlert,
  AlertActiveSummary,
  AlertCamera,
  AlertHistoryTraceResponse,
  AlertList2,
  AlertTraceList,
  AlertType,
  AlertViewDetail,
  AlertViewListResponse,
  SelectedAlertView,
  VideoLossCamera,
} from "api/interfaces/alertInterface.interface";
import AlertViewPresenter from "./AlertViewPresenter";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  getAlertTraceList,
  getAlertViewList,
  putAlertStatusAllClose,
} from "api/alertAPI";
import { notify } from "components/atoms/notification/Notification";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { Option } from "utils/options";
import { useQueryClient } from "react-query";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { useLocation } from "react-router-dom";
import {
  cameraUniqueValue,
  changeAlertReasonToCategory,
  filteringDiskAlertBoolean,
} from "utils/AlertUtil";
import { splitCameraId } from "./components/AlertTypeItem";

interface Props {
  onModal: () => void;
  selectedAlert: SelectedAlertView;
}

const AlertViewContainer = (props: Props) => {
  // console.log(props.selectedAlert);
  const location = useLocation();
  const intl = useIntl();
  const { handleError } = useApiError();

  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const isMap = location.pathname.includes("map");

  const [originList, setOriginList] = useState<AlertList2[]>([]);

  const [traceList, setTraceList] = useState<AlertTraceList[]>([]);
  const [alertTypeList, setAlertTypeList] = useState<AlertType[]>([]);
  const [currentAlert, setCurrentAlert] = useState<AlertList2>();
  const [count, setCount] = useState<AlertActiveSummary>({
    total: 0,
    systemCount: 0,
    diskCount: 0,
    videoCount: 0,
  });

  const [isConfirmStatus, SetIsConfirmStatus] = useState(false);
  const [checkNoConfirm, setCheckNoConfirm] = useState<boolean>(false);

  const onCheckNoConfirm = (value: boolean) => {
    setCheckNoConfirm(value);
  };

  const onConfirmCancel = () => {
    SetIsConfirmStatus(false);
  };

  const onConfirmOk = (checked: boolean) => {
    mutationAlertStatusAllClose.mutate({
      accountId: selectedAccount.accountId,
      recorderId: props.selectedAlert.recorderId,
    });
  };

  const alertViewListQuery = useQuery(
    ["alertViewList", selectedAccount, props.selectedAlert],
    () =>
      getAlertViewList({
        accountId: selectedAccount.accountId,
        recorderId: props.selectedAlert.recorderId,
        historyId: props.selectedAlert.historyId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: AlertViewListResponse) => {
        // console.log(res, "alertViewList");
        if (res.error !== 0 && res.result === undefined) {
          setAlertTypeList([]);
        } else {
          // console.log(res.result, props.selectedAlert, "00000");
          setOriginList(res.result);
          let tempAlertTypeList = res.result
            .reduce((acc: AlertType[], cur: AlertList2) => {
              if (
                (filteringDiskAlertBoolean(cur) && cur.storageName) ||
                (cur.type === "Video Loss" && cur.camera)
              ) {
                const index = acc.findIndex(
                  (item: any) => item.type === cur.type
                );
                const newItem: AlertType = {
                  category: cur.category,
                  status: cur.status,
                  type: cur.type,
                  ...(filteringDiskAlertBoolean(cur) &&
                    cur.storageName && {
                      storageList: [
                        { value: cur.storageName, label: cur.storageName },
                      ],
                    }),
                  ...(cur.type === "Video Loss" &&
                    cur.camera && {
                      cameraList: [
                        {
                          ...cur.camera,
                          value: cameraUniqueValue(cur.camera),
                          label: cur.camera.cameraName,
                        },
                      ],
                    }),
                };

                // type 같은 것 끼리 이미 storageList 나 cameraList group 이 없을 경우 group 생성
                if (index !== -1) {
                  if (cur.type === "Video Loss") {
                    acc[index] = {
                      ...acc[index],
                      ...newItem,
                      cameraList: [
                        ...(acc[index].cameraList || []),
                        ...(newItem.cameraList || []),
                      ],
                    };
                  }

                  if (filteringDiskAlertBoolean(cur)) {
                    acc[index] = {
                      ...acc[index],
                      ...newItem,
                      storageList: [
                        ...(acc[index].storageList || []),
                        ...(newItem.storageList || []),
                      ],
                    };
                  }
                  acc[index].cameraList?.sort((a: Option, b: Option) => {
                    return a.label.localeCompare(b.label, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                  });
                  acc[index].storageList?.sort((a: Option, b: Option) => {
                    return a.label.localeCompare(b.label, undefined, {
                      numeric: true,
                      sensitivity: "base",
                    });
                  });
                } else {
                  acc.push(newItem);
                }
              } else {
                acc.push({
                  category: cur.category,
                  status: cur.status,
                  type: cur.type,
                });
              }
              return acc;
            }, [])
            .sort((a) => (a.type === props.selectedAlert.alertType ? -1 : 1));

          setAlertTypeList(tempAlertTypeList);

          // console.log(props.selectedAlert, res.result, tempAlertTypeList);
          // 선택한 Alert 상태
          let tempCurrentAlert = res.result.find((alert, index) => {
            if (props.selectedAlert.alertType) {
              if (
                props.selectedAlert.alertType === "Video Loss" &&
                props.selectedAlert.camera &&
                alert.camera
              ) {
                return (
                  cameraUniqueValue(alert.camera) ===
                  cameraUniqueValue(props.selectedAlert.camera)
                );
              } else if (
                filteringDiskAlertBoolean(props.selectedAlert) &&
                props.selectedAlert.storageName &&
                alert.storageName
              ) {
                return alert.storageName === props.selectedAlert.storageName;
              } else {
                return alert.type === props.selectedAlert.alertType;
              }
            } else {
              return index === 0;
            }
          });
          // console.log(
          //   props.selectedAlert,
          //   res.result,
          //   tempCurrentAlert,
          //   "currentaelrt"
          // );
          setCurrentAlert(tempCurrentAlert ? tempCurrentAlert : res.result[0]);
          setCount(res.count);
        }
      },
      onError: (err: any) => {
        setAlertTypeList([]);

        if (!isMap) {
          notify(
            "error",
            intl.formatMessage({
              id: "label.alert.notify.list.error",
              defaultMessage: "Alert search error.",
            })
          );
        }
      },
    }
  );

  const alertTraceListQuery = useQuery(
    ["alertTraceList", selectedAccount, props.selectedAlert],
    () =>
      getAlertTraceList({
        accountId: selectedAccount.accountId,
        recorderId: props.selectedAlert.recorderId,
        historyId: props.selectedAlert.historyId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: AlertHistoryTraceResponse) => {
        // console.log(res, "alert trace list");
        if (res.error !== 0 && res.result === undefined) {
          setTraceList([]);
        } else {
          setTraceList(res.result);
        }
      },
      onError: (err: any) => {
        setTraceList([]);

        if (!isMap) {
          notify(
            "error",
            intl.formatMessage({
              id: "label.alert.notify.list.error",
              defaultMessage: "Alert search error.",
            })
          );
        }
      },
    }
  );

  const mutationAlertStatusAllClose = useMutation(putAlertStatusAllClose, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.alert.notify.allClose.success",
          defaultMessage: "All status closed successfully",
        })
      );
      // queryClient.invalidateQueries("alertViewList");
      // queryClient.invalidateQueries("alertTraceList");
      queryClient.invalidateQueries("activeAlert");
      queryClient.invalidateQueries("alertHistoryList");
      queryClient.invalidateQueries("searchMapFilterResult");
      SetIsConfirmStatus(false);
      props.onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alert.notify.allClose.fail",
          defaultMessage: "All status closed failed",
        })
      );
    },
  });

  const onCloseAllStatus = () => {
    SetIsConfirmStatus(true);
  };

  const onChangeCurrentAlert = useCallback(
    (alertType: AlertType, value?: string) => {
      // value가 들어올 때는 VideoLoss 나 Disk Alert 일 때 선택한 cameralist / storagelist 아이템 선택했을 떄임
      let type = alertType.type;
      // console.log(
      //   // alertType, originList,
      //   value,
      //   "onChangeCurrentAlert"
      // );

      if (type === "Video Loss" && value) {
        let tempCameraId = value.split(":");
        let findCurrentAlert: AlertList2 | undefined = originList.find(
          (origin) => {
            // console.log(value, tempCameraId, "cameraId");
            // console.log(origin, alertType, "999");
            // console.log(origin.camera?.cameraId, tempCameraId[0], "888");
            // console.log(origin.camera?.channel, tempCameraId[1], "777");
            // console.log(
            //   origin.type === type &&
            //     origin.camera?.cameraId === tempCameraId[0] &&
            //     origin.camera?.channel === Number(tempCameraId[1]),
            //   origin
            // );
            return (
              origin.type === type &&
              origin.camera?.cameraId === tempCameraId[0] &&
              origin.camera?.channel === Number(tempCameraId[1])
            );
          }
        );
        // console.log(findCurrentAlert?.camera, "findCurrentAlert");
        if (findCurrentAlert) setCurrentAlert(findCurrentAlert);
      } else if (filteringDiskAlertBoolean(alertType) && value) {
        // console.log("2222");
        setCurrentAlert(
          originList.find(
            (origin) => origin.type === type && origin.storageName === value
          )
        );
      } else {
        // console.log("33", originList, type);
        setCurrentAlert(originList.filter((origin) => origin.type === type)[0]);
      }
    },
    [currentAlert, originList, checkNoConfirm]
  );

  useEffect(() => {
    alertViewListQuery.refetch();
  }, [props.selectedAlert]);

  return (
    <AlertViewPresenter
      selectedAlert={props.selectedAlert}
      currentAlert={currentAlert}
      alertTypeList={alertTypeList}
      traceList={traceList}
      count={count}
      onModal={props.onModal}
      isFetching={
        alertViewListQuery.isFetching || alertTraceListQuery.isFetching
      }
      onCloseAllStatus={onCloseAllStatus}
      isConfirmStatus={isConfirmStatus}
      onConfirmCancel={onConfirmCancel}
      onConfirmOk={onConfirmOk}
      onChangeCurrentAlert={onChangeCurrentAlert}
      onCheckNoConfirm={onCheckNoConfirm}
      checkNoConfirm={checkNoConfirm}
    />
  );
};

export default AlertViewContainer;
