import { useAuth } from "components/Auth";
import { useCallback, useState } from "react";
import { MyProfilePresenter } from "./MyProfilePresenter";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deactiveProfile,
  deleteProfileImage,
  getUserDetail,
  getUserProfile,
  postUpdateProfile,
  updateProfileIamge,
  updateTheme,
} from "api/userAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { START_PAGE, UserDetail } from "api/interfaces/userInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { getAccountTimeFormat, getCurrentMilliSecond } from "utils/timeUtil";
import { Option, settingStartPageOptions } from "utils/options";
import {
  CustomError,
  LEVEL_TYPE,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import useApiError from "hook/useApiError";
import { isPhoneNumberValid } from "utils/validHelper";

export enum UPDATE_TYPE {
  NAME,
  COMPANY_NAME,
  USER_ADDR,
  COMPANY_ADDR,
  COMPANY_PHONE,
  START_PAGE,
  NEWS,
}

export default function MyProfileContainer(): JSX.Element {
  const { user, logout, updateProfileImageUrl, updateUserInfo } = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [isModal, setIsModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");

  const [isDeactiveModal, setIsDeactiveModal] = useState<boolean>(false);
  const [isCrop, setIsCrop] = useState<boolean>(false);
  const [isPhotoMenu, setIsPhotoMenu] = useState<boolean>(false);
  const profileImageUrl = user?.profileImg ? user.profileImg : "";

  // 원본 이미지
  const [originProfileImageUrl, setOriginProfileImageUrl] =
    useState<string>(profileImageUrl);

  const [isValidated, setIsValidated] = useState<Validate>({
    name: false,
    companyPhoneNumber: false,
  });
  const [isValidatedMsg, setIsValidatedMsg] = useState<ValidateMsg>({
    name: "",
    companyPhoneNumber: "",
  });

  const [originName, setOriginName] = useState<string>(user.name);
  const [profile, setProfile] = useState<UserDetail>({
    userId: "",
    name: "",
    email: "",
    address: "",
    phone: "",
    startPage: START_PAGE.MAP_VIEW,
    photo: "",
    newsLetter: false,
    companyName: "",
    companyAddress: "",
    companyPhoneNumber: "",
    createAt: "",
    lastLoggedOn: "",
    provider: "",
  });

  const [startPageOptions, setStartPageOptions] = useState<Option[]>(
    settingStartPageOptions
  );

  const onChangeisPhotoMenu = () => {
    setIsPhotoMenu(!isPhotoMenu);
  };
  const onChangeIsCrop = (type?: string) => {
    if (type === "close") {
      setIsCrop(false);
      return;
    }
    if (type === "open") {
      setIsCrop(true);
      return;
    }
    setIsCrop(!isCrop);
  };

  const onModal = (type: string) => {
    if (isModal) {
      queryClient.invalidateQueries("profile");
    }
    setIsModal(!isModal);
    setModalType(type);
  };

  const mutationDeactiveProfile = useMutation(deactiveProfile, {
    onSuccess: () => {
      logout();
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.profile.notify.deactivate.fail",
          defaultMessage: "Fail to deactivate profile.",
        })
      );
    },
  });

  const onDeactiveModal = () => {
    setIsDeactiveModal(!isDeactiveModal);
  };

  const onConfirmDeactive = () => {
    mutationDeactiveProfile.mutate({
      userId: user.userId,
    });
  };

  const mutationUpdateProfileImage = useMutation(updateProfileIamge, {
    onSuccess: (res: any) => {
      updateProfileImageUrl(res.result + "?v=" + getCurrentMilliSecond());
      onChangeIsCrop("close");
      queryClient.invalidateQueries("profile");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.updateImage.fail",
          defaultMessage: "Fail to upload Profile image.",
        })
      );
    },
  });

  const mutationUpdateTheme = useMutation(updateTheme, {
    onSuccess: (res: any) => {
      getUserDetail()
        .then((response) => {
          updateUserInfo(response);
        })
        .catch((error) => {});
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.theme.fail",
          defaultMessage: "Theme not available.",
        })
      );
    },
  });

  const mutationDeleteProfileImage = useMutation(deleteProfileImage, {
    onSuccess: (res: any) => {
      // if (res.error === 0) {
      notify(
        "success",
        intl.formatMessage({
          id: "label.profile.notify.delImage.success",
          defaultMessage: "Profile image deleted successfully",
        })
      );
      updateProfileImageUrl("");
      queryClient.invalidateQueries("profile");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.delImage.fail",
          defaultMessage: "Fail to delete Profile image.",
        })
      );
    },
  });

  const onChangeTheme = (theme: "light" | "dark") => {
    mutationUpdateTheme.mutate({
      accountId: selectedAccount.accountId,
      userId: profile.userId,
      payload: {
        theme,
      },
    });
  };

  const [isDeleteProfileImageConfirm, setIsDeleteProfileImageConfirm] =
    useState<boolean>(false);

  const onDeleteProfileImageConfirm = useCallback(() => {
    setIsDeleteProfileImageConfirm(!isDeleteProfileImageConfirm);
  }, [isDeleteProfileImageConfirm]);

  const onDeleteProfileImage = useCallback(() => {
    setIsDeleteProfileImageConfirm(!isDeleteProfileImageConfirm);
    if (profile.userId !== "") {
      mutationDeleteProfileImage.mutate({
        accountId: selectedAccount.accountId,
        userId: profile.userId,
      });
    }
  }, [
    isDeleteProfileImageConfirm,
    mutationDeleteProfileImage,
    profile.userId,
    selectedAccount.accountId,
  ]);

  // profile image finish 버튼 클릭 시
  const onFinishProfileImage = useCallback(
    (croppedImage: File, originFile: File) => {
      if (user) {
        // 기존 프로필 이미지가 없고 신규 등록 or 변경된 이미지가 없을 경우에는 update 없이 modal 닫기
        // if (
        //   profileImageUrl === ""
        //   //  && newProfileImage === ""
        // ) {
        //   notify(
        //     "warning",
        //     intl.formatMessage({
        //       id: "label.profile.notify.photo.no",
        //       defaultMessage: "No profile image.",
        //     })
        //   );
        //   // onChangeIsCrop("close");
        //   return;
        // } else {
        let formData = new FormData();
        formData.append("file", croppedImage);
        formData.append("origin", originFile);

        mutationUpdateProfileImage.mutate({
          accountId: selectedAccount.accountId,
          userId: user.userId,
          payload: formData,
        });
        // }
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.profile.notify.user.no",
            defaultMessage: "User info not founded.",
          })
        );
        return;
      }
    },
    [mutationUpdateProfileImage, selectedAccount.accountId]
  );

  const { isLoading, data } = useQuery(
    ["profile"],
    () =>
      getUserProfile({
        accountId: selectedAccount.accountId,
        userId: user.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error === 0 && res.result !== undefined) {
          res.result.lastLoggedOn =
            res.result.lastLoggedOn !== undefined
              ? getAccountTimeFormat(
                  res.result.lastLoggedOn!,
                  selectedAccount,
                  true
                )
              : "";
          res.result.createAt =
            res.result.createAt !== undefined
              ? getAccountTimeFormat(
                  res.result.createAt!,
                  selectedAccount,
                  true
                )
              : "";

          // start page option - user 가 속한 모든 account 확인
          let tempStartPageOpt = [...settingStartPageOptions];

          if (res.result.level !== undefined) {
            if (
              res.result.level.includes(LEVEL_TYPE.CP) ||
              res.result.level.includes(LEVEL_TYPE.DW)
            ) {
              tempStartPageOpt.push({
                value: "AccountList",
                label: "Organization List",
              });
            } else {
              tempStartPageOpt.push({
                value: "RecorderList",
                label: "Recorder List",
              });
            }
          }

          // console.log(res.result, "get profile");
          if (res.result.originalProfileImg) {
            setOriginProfileImageUrl(res.result.originalProfileImg);
          }
          setStartPageOptions(tempStartPageOpt);
          setProfile(res.result);
          setOriginName(res.result.name);
        } else {
          notify(
            "error",
            intl.formatMessage({
              id: "label.profile.notify.search.fail",
              defaultMessage: "User Profile Information not available.",
            })
          );
        }
      },
      onError: (res: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.profile.notify.search.fail",
            defaultMessage: "User Profile Information not available.",
          })
        );
      },
    }
  );

  const mutationUpdateProfile = useMutation(postUpdateProfile, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        getUserDetail()
          .then((response) => {
            updateUserInfo(response);
          })
          .catch((error) => {});
        notify(
          "success",
          intl.formatMessage({
            id: "label.profile.notify.update.success",
            defaultMessage: "Profile updated successfully.",
          })
        );
        queryClient.invalidateQueries("profile");
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.profile.notify.update.fail",
            defaultMessage: "Fail to update profile.",
          })
        );
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.update.fail",
          defaultMessage: "Fail to update profile.",
        })
      );
    },
  });

  const onClickSave = useCallback(() => {
    setIsValidated((info) => {
      return { ...info, name: false, companyPhoneNumber: false };
    });
    setIsValidatedMsg((msg) => {
      return {
        ...msg,
        name : "",
        companyPhoneNumber: "",
      };
    });

    if (isEmpty(profile.name)) {
      setIsValidated((info) => {
        return { ...info, name: true };
      });
      setIsValidatedMsg((msg) => {
        return {
          ...msg,
          name: intl.formatMessage({
            id: "validateMsg.profile.name.empty",
            defaultMessage: "Name not allowed blank.",
          }),
        };
      });
      return;
    }
    if (
      !isEmpty(profile.companyPhoneNumber) &&
      !isPhoneNumberValid(profile.companyPhoneNumber)
      
    ) {
      setIsValidated((info) => {
        return { ...info, companyPhoneNumber: true };
      });
      setIsValidatedMsg((msg) => {
        return {
          ...msg,
          companyPhoneNumber: intl.formatMessage({
            id: "validateMsg.profile.phone.validate",
            defaultMessage: "Please try again with the correct format.",
          }),
        };
      });
      return;
    }
    // if (isEmpty(profile.companyPhoneNumber)) {
    //   setIsValidated((info) => {
    //     return { ...info, companyPhoneNumber: false };
    //   });
    //   setIsValidatedMsg((msg) => {
    //     return {
    //       ...msg,
    //       companyPhoneNumber: "",
    //     };
    //   });
    // }
    const updateObject = Object.assign({}, profile);
    delete updateObject.createAt;
    delete updateObject.lastLoggedOn;

    mutationUpdateProfile.mutate({
      accountId: selectedAccount.accountId,
      userId: user.userId,
      payload: updateObject,
    });
  }, [
    intl,
    mutationUpdateProfile,
    profile,
    selectedAccount.accountId,
    user.userId,
  ]);

  const onChangeProfile = useCallback(
    (type: UPDATE_TYPE, value: string | boolean) => {
      if (type === UPDATE_TYPE.NAME) {
        setProfile((profile) => {
          return { ...profile, name: value as string };
        });
      } else if (type === UPDATE_TYPE.USER_ADDR) {
        setProfile((profile) => {
          return { ...profile, address: value as string };
        });
      } else if (type === UPDATE_TYPE.COMPANY_NAME) {
        setProfile((profile) => {
          return { ...profile, companyName: value as string };
        });
      } else if (type === UPDATE_TYPE.COMPANY_ADDR) {
        setProfile((profile) => {
          return { ...profile, companyAddress: value as string };
        });
      } else if (type === UPDATE_TYPE.COMPANY_PHONE) {
        setProfile((profile) => {
          return { ...profile, companyPhoneNumber: value as string };
        });
      } else if (type === UPDATE_TYPE.START_PAGE) {
        setProfile((profile) => {
          return { ...profile, startPage: value as START_PAGE };
        });
      } else if (type === UPDATE_TYPE.NEWS) {
        setProfile((profile) => {
          return { ...profile, newsLetter: value as boolean };
        });
      }
    },
    []
  );

  const onChangeAddress = useCallback(
    (type: UPDATE_TYPE, value: string | boolean) => {
      if (type === UPDATE_TYPE.USER_ADDR) {
        setProfile((profile) => {
          return { ...profile, address: value as string };
        });
      } else if (type === UPDATE_TYPE.COMPANY_ADDR) {
        setProfile((profile) => {
          return { ...profile, companyAddress: value as string };
        });
      }
    },
    []
  );
  return (
    <MyProfilePresenter
      isModal={isModal}
      isDeactiveModal={isDeactiveModal}
      modalType={modalType}
      onModal={onModal}
      onDeactiveModal={onDeactiveModal}
      originName={originName}
      profile={profile}
      isCrop={isCrop}
      isPhotoMenu={isPhotoMenu}
      onChangeIsCrop={onChangeIsCrop}
      profileImageUrl={profileImageUrl}
      // newProfileImageUrl={newProfileImageUrl}
      originProfileImageUrl={originProfileImageUrl}
      // onChangeProfileImage={onChangeProfileImage}
      onFinishProfileImage={onFinishProfileImage}
      onChangeisPhotoMenu={onChangeisPhotoMenu}
      onChangeProfile={onChangeProfile}
      onChangeAddress={onChangeAddress}
      accountLevel={selectedAccount.accountLevel}
      onClickSave={onClickSave}
      isValidated={isValidated}
      isValidatedMsg={isValidatedMsg}
      onDeleteProfileImage={onDeleteProfileImage}
      onDeleteProfileImageConfirm={onDeleteProfileImageConfirm}
      onConfirmDeactive={onConfirmDeactive}
      startPageOptions={startPageOptions}
      isDeleteProfileImageConfirm={isDeleteProfileImageConfirm}
      onChangeTheme={onChangeTheme}
    />
  );
}
