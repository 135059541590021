import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isPhoneNumberValid, validPassword } from "utils/validHelper";
import { SignupPagePresenter, UPDATE_TYPE } from "./SignupPresenter";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { postRegister, postResetPassword, postVerify } from "api/authAPI";
import { useMutation } from "react-query";
import { SEND_TYPE } from "api/interfaces/authInterface.interface";
import { getTokenInfo, getUserDetail, getUserDetailAccount } from "api/userAPI";
import { notify } from "components/atoms/notification/Notification";
import { checkPasswordNumberRegEx, checkPasswordRegEx } from "utils/regEx";
import qs from "qs";
import { useIntl } from "react-intl";
import {
  CustomError,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { setCookie } from "cookies/cookie";
import { getCookieExpireTime } from "utils/timeUtil";
import { ACCESS_TOKEN, AxiosInstance, REFRESH_TOKEN } from "api/axiosInstance";
import { getClientDevice } from "utils/functions";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { useAppDispatch } from "redux/hooks";
import { useAuth } from "components/Auth";
import useApiError from "hook/useApiError";

export enum SIGNUP_STEP {
  SELECT_SIGNUP = "SIGN UP",
  INPUT_INFO = "INPUT_INFO",
  INPUT_VERIFY_CODE = "INPUT_VERIFY_CODE",
  SUCCESS = "SUCCESS",
  PASSWORD_RESET = "PASSWORD_RESET",
  RESET_PASSWORD = "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  agreement: boolean;
  parentAccount: string;
}

export interface Validate {
  [key: string]: boolean;
}

export function SignupPage(): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const intl = useIntl();

  const { displayError } = useApiError();
  
  const { pathname } = useLocation();
  const [checkedEmail, setCheckedEmail] = useState<string>("");
  const [checkedAccountId, setCheckedAccountId] = useState<string>("");

  const [step, setStep] = useState<SIGNUP_STEP>(SIGNUP_STEP.SELECT_SIGNUP);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    parentAccount: "",
    agreement: false,
  });

  const [isValidated, setIsValidated] = useState<Validate>({
    firstName: false,
    lastName: false,
    password: false,
    confirmPassword: false,
    phoneNumber: false,
    agreement: false,
  });

  const [validateMsg, setValidateMsg] = useState<string>("");

  const [spectrumValidated, setSpectrumValidated] = useState<Validate>({
    email: false,
  });
  const [spectrumValidateMsg, setSpectrumValidateMsg] = useState<ValidateMsg>({
    email: "",
  });

  const firstNameRef = useRef<any>(null);
  const lastNameRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const confirmPasswordRef = useRef<any>(null);
  const agreementRef = useRef<any>(null);
  // const firstNameRef = useRef<any>(null);

  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [isValidConfirm, setIsValidConfirm] = useState<boolean>(false);
  const [isValidVerify, setIsValidVerify] = useState<boolean>(false);

  const [isOpenEULA, setIsOpenEULA] = useState<boolean>(false);

  const [isFlagSelected, setIsFlagSelected] = useState("US");

  const [isCloudAccount, setIsCloudAccount] = useState<boolean>(false);

  const [isLoadedToken, setIsLoadedToken] = useState<boolean>(false);
  const [isExpiredToken, setIsExpiredToken] = useState<boolean>(false);


  // 나라 선택 함수
  const onSelectFlag = (code: string) => {
    setIsFlagSelected(code);
  };
  const getToken = async (token: string) => {
    const data = getTokenInfo(token)
      .then((data) => {
        setIsLoadedToken(true);
        setCheckedEmail(data.result.email);
        setCheckedAccountId(data.result.accountId);
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            parentAccount: data.result.accountId as string,
          };
        });
        if (pathname === "/signup" && data.result.isActive) {
          setStep(SIGNUP_STEP.SUCCESS);
        }
        setIsCloudAccount(data.result.dwAccountActive);
      })
      .catch((error: CustomError) => {
        const tokenEmail = error.response?.data?.result?.email;
        if (tokenEmail) {
          setCheckedEmail(tokenEmail);
        } else {
          setCheckedEmail("Email not found");
        }
        setIsExpiredToken(true);
        if (error.response?.data !== undefined) {
          if (pathname === "/reset") {
            if (error.response?.data.error === 1011) {
              setValidateMsg(
                intl.formatMessage({
                  id: "validateMsg.reset.getTokenExpire",
                  defaultMessage:
                    "Reset Token has expired. Retry password reset.",
                })
              );
            } else if (error.response?.data.error === 1010) {
              setValidateMsg(
                intl.formatMessage({
                  id: "validateMsg.reset.getTokenInvalid",
                  defaultMessage:
                    "Reset Token is invalid. Contact your administrator",
                })
              );
            } else {
              setValidateMsg(
                intl.formatMessage({
                  id: "validateMsg.signup.getTokenEmail",
                  defaultMessage: "Please contact your administrator",
                })
              );
            }
          } else {
            if (error.response?.data.error === 1011) {
              setValidateMsg(
                intl.formatMessage({
                  id: "validateMsg.signup.getTokenExpire",
                  defaultMessage:
                    "Invitation has expired. Contact your administrator",
                })
              );
            } else if (error.response?.data.error === 1010) {
              setValidateMsg(
                intl.formatMessage({
                  id: "validateMsg.signup.getTokenInvalid",
                  defaultMessage:
                    "Invitation is invalid. Contact your administrator",
                })
              );
            } else if (error.response?.data.error === 10001) {
              setStep(SIGNUP_STEP.SUCCESS);
            } else {
              setValidateMsg(
                intl.formatMessage({
                  id: "validateMsg.signup.getTokenEmail",
                  defaultMessage: "Please contact your administrator",
                })
              );
            }
          }
        } else {
          setValidateMsg(
            intl.formatMessage({
              id: "validateMsg.signup.getTokenEmail",
              defaultMessage: "Please contact your administrator",
            })
          );
        }
      });
  };

  useEffect(() => {
    if (pathname === "/reset") {
      setStep(SIGNUP_STEP.RESET_PASSWORD);
    }

    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const inviteEmail = params.get("email");
    if (
      inviteEmail !== undefined &&
      inviteEmail !== null &&
      isEmpty(inviteEmail)
    ) {
      setCheckedEmail(inviteEmail);
    }
    if (token === undefined || token == null) {
      navigate("/login");
    } else {
      getToken(token as string);
    }
  }, []);

  const validStatus = useMemo(() => {
    return validPassword(userInfo.password);
  }, [userInfo.password]);

  const mutationCreateUser = useMutation(postRegister, {
    onSuccess: () => {
      setStep(SIGNUP_STEP.SUCCESS);
    },
    onError: (err: CustomError) => {
      setIsValidVerify(true);
      setValidateMsg(
        displayError(
          err,
          intl.formatMessage({
            id: "validateMsg.signup.fail",
            defaultMessage: "SignUp failed during the registration process.",
          })
        )
      );
    },
  });

  const [mfaNumber, setMfaNumber] = useState<string>("");

  const mutationVerify = useMutation(postVerify, {
    onSuccess: (res: any) => {
      setMfaNumber(res.result.verifiedNumber);
      setStep(SIGNUP_STEP.INPUT_VERIFY_CODE);
    },
    onError: () => {
      setIsValidated({
        ...isValidated,
        firstName: false,
        lastName: false,
        password: false,
        confirmPassword: false,
        phoneNumber: true,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.invalidPhoneNumber",
          defaultMessage: "Verify Message Sending Failed",
        })
      );
    },
  });

  const onClickSignup = useCallback(() => {
    setIsValidated({
      ...isValidated,
      firstName: false,
      lastName: false,
      password: false,
      confirmPassword: false,
      phoneNumber: false,
      agreement: false,
    });

    if (isEmpty(userInfo.firstName)) {
      setIsValidated({
        ...isValidated,
        firstName: true,
        lastName: false,
        password: false,
        confirmPassword: false,
        phoneNumber: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.firstname",
          defaultMessage: "Please enter First Name",
        })
      );
      firstNameRef.current.focus();
      return;
    }
    if (isEmpty(userInfo.lastName)) {
      setIsValidated({
        ...isValidated,
        lastName: true,
        firstName: false,
        password: false,
        confirmPassword: false,
        phoneNumber: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.lastname",
          defaultMessage: "Please enter Last Name",
        })
      );
      lastNameRef.current.focus();
      return;
    }
    if (!isEmpty(userInfo.phoneNumber)) {
      if(!isPhoneNumberValid(userInfo.phoneNumber)){
        setIsValidated({
          ...isValidated,
          phoneNumber: true,
          firstName: false,
          password: false,
          confirmPassword: false,
          lastName: false,
          agreement: false,
        });
        setValidateMsg(
          intl.formatMessage({
            id: "validateMsg.signup.phone.invalidFormat",
            defaultMessage: "Phone number you entered is invalid format.",
          })
        );
        return true;
      }
    }
    if (isEmpty(userInfo.password)) {
      setIsValidated({
        ...isValidated,
        password: true,
        firstName: false,
        phoneNumber: false,
        confirmPassword: false,
        lastName: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.password",
          defaultMessage: "Please enter Password",
        })
      );
      passwordRef.current.focus();
      return;
    }

    if (isEmpty(userInfo.confirmPassword)) {
      setIsValidated({
        ...isValidated,
        confirmPassword: true,
        firstName: false,
        phoneNumber: false,
        password: false,
        lastName: false,
        agreement: false,
      });
      // setValidateMsg(
      //   intl.formatMessage({
      //   id: "validateMsg.signup.passwordMismatch",
      //   defaultMessage: "Password you entered do not mismatch",
      // })
      // );
      confirmPasswordRef.current.focus();
      return;
    }

    if (
      userInfo.password.length < 8 ||
      !checkPasswordRegEx(userInfo.password) ||
      !checkPasswordNumberRegEx(userInfo.password)
    ) {
      setIsValidated({
        ...isValidated,
        confirmPassword: false,
        firstName: false,
        phoneNumber: false,
        password: true,
        lastName: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.password",
          defaultMessage: "Password you entered is incorrect",        
        })
      );
      passwordRef.current.focus();
      return;
    }

    if (userInfo.password !== userInfo.confirmPassword) {
      setIsValidated({
        ...isValidated,
        confirmPassword: true,
        firstName: false,
        phoneNumber: false,
        password: false,
        lastName: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.passwordMismatch",
          defaultMessage: "Password you entered mismatch",
        })
      );
      confirmPasswordRef.current.focus();
      return;
    }

    if (!userInfo.agreement) {
      setIsValidated({
        ...isValidated,
        agreement: true,
        confirmPassword: false,
        firstName: false,
        phoneNumber: false,
        password: false,
        lastName: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.agreement",
          defaultMessage: "Please Check User Agreement",
        })
      );
      agreementRef.current.focus();
      return;
    }

    if (isEmpty(userInfo.phoneNumber) || userInfo.phoneNumber.trim() === "+1") {

        mutationCreateUser.mutate({
          payload: {
            name:
              userInfo.firstName.replace(/^\s+|\s+$/gm, "") +
              " " +
              userInfo.lastName.replace(/^\s+|\s+$/gm, ""),
            phone: userInfo.phoneNumber,
            password: userInfo.password,
            parentAccount: userInfo.parentAccount,
            email: checkedEmail,
            sendCode: SEND_TYPE.EMAIL,
          },
          token: "token",
        });
        setVerifyCode("");
     
    } else {
      mutationVerify.mutate({
        payload: {
          phoneNumber: userInfo.phoneNumber,
        },
      });
    }
  }, [userInfo, mutationCreateUser, checkedEmail]);

  const onClickLogin = useCallback(() => {
    window.location.replace(
      `/login?accountId=${checkedAccountId}&email=${encodeURIComponent(
        checkedEmail
      )}`
    );
  }, [checkedAccountId, checkedEmail]);

  const onClickVerifyCancel = useCallback(() => {
    setIsValidated({
      ...isValidated,
      agreement: false,
      confirmPassword: false,
      firstName: false,
      phoneNumber: false,
      password: false,
      lastName: false,
    });
    setValidateMsg("");
    setStep(SIGNUP_STEP.INPUT_INFO);
  }, []);

  const onChangeUserInfo = useCallback(
    (type: UPDATE_TYPE, value: string | boolean) => {
      if (type === UPDATE_TYPE.PASSWORD) {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            password: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.CONFIRM_PASSWORD) {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            confirmPassword: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.PHONE_NUMBER) {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            phoneNumber: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.FIRST_NAME) {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            firstName: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.LAST_NAME) {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            lastName: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.AGREEMENT) {
        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            agreement: value as boolean,
          };
        });
      }
    },
    []
  );

  const onOpenEULAModal = useCallback((isOpen:boolean) => {
    // if(!userInfo.agreement && isOpen){
    //   onChangeUserInfo(
    //     UPDATE_TYPE.AGREEMENT,
    //    true
    //   )
    // }
    setIsOpenEULA(isOpen);
  },[]);

  const [verifyCode, setVerifyCode] = useState<string>("");

  const onChangeVerifyCode = useCallback(
    (value: string) => {
      setVerifyCode(value);
    },
    [verifyCode]
  );

  const onClickVerify = useCallback(() => {
    if (mfaNumber === verifyCode) {
      mutationCreateUser.mutate({
        payload: {
          name: userInfo.firstName + " " + userInfo.lastName,
          phone: userInfo.phoneNumber,
          password: userInfo.password,
          email: checkedEmail,
          parentAccount: userInfo.parentAccount,
          sendCode: SEND_TYPE.EMAIL,
        },
        token: "token",
      });
    } else {
      setIsValidVerify(true);
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.verifyNumber",
          defaultMessage: "The code you entered do not mismatch",
        })
      );
    }
  }, [
    mfaNumber,
    verifyCode,
    mutationCreateUser,
    userInfo.firstName,
    userInfo.lastName,
    userInfo.phoneNumber,
    userInfo.password,
    userInfo.parentAccount,
    checkedEmail,
    intl,
  ]);

  const onblurConfirmPassword = useCallback(() => {
    setIsValidConfirm(false);
    setIsValidated({
      ...isValidated,
      lastName: false,
      firstName: false,
      password: false,
      confirmPassword: false,
      phoneNumber: false,
      agreement: false,
    });
    setValidateMsg("");

    if (!isEmpty(userInfo.password)) {
      if (
        userInfo.password.length < 8 ||
        !checkPasswordRegEx(userInfo.password) ||
        !checkPasswordNumberRegEx(userInfo.password)
      ) {
        setIsValidated({
          ...isValidated,
          confirmPassword: false,
          firstName: false,
          phoneNumber: false,
          password: true,
          lastName: false,
          agreement: false,
        });
        setValidateMsg(
          intl.formatMessage({
            id: "validateMsg.signup.password",
            defaultMessage: "Password you entered is incorrect",
          })
        );
      } else if (userInfo.password !== userInfo.confirmPassword) {
        setIsValidated({
          ...isValidated,
          confirmPassword: true,
          firstName: false,
          phoneNumber: false,
          password: false,
          lastName: false,
          agreement: false,
        });
        setValidateMsg(
          intl.formatMessage({
            id: "validateMsg.signup.passwordMismatch",
            defaultMessage: "Password you entered mismatch",
          })
        );
      } else {
        setIsValidConfirm(false);
      }
    }
  }, [intl, isValidated, userInfo.confirmPassword, userInfo.password]);

  const mutationResetPassword = useMutation(postResetPassword, {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        setStep(SIGNUP_STEP.RESET_PASSWORD_SUCCESS);
      } else {
        setIsValidPassword(true);
        // setValidateMsg("Change Password is Failure")
      }
    },
    onError: () => {
      setIsValidPassword(true);
      // setValidateMsg("Change Password is Failure")
    },
  });

  const onClickPassword = useCallback(() => {
    const { password, confirmPassword } = userInfo;
    setIsValidPassword(false);
    setIsValidated({
      ...isValidated,
      confirmPassword: false,
      firstName: false,
      phoneNumber: false,
      password: false,
      lastName: false,
      agreement: false,
    });
    setValidateMsg("");

    if (
      password.length < 8 ||
      !checkPasswordRegEx(password) ||
      !checkPasswordNumberRegEx(password)
    ) {
      setIsValidated({
        ...isValidated,
        confirmPassword: false,
        firstName: false,
        phoneNumber: false,
        password: true,
        lastName: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.password",
          defaultMessage: "Password you entered mismatch",
        })
      );
      passwordRef.current.focus();
      return true;
    }

    if (password !== confirmPassword) {
      setIsValidated({
        ...isValidated,
        confirmPassword: true,
        firstName: false,
        phoneNumber: false,
        password: false,
        lastName: false,
        agreement: false,
      });
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.passwordMismatch",
          defaultMessage: "Password you entered mismatch",
        })
      );
      confirmPasswordRef.current.focus();
      return true;
    }
    mutationResetPassword.mutate({
      payload: {
        email: checkedEmail,
        password: userInfo.password,
      },
    });
  }, [checkedEmail, intl, isValidated, mutationResetPassword, userInfo]);

  const onClickBackLogin = useCallback(() => {
    navigate("/");
  }, []);

  const mutationResendCode = useMutation(postVerify, {
    onSuccess: (res: any) => {
      if (res.result.verifiedNumber !== undefined) {
        setMfaNumber(res.result.verifiedNumber);
      }
      notify(
        "success",
        intl.formatMessage({
          id: "label.signup.resendCode.success",
          defaultMessage: "Verification Code sended successfully.",
        })
      );
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.signup.resendCode.fail",
          defaultMessage: "Fail to send Verification Code",
        })
      );
    },
  });

  const onClickResend = useCallback(() => {
    mutationResendCode.mutate({
      payload: {
        phoneNumber: userInfo.phoneNumber,
      },
    });
  }, [mutationResendCode, userInfo.phoneNumber]);

  const onClickSignPage = useCallback(() => {
    setStep(SIGNUP_STEP.INPUT_INFO);
  }, []);

  const onClickCloudCreate = useCallback(() => {
    const OATUH_HOST =
      "https://dwspectrum.digital-watchdog.com/authorize?client_type=create";
    const client_id = "mydw-api";
    const redirect_uri = process.env.REACT_APP_OAUTH_CALLBACK_URL;
    const AUTHORIZE_URI = `${OATUH_HOST}?${qs.stringify({
      client_id,
      redirect_uri,
    })}`;
    window.open(OATUH_HOST, "_blank", "noopener, noreferrer");
    navigate("/login");
  }, []);

  const onClickCloudLogin = useCallback(() => {
    const OATUH_HOST = "https://dwspectrum.digital-watchdog.com/authorize";
    const client_id = "mydw-api";
    const redirect_uri = process.env.REACT_APP_OAUTH_CALLBACK_URL;
    //const redirect_uri = "http://localhost:3000";
    const response_type = "code";
    const email = checkedEmail;

    const AUTHORIZE_URI = `${OATUH_HOST}?${qs.stringify({
      client_id,
      redirect_uri,
      response_type,
      email,
    })}`;

    window.location.href = AUTHORIZE_URI;
  }, [checkedEmail]);

  const toPathParam = location.state?.pathname;
  const toSearchParam = new URLSearchParams(location.state?.search);

  const setUserInfoApi = useCallback((email: string) => {
    return new Promise(function (resolve, reject) {
      // AxiosInstance.defaults.headers.common[
      //   "Authorization"
      // ] = `Bearer ${accessToken.accessTokenValue}`;
      // sessionStorage.setItem('token', session);
      if (
        toSearchParam !== undefined &&
        toSearchParam.get("accountId") !== null
      ) {
        getUserDetailAccount(toSearchParam.get("accountId") as string)
          .then((response) => {
            auth.login(response);
            resolve(response);
          })
          .catch(function (err) {
            notify(
              "error",
              intl.formatMessage({
                id: "validateMsg.signup.noUserInformation",
                defaultMessage: "User Information not found",
              })
            );
          });
      } else {
        getUserDetail()
          .then((response) => {
            auth.login(response);
            resolve(response);
          })
          .catch(function (err) {
            notify(
              "error",
              intl.formatMessage({
                id: "validateMsg.signup.noUserInformation",
                defaultMessage: "User Information not found",
              })
            );
          });
      }
    });
  }, []);

  const [cloudLoading, setCloudLoading] = useState<boolean>(false);

  const onCode = async (code: string, params: URLSearchParams) => {
    if (code !== undefined && code !== "") {
      try {
        setSpectrumValidated({ ...isValidated, email: false });
        setSpectrumValidateMsg({
          ...spectrumValidateMsg,
          email: "",
        });

        const device = getClientDevice();
        setCloudLoading(true);
        // code를 이용해서 token들을 받아옴
        sessionStorage.setItem("code", code);
        const resp = await AxiosInstance.post("/auth/signUpToken", {
          code: code,
          device : device,
          email : checkedEmail
        });

        if (resp.status === 404) {
          setSpectrumValidated({ ...isValidated, email: true });
          setSpectrumValidateMsg({
            ...spectrumValidateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.accountNotFound",
              defaultMessage: "Couldn't find your myDW organization",
            }),
          });
        } else if (200 > resp.status || 300 < resp.status) {
          setSpectrumValidated({ ...isValidated, email: true });
          setSpectrumValidateMsg({
            ...spectrumValidateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.loginIncorrect",
              defaultMessage: "Incorrect Login Credentials.",
            }),
          });
        } else {
          if (resp.status === 200 && resp.data.result.email === checkedEmail) {
            AxiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp.data.result.accessToken}`;
            setCookie({
              name: ACCESS_TOKEN,
              value: resp.data.result.accessToken,
              options: {
                path: "/",
                expires: getCookieExpireTime(6),
              },
            });
            setCookie({
              name: REFRESH_TOKEN,
              value: resp.data.result.refreshToken,
              options: {
                path: "/",
                expires: getCookieExpireTime(72),
                //secure : true,
                //httpOnly : true
              },
            });
            //sessionStorage.setItem("token", resp.data.token);
            setUserInfoApi(resp.data.result.email)
              .then(function (resolvedData) {
                dispatch(setMenu("Dashboard"));
                navigate("/dashboard/map", { replace: true });
              })
              .catch(function (err) {
                setSpectrumValidated({ ...isValidated, email: true });
                setSpectrumValidateMsg({
                  ...spectrumValidateMsg,
                  email: intl.formatMessage({
                    id: "validateMsg.signup.loginIncorrect",
                    defaultMessage: "Incorrect Login Credentials.",
                  }),
                });
                //console.log('then error : ', err); // then error :  Error: Error in then()
              });
          } else {
            setSpectrumValidated({ ...isValidated, email: true });
            setSpectrumValidateMsg({
              ...spectrumValidateMsg,
              email: intl.formatMessage({
                id: "validateMsg.signup.email.difference",
                defaultMessage:
                  "Invitation email does nt match authenticated email",
              }),
            });
          }
        }
      } catch (err) {
        setSpectrumValidated({ ...isValidated, email: true });
        setSpectrumValidateMsg({
          ...spectrumValidateMsg,
          email: displayError(
            err as CustomError,
            intl.formatMessage({
              id: "validateMsg.signup.fail",
              defaultMessage: "SignUp failed during the registration process.",
            })
          )
        });
      } finally {
        setCloudLoading(false);
      }
    }
  };
  const onPopupClose = () => console.log("closed!");

  return (
    <SignupPagePresenter
      step={step}
      checkedEmail={checkedEmail}
      userInfo={userInfo}
      verifyCode={verifyCode}
      onChangeVerifyCode={onChangeVerifyCode}
      onClickSignup={onClickSignup}
      onClickVerify={onClickVerify}
      onClickLogin={onClickLogin}
      onClickVerifyCancel={onClickVerifyCancel}
      passwordStatus={validStatus}
      onChangeUserInfo={onChangeUserInfo}
      onblurConfirmPassword={onblurConfirmPassword}
      isValidConfirm={isValidConfirm}
      isValidPassword={isValidPassword}
      onClickPassword={onClickPassword}
      onClickBackLogin={onClickBackLogin}
      isFlagSelected={isFlagSelected}
      onSelectFlag={onSelectFlag}
      onOpenEULAModal={onOpenEULAModal}
      isOpenEULA={isOpenEULA}
      isValidated={isValidated}
      firstNameRef={firstNameRef}
      lastNameRef={lastNameRef}
      passwordRef={passwordRef}
      confirmPasswordRef={confirmPasswordRef}
      agreementRef={agreementRef}
      onClickSignPage={onClickSignPage}
      onClickCloudCreate={onClickCloudCreate}
      validateMsg={validateMsg}
      onClickResend={onClickResend}
      isValidVerify={isValidVerify}
      isCloudAccount={isCloudAccount}
      onClickCloudLogin={onClickCloudLogin}
      isLoadedToken={isLoadedToken}
      isExpiredToken={isExpiredToken}
      onCode={onCode}
      onPopupClose={onPopupClose}
      cloudLoading={cloudLoading}
      spectrumValidated={spectrumValidated}
      spectrumValidateMsg={spectrumValidateMsg}
    />
  );
}
