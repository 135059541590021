import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import * as s from "./ExportMailStyled";
import EmailAdd from "components/atoms/select/email/EmailAdd";
import { MultiValue } from "react-select";
import { ExportEmailRequest } from "api/interfaces/reportInterface.interface";
import { useCallback, useState } from "react";
import TextArea from "components/atoms/input/TextArea";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { useIntl } from "react-intl";
import Input from "components/atoms/input/Input";
import { isEmpty } from "lodash";
import { checkEmailRegEx } from "utils/regEx";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { sendExportEmail } from "api/reportAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";

type ExportEmailProps = {
  reportId: string;
  title: string | undefined;
  onModal: () => void;
};

//TODO RND-340
export default function ExportMail(props: ExportEmailProps): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [state, setState] = useState<ExportEmailRequest>({
    email: [],
    title: props.title !== undefined? props.title : "",
    message: "",
    reportId: props.reportId,
  });

  const onSelectUser = (user: MultiValue<any>) => {
    setState({
      ...state,
      email: user,
    });
  };

  const mutationExportEmail = useMutation(sendExportEmail, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.common.notify.email.success",
        defaultMessage: "Email sent successfully",
      }));
      props.onModal();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.common.notify.email.fail",
        defaultMessage: "Email sent failed.",
      }));
    },
  });

  const onClickSend = useCallback(() => {
    setIsValid(true);
    setIsValidEmail(true);
    if(state.email.length === 0){
      setIsValid(false);
      return;
    }

    let checkInvalidEmail = true;
    state.email.forEach(value => {
      if(value === undefined || isEmpty(value.value) || !checkEmailRegEx(value.value)){
        checkInvalidEmail = false;
      }
    })
    
    if(!checkInvalidEmail){
      setIsValidEmail(false);
      return;
    }

    mutationExportEmail.mutate({
        accountId: selectedAccount.accountId,
        payload: {
          email: state.email.map((item) => {
            return item.value;
          }),
          title: state.title,
          message : state.message,
          reportId : state.reportId
        },
      });
  }, [mutationExportEmail, selectedAccount.accountId, state.email, state.message, state.reportId, state.title]);

  const onChangeMessage = (e:any) => {
    setState({
        ...state,
        message: e.target.value,
      });
  };

  const onChangeTitle = (e: any) => {
    setState({
        ...state,
        title: e.target.value,
      });

  };
  return (
    <SmallPopupTemplate
      title="Send Email"
      subTitle="Export Inventory Report"
      onClick={onClickSend}
      onModal={props.onModal}
      confirmLabel="Send"
    >
      <s.EmailSelectContainer>
        <EmailAdd
          value={state.email}
          //placeholder="Select User or Enter Email Address"
          onChange={onSelectUser}
          isVs
          isMulti
          // isSelectedArr={state.user}
        />
      </s.EmailSelectContainer>

      <s.MessageOptional>
        <Text fontSize={18}>Title</Text>
        <Input
          name="title"
          value={state.title}
          onChange={onChangeTitle}
          placeholder="Enter Title here."
        />
      </s.MessageOptional>
      <s.MessageOptional>
        <Text fontSize={18}>Message (Optional)</Text>
        <TextArea
          name="message"
          value={state.message}
          onChange={onChangeMessage}
          placeholder="Enter your message here."
        />
      </s.MessageOptional>
      {!isValid && (
        <ValidateWord>
          {intl.formatMessage({
            id: "validateMsg.videoShare.err.emptyUser",
            defaultMessage: "Select one or more User or Enter email address",
          })}
        </ValidateWord>
      )}
      {!isValidEmail && (
        <ValidateWord>
          {intl.formatMessage({
            id: "validateMsg.videoShare.err.invalidEmail",
            defaultMessage: "Entered Email is Invalid",
          })}
        </ValidateWord>
      )}
    </SmallPopupTemplate>
  );
}
