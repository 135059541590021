import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { IntlProvider } from "react-intl";
import enMessage from "./lang/en-US.json";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import LoginPage from "./pages/auth/signin";
import DashboardPage from "./pages/dashboard";
import { SignupPage } from "./pages/auth/signup/SignupContainer";
import AccountPage from "./pages/account";
import RecordersPage from "./pages/recorders";
// import UsersPage from "./pages/users";
import UsersPage from "./pages/users/userManager";
import GroupsPage from "./pages/users/groupManager";
import AlertRulePage from "./pages/alert/rule";
import AlertPage from "./pages/alert";
import AlertHistoryPage from "./pages/alert/history";
import AlertHistoryDetail from "./pages/alert/history/AlertHistoryDetail";
import ReportPage from "./pages/reports";
import ReportType from "./pages/reports/view";
import VideoSharePage from "./pages/videoshare/list";
import VideoShareListPage from "./pages/videoshare/list/VideoSharePresenter";
import VideoShareView from "./pages/videoshare/view";
import SettingPage from "./pages/setting";
import EmailPage from "./pages/setting/email";
import NoticePage from "./pages/setting/siteNotices";

import HelpPage from "./pages/help";
import BillingPage from "./pages/billing";
import ProtectedRoute from "components/routes/ProtectedRoute";
import { MainLayout } from "components/templates/layout/MainLayout";

import VideoManager from "./pages/manager/videoManager";
import TechManager from "./pages/manager/troubleManager";
import OperationManager from "./pages/manager/operationManager";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setNotices } from "redux/reducers/notices/noticeReducer";
import { useSelector } from "react-redux";
import { selectCookies } from "redux/reducers/notices/cookieSelector";
import { ACCESS_TOKEN, AxiosInstance, REFRESH_TOKEN } from "api/axiosInstance";
import { useJsApiLoader } from "@react-google-maps/api";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { IconContext } from "react-icons";
import { CookieBar } from "components/element/CookieBar";
import { light, dark, calcRem } from "./styles/theme";
import styled, { DefaultTheme, ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/global";
import { NoticeInfo } from "api/interfaces/noticeInterface.interface";
import { useQuery } from "react-query";

import { Notice } from "components/element/Notice";
import { getAuthNotices } from "api/authAPI";
import GuestShare from "pages/guest/videoShare/GuestShare";
import { useCookies } from "react-cookie";
import { getCookie, removeCookie, setCookie } from "cookies/cookie";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import BigButton from "components/atoms/buttons/BigButton";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { ConfigProvider, Tree } from "antd";
import { checkTrialShowMenu } from "utils/AccountUtil";
import RouteChangeTracker from "RouteChangeTracker";
import Notfound from "components/templates/nopage/Notfound";
import OrgNotfound from "components/templates/nopage/OrgNotfound";
import { ResponseNewToken } from "api/interfaces/authInterface.interface";
import { getCookieExpireTime } from "utils/timeUtil";
import { store } from "redux/store";
import { ValidAuth, setIsAuth } from "redux/reducers/auth/isAuth";
import { useQueryClient } from "react-query";
import Spinner from "components/atoms/loader/Spinner";
import HealthDetail from "pages/reports/view/health/HealthDetail";
import NoData from "components/templates/nopage/NoData";

type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];
//const libraries: Libraries = ["places"];

AxiosInstance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (error) => {
    const err = error as AxiosError;
    if (err.response?.status === 401) {
      console.log("401 error start");

      try {
        const { data, status }: AxiosResponse<ResponseNewToken> =
          await AxiosInstance.post("/auth/accessToken", {
            grantType: "Bearer",
            refreshToken: getCookie(REFRESH_TOKEN),
          });
        if (status === 200) {
          error.config.headers[
            "Authorization"
          ] = `Bearer ${data.result.accessToken}`;
          setCookie({
            name: ACCESS_TOKEN,
            value: data.result.accessToken,
            options: {
              path: "/",
              expires: getCookieExpireTime(6),
              // secure : true,
              // httpOnly : true
            },
          });
          if (data.result.refreshToken !== undefined) {
            setCookie({
              name: REFRESH_TOKEN,
              value: data.result.refreshToken,
              options: {
                path: "/",
                expires: getCookieExpireTime(72),
                // secure : true,
                // httpOnly : true
              },
            });
          }
          return await axios.request(error.config);
        } else if (status === 599) {
          error.config.headers = {};
          store.dispatch(setIsAuth(false));
          return;
        } else {
          error.config.headers = {};
          store.dispatch(setIsAuth(false));
          return;
        }
      } catch (err) {
        store.dispatch(setIsAuth(false));
      }
    }
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (
    !config.url?.startsWith("/auth") ||
    !config.url?.startsWith("/videoShare/guest") ||
    !config.url?.startsWith("/videoShare/download/video") ||
    !config.url?.startsWith("/user/token") ||
    !config.url?.startsWith("/user/password")
  ) {
    config.headers!["Authorization"] = `Bearer ${getCookie(ACCESS_TOKEN)}`;
  }
  return config;
});

function App(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [initLoad, setInitLoad] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isActiveCookieConsent, setIsActiveCookieConsent] =
    useState<boolean>(false);
  const cookie = useSelector(selectCookies);
  const mapKey = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const [libraries] = useState<Libraries>(["places"]);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const isAuth: ValidAuth = useAppSelector((state) => state.isAuth);
  const [cookies, setCookies] = useCookies(["cookie_consent"]);

  const themeMode = useAppSelector((state) => state.theme.theme);
  const theme = themeMode === "light" ? light : dark;
  const [code, setCode] = useState<string>("");

  const preventClose = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    //sessionStorage.clear();
    try {
      const resp = AxiosInstance.get(
        `/auth/logout/${selectedAccount.accountId}`
      );
    } catch (error) {}
  };

  if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    console.error = function no_console() {};
  }

  useEffect(() => {
    try {
      const loadToken = async () => {
        //const token = await sessionStorage.getItem("token");
        if (getCookie(ACCESS_TOKEN)) {
          AxiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${getCookie(ACCESS_TOKEN)}}`;
        } else {
          AxiosInstance.defaults.headers.common["Authorization"] = "";
        }

        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");

        if (code !== undefined && code !== null) {
          setCode(code);
        }
      };

      if (cookies.cookie_consent === undefined) {
        setIsActiveCookieConsent(true);
      }

      loadToken();
    } catch (e) {
      console.log(e);
    } finally {
      setInitLoad(true);
    }

    // (() => {
    //   window.addEventListener("beforeunload", preventClose);
    // })();

    // return () => {
    //   window.removeEventListener("beforeunload", preventClose);
    // };
  }, [cookies.cookie_consent, location.search]);

  useEffect(() => {
    if (!cookie) {
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      setCookies("cookie_consent", 1, {
        path: "/",
        expires,
        // secure : true,
        // httpOnly : true
      });
      setIsActiveCookieConsent(false);
    }
  }, [cookie]);

  useEffect(() => {
    if (!isAuth.isAuthenticated) {
      console.log("queryClient clear");
      queryClient.setDefaultOptions({
        queries: {
          enabled: false,
        },
      });
    }
  }, [isAuth, queryClient]);

  const onCookieClose = () => {
    setIsActiveCookieConsent(false);
  };

  RouteChangeTracker();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    language: "en",
    libraries: libraries,
  });

  const { error, refetch } = useQuery(
    ["getAuthNotice"],
    () => getAuthNotices(),
    {
      // retry: 3,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        let tempNotices: NoticeInfo[] = res.filter(
          (r: any) =>
            getCookie(`notice-${r.noticeId}`) === undefined &&
            getCookie(`notice-${r.noticeId}`) !== 1
        );
        dispatch(setNotices(tempNotices));
      },
      onError: (e: any) => {},
    }
  );

  const onForwardLogin = () => {
    dispatch(setIsAuth(true));
    removeCookie(ACCESS_TOKEN);
    window.location.replace("/login");
  };

  // AxiosInstance.interceptors.request.use(
  //   (request) => {
  //     if (!request.url?.includes("dashboard")) {
  //       setLoading(true);
  //     }
  //     return request;
  //   },
  //   (error) => {
  //     setLoading(false);
  //     Promise.reject(error);
  //   }
  // );

  // // Configure incoming response interceptor logic
  // AxiosInstance.interceptors.response.use(
  //   (response) => {
  //     setLoading(false);
  //     return response;
  //   },
  //   (error) => {
  //     setLoading(false);
  //     Promise.reject(error);
  //   }
  // );

  // console.log(location.pathname.includes("guest"), "App");

  return (
    <ThemeProvider theme={theme}>
      <ProSidebarProvider>
        <ConfigProvider
          renderEmpty={() => <NoData theme={themeMode} />}
          theme={{
            components: {
              Popover: {
                minWidth: 0,
                width: 0,
                padding: 0,
              },
              Tree: {
                directoryNodeSelectedBg: COLORS.SELECTED,
              },
              Table: {
                cellPaddingBlock: 0,
                headerColor: `${theme.COLORS.WHITE}95`,
                headerSplitColor: "transparent",
                bodySortBg: "transparent",
                borderColor:
                  theme.value === "light"
                    ? "rgb(237, 237, 237)"
                    : COLORS.BORDER,
                filterDropdownBg: COLORS.WHITE,
                colorFillTertiary: COLORS.BLACK,
              },
              Modal: {
                contentBg: "transparent",
              },
            },
            token: {
              colorBgContainer: "transparent",
              borderRadiusOuter: 0,
              borderRadiusXS: 0,
              borderRadiusLG: 0,
              paddingSM: 0,
              // colorText: theme.COLORS.WHITE,
              colorLink: theme.COLORS.SELECTED,
              colorLinkActive: theme.COLORS.SELECTED,
              colorPrimary: theme.COLORS.SELECTED,
              fontFamily: `system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
              sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            },
          }}
        >
          <IconContext.Provider value={{}}>
            {initLoad && isLoaded ? (
              // <AuthProvider>
              <Fragment>
                <Notice />
                {!location.pathname.includes("guest") &&
                  isActiveCookieConsent && (
                    <CookieBar onClickClose={onCookieClose} />
                  )}
                {/* <Spin
                  // spinning={true}
                  spinning={loading}
                  size="large"
                  indicator={
                    <div className="progress-indicator progress-indicator--responsive" />
                  }
                > */}
                <Routes>
                  <Route>
                    <Route path="/" element={<LoginPage code={code} />} />
                    <Route path="/login" element={<LoginPage code={code} />} />
                    <Route
                      path="/callback"
                      element={<LoginPage code={code} />}
                    />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/reset" element={<SignupPage />} />
                    <Route
                      path="/guest/video/player"
                      element={<GuestShare />}
                    />
                  </Route>

                  <Route element={<MainLayout />}>
                    {!(
                      selectedAccount.accountLevel === LEVEL_TYPE.EU &&
                      checkTrialShowMenu(selectedAccount)
                    ) && (
                      <>
                        <Route
                          path="/dashboard/:view"
                          element={<DashboardPage />}
                        />
                        <Route path="/users/groups" element={<GroupsPage />} />
                        <Route
                          path="/alert/rules/*"
                          element={<AlertRulePage />}
                        />
                        {/* <Route
                          path="/alert/history/*"
                          element={<AlertHistoryPage />}
                        /> */}
                        <Route path="/reports" element={<ReportPage />} />
                        <Route
                          path="/reports/:type/:reportId"
                          element={<ReportType />}
                        />
                        <Route
                          path="/reports/:type/merged/:reportId/:mergedSystemId"
                          element={<ReportType />}
                        />
                        <Route
                          path="/reports/:type/detail/:reportId/:recorderId"
                          element={<HealthDetail />}
                        />
                        <Route path="/alert/active" element={<AlertPage />} />
                        <Route
                          path="/alert/history"
                          element={<AlertHistoryPage />}
                        />
                        <Route
                          path="/alert/history/merged/:mergedSystemId"
                          element={<AlertHistoryPage />}
                        />
                        <Route
                          path="/alert/history/detail/:recorderId"
                          element={<AlertHistoryDetail />}
                        />
                        <Route
                          path="/recorders/groups"
                          element={<RecordersPage />}
                        />
                        <Route
                          path="/recorders/groups/*"
                          element={<RecordersPage />}
                        />
                        <Route path="/videoshare" element={<VideoSharePage />}>
                          {/* <Route path="" element={<VideoShareListPage />} /> */}
                          {/* <Route path="/:videoId" element={<VideoShareView />} /> */}
                        </Route>
                        <Route
                          path="/videoshare/:videoId"
                          element={<VideoShareView />}
                        />
                        <Route
                          path="/billing/customeraccount"
                          element={<BillingPage />}
                        />
                        <Route path="/billing/*" element={<BillingPage />} />
                      </>
                    )}
                    <Route path="/users" element={<UsersPage />} />
                    <Route path="/users/list" element={<UsersPage />} />

                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/account" element={<AccountPage />} />

                    <Route path="/recorders/list" element={<RecordersPage />} />
                    <Route
                      path="/recorders/list/detail/:mergedSystemId"
                      element={<RecordersPage />}
                    />
                    <Route path="/billing/*" element={<BillingPage />} />
                    <Route
                      path="/settings/:type/*"
                      element={
                        <ProtectedRoute>
                          <SettingPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/manager/guideSetting"
                      element={<VideoManager />}
                    />
                    <Route
                      path="/manager/techSummit"
                      element={<TechManager />}
                    />
                    <Route
                      path="/manager/operationLog"
                      element={<OperationManager />}
                    />

                    <Route path="/manager/emails" element={<EmailPage />} />
                    <Route
                      path="/manager/emails/recipients/:subject/:id"
                      element={<EmailPage />}
                    />

                    <Route
                      path="/manager/sitenotices"
                      element={<NoticePage />}
                    />

                    <Route path="/help/*" element={<HelpPage />} />

                    <Route
                      path="/protected"
                      element={
                        <ProtectedRoute>
                          <DashboardPage />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="*" element={<Notfound />} /> */}
                  </Route>
                  <Route path="/error/403" element={<OrgNotfound />} />
                  <Route path="*" element={<Notfound />} />
                </Routes>
                {/* </Spin> */}
              </Fragment>
            ) : (
              // </AuthProvider>
              <div>
                <Spinner />
              </div>
            )}
            {!isAuth.isLogout && !isAuth.isAuthenticated && (
              <SmallPopupDefaultTemplate
                button={<BigButton label="Login" onClickBtn={onForwardLogin} />}
                disableClose
                //onModal={() => setIsOpenReLogin(false)}
              >
                <Container>
                  <Title>
                    {"Session Expired"}
                    <br />
                    <strong>
                      Your session has been closed for security reasons.
                      <br />
                      You will be redirected to the login page.
                    </strong>
                  </Title>
                </Container>
              </SmallPopupDefaultTemplate>
            )}
          </IconContext.Provider>
        </ConfigProvider>
      </ProSidebarProvider>
      <GlobalStyle />
    </ThemeProvider>
  );
}
export default App;

const Title = styled.div`
  font-weight: 500;
  color: ${COLORS.WHITE};
  font-size: ${calcRem(20)};
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(24)};
  strong {
    color: ${COLORS.COMPANY};
    font-size: ${calcRem(18)};
  }
`;
const Container = styled.div`
  max-width: 100%;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
`;
