import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import {
  CVVReport,
  InstallReport,
  ReportDataRow,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";
import { useLocation } from "react-router-dom";
import AddReport from "./add";
import { DataRow } from "./ReportContainer";
import {
  HealthReport,
  InventoryReport,
} from "api/interfaces/reportInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { useAppSelector } from "redux/hooks";
import * as mediaQuery from "components/MediaQuery";
import { BasePagination } from "api/interfaces/commonInterface.interface";

type Props = {
  columns: TableColumn<ReportDataRow>[];
  mobileColumns: TableColumn<ReportDataRow>[];
  data: DataRow[];
  onModal: (type?: string) => void;
  isAddModal: boolean;
  isEditModal: boolean;
  status: StatusType;
  onRowClicked: (row: ReportDataRow) => void;
  selectReport?: number;
  selectReportRow?: ReportDataRow;
  selectData?:
    | HealthReport
    | InventoryReport
    | InstallReport
    | CVVReport
    | ThumbnailReport;
  selectedAccount: ProfileAccountInfo;
  paginationPerPage: number;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  paginationTotalRows: number;
  onClickSearch: ClickSearch;
  isRowClickEditable: boolean;
  tablePending: boolean;
  paginationInfo: BasePagination;
};

export default function ReportPresenter(props: Props): JSX.Element {
  const centerChildren = <Status status={props.status} />;
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  return (
    <>
      <TableTemplate
        onClickBtn={() => props.onModal("add")}
        buttonLabel="Add Report"
        centerChildren={centerChildren}
        onClickSearch={props.onClickSearch}
        keyword={props.paginationInfo.keyword}
        requiredPermissions={
          props.selectedAccount.accountLevel !== "EU" ? "" : "manageReportRules"
        }
      >
        <mediaQuery.Default>
          <Table
            columns={
              selectedAccount.accountLevel === "EU"
                ? props.columns.filter(
                    (column) => column.sortField !== "accountName"
                  )
                : props.columns
            }
            data={props.data}
            onRowClicked={props.onRowClicked}
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage}
            onChangePage={props.onChangePage}
            handleSort={props.handleSort}
            defaultSortFieldId={"createdAt"}
            defaultSortAsc={false}
            isRowClickEditable={props.isRowClickEditable}
            progressPending={props.tablePending}
          />
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          <Table
            columns={
              selectedAccount.accountLevel === "EU"
                ? props.mobileColumns.filter(
                    (column) => column.sortField !== "accountName"
                  )
                : props.mobileColumns
            }
            data={props.data}
            onRowClicked={props.onRowClicked}
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage}
            onChangePage={props.onChangePage}
            handleSort={props.handleSort}
            defaultSortFieldId={"createdAt"}
            defaultSortAsc={false}
            isRowClickEditable={props.isRowClickEditable}
          />
        </mediaQuery.Mobile>
      </TableTemplate>
      {props.isAddModal && <AddReport onModal={() => props.onModal("add")} />}
      {props.isEditModal && (
        <AddReport
          type={props.selectReport}
          reportRow={props.selectReportRow}
          data={props.selectData}
          onModal={() => props.onModal("edit")}
          isEdit
        />
      )}
    </>
  );
}
