import { AddModalPresenter } from "./AddModalPresenter";
import {
  ICON_TYPE,
  NOTICE_COLOR,
  Notice,
} from "api/interfaces/noticeInterface.interface";
import { deleteNotice, postNotice, putNotice } from "api/noticeAPI";
import { notify } from "components/atoms/notification/Notification";
import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { isEmpty, isNumber } from "lodash";
import { BACKGROUND_COLORS } from "styles/colors";
interface Props {
  isEdit?: boolean;
  selectedNotice?: Notice | undefined;
  onModal: () => void;
  validateMsg?: ValidateMsg;
}

export function AddModalContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [isPermanently, setIsPermanently] = useState<boolean>(true);
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    title: "",
    message: "",
  });

  const [notice, setNotice] = useState<Notice>(
    props.isEdit && props.selectedNotice !== undefined
      ? props.selectedNotice
      : {
          subject: "",
          message: "",
          icon: ICON_TYPE.NONE,
          color: `BLUE` as NOTICE_COLOR,
          period: 0,
          isDisplay: false,
        }
  );

  /**
   * @todo Update SetNotice
   */

  const onChange = (e: any) => {
    let {
      target: { value, name },
    } = e;

    if (name === "period") {
      if (value === "0" || value === 0 || !isNumber(Number(value))) return;
      setNotice({
        ...notice,
        period: Number(value),
      });
      return;
    }

    setNotice({
      ...notice,
      [name]: value,
    });
  };

  const mutationCreateNotice = useMutation(postNotice, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.setting.notice.notify.add.success",
        defaultMessage: "Notice added successfully.",
      }));
      props.onModal();
      queryClient.invalidateQueries("getNoticeInNoticePage");
    },
    onError: () => {
      // setIsAddModal(false);
      notify("error", intl.formatMessage({
        id: "label.setting.notice.notify.add.fail",
        defaultMessage: "Fail to add Notice.",
      }));
    },
  });

  const onClickSave = (notice: Notice) => {
    setValidateMsg((info) => {
      return { title: "", message: "" };
    });

    if (isEmpty(notice.subject)) {
      setValidateMsg((info) => {
        return {
          ...info,
          title: intl.formatMessage({
            id: "validateMsg.notice.title",
            defaultMessage: "Please enter Notice Title",
          }) as string,
        };
      });
      return;
    }

    if (isEmpty(notice.message)) {
      setValidateMsg((info) => {
        return {
          ...info,
          message: intl.formatMessage({
            id: "validateMsg.notice.message",
            defaultMessage: "Please enter Notice Message",
          }) as string,
        };
      });
      return;
    }

    mutationCreateNotice.mutate({
      payload: { ...notice, period: isPermanently ? 0 : notice.period },
      accountId: selectedAccount.accountId,
    });
  };

  const mutationUpdateNotice = useMutation(putNotice, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.setting.notice.notify.update.success",
        defaultMessage: "Notice updated successfully.",
      }));
      props.onModal();
      queryClient.invalidateQueries("getNoticeInNoticePage");
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.setting.notice.notify.update.fail",
        defaultMessage: "Fail to update Notice.",
      }));
    },
  });

  const onUpdate = useCallback(() => {
    if (props.isEdit && props.selectedNotice) {
      if (props.selectedNotice.noticeId) {
        setValidateMsg((info) => {
          return { title: "", message: "" };
        });

        if (isEmpty(notice.subject)) {
          setValidateMsg((info) => {
            return {
              ...info,
              title: intl.formatMessage({
                id: "validateMsg.notice.title",
                defaultMessage: "Please enter Notice Title",
              }) as string,
            };
          });
          return;
        }

        if (isEmpty(notice.message)) {
          setValidateMsg((info) => {
            return {
              ...info,
              message: intl.formatMessage({
                id: "validateMsg.notice.message",
                defaultMessage: "Please enter Notice Message",
              }) as string,
            };
          });
          return;
        }
        mutationUpdateNotice.mutate({
          payload: notice,
          noticeId: props.selectedNotice.noticeId,
          accountId: selectedAccount.accountId,
        });
      }
    }
  }, [mutationUpdateNotice, notice]);

  const mutationDeleteNotice = useMutation(deleteNotice, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.setting.notice.notify.delete.success",
        defaultMessage: "Notice deleted successfully.",
      }));
      queryClient.invalidateQueries("getNoticeInNoticePage");
      props.onModal();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.setting.notice.notify.delete.fail",
        defaultMessage: "Fail to delete Notice.",
      }));
    },
  });

  const onChangePermanent = (e: any) => {
    setIsPermanently(!isPermanently);
  };

  const onDelete = useCallback(() => {
    if (props.isEdit && props.selectedNotice) {
      mutationDeleteNotice.mutate({
        accountId: selectedAccount.accountId,
        noticeId: props.selectedNotice.noticeId as string,
      });
    }
  }, [mutationDeleteNotice, props.selectedNotice, props.isEdit]);

  return (
    <AddModalPresenter
      isEdit={props.isEdit}
      onModal={props.onModal}
      onChange={onChange}
      notice={notice}
      onClickSave={onClickSave}
      onDelete={onDelete}
      onUpdate={onUpdate}
      validateMsg={validateMsg}
      isPermanently={isPermanently}
      onChangePermanent={onChangePermanent}
    />
  );
}
