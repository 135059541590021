import * as s from "../../VideoShareStyled";
import { Text } from "components/atoms/text/Text";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import DateSelect from "components/atoms/select/DateSelect";
import TextArea from "components/atoms/input/TextArea";
import {
  VideoShareUserListResult,
  VideoShareUserUpdate,
} from "api/interfaces/videoshareInterface.interface";
import { COLORS } from "styles/colors";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { ButtonWrapper } from "components/templates/modal/ModalTemplateStyled";
import { convertVideoShareTime } from "utils/timeUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type Props = {
  selectedAccount: ProfileAccountInfo;
  state: VideoShareUserListResult;
  newState: VideoShareUserUpdate;
  isExpired: boolean;
  onChangeModal: (type: string, value: boolean) => void;
  onChangeValues: (e: any) => void;
  onChangeDateValue: (date: Date) => void;
  onFinish: () => void;
  onClickDelete: () => void;
};

export default function EditVSPresenter({
  selectedAccount,
  state,
  newState,
  onChangeModal,
  onChangeValues,
  onChangeDateValue,
  onFinish,
  isExpired,
  onClickDelete,
}: Props): JSX.Element {
  const buttons = (
    <ButtonWrapper>
      <PrimaryButton
        buttonType="warning"
        label="Delete"
        onClickBtn={onClickDelete}
      />
      <PrimaryButton label="Save" onClickBtn={onFinish} />
    </ButtonWrapper>
  );
  return (
    <SmallPopupTemplate
      title="Edit Participant"
      subTitle="Edit permissions for a sharing participant, or delete them."
      onClick={onFinish}
      onModal={() => onChangeModal("edit", false)}
      buttons={buttons}
    >
      <s.EditTextWrapper>
        <Text className="title">Name</Text>
        <Text className="content">{state.name}</Text>
      </s.EditTextWrapper>
      <s.EditTextWrapper>
        <Text className="title">Email</Text>
        <Text className="content">{state.email}</Text>
      </s.EditTextWrapper>
      <s.MessageOptional>
        <Text fontSize={18}>Message (Optional)</Text>
        <TextArea
          name="message"
          value={newState.message}
          onChange={onChangeValues}
          placeholder="Enter your message here."
        />
         <s.AddCheck>
          <label>
            <input
              type="checkbox"
              name="isExpired"
              checked={isExpired}
              onChange={onChangeValues}
            />{" "}
            <Text>Expiration</Text>
          </label>
          <p className="date-select">
            <DateSelect
              disabled={!isExpired}
              value={convertVideoShareTime(newState.expires, selectedAccount.timezone)}
              onChange={onChangeDateValue}
              tileDisabled={({ date, view }) =>
                view === "month" && date <= new Date()
              }
            />
          </p>
        </s.AddCheck>
        <s.AddCheck>
        <label>
          <input
            type="checkbox"
            name="canDownload"
            checked={newState.canDownload}
            onChange={onChangeValues}
          />{" "}
          <Text>Allow Downloading</Text>
        </label>
        </s.AddCheck>
       
      </s.MessageOptional>
    </SmallPopupTemplate>
  );
}
