import { useCallback, useEffect, useState } from "react";
import ReportPresenter from "./ReportPresenter";
import * as s from "./ReportStyled";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  CVVReport,
  GetReportListResposne,
  InstallReport,
  ReportDataRow,
  ReportRecorderGroup,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import {
  changeTypeForReportType,
  changeTypeNumberForReportText,
  getNumberTypeForReportType,
  isEditAble,
} from "utils/functions";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  getReportInfo,
  getReportInfoCVV,
  getReportInfoInstall,
  getReportInfoThumbnail,
  getReportList,
} from "api/reportAPI";
import { useAuth } from "components/Auth";
import { getAccountTimeFormat } from "utils/timeUtil";
import DetailButton from "components/atoms/buttons/detail/DetailButton";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import {
  HealthReport,
  InventoryReport,
} from "api/interfaces/reportInterface.interface";
import { isCheckPermission } from "utils/AccountUtil";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import AccountId from "components/atoms/text/labels/AccountId";

export interface DataRow {
  type: string;
  title: string;
  recorder: string;
  createdBy: string;
  email: string;
  createdAt: string;
  enable: boolean;
  accountName: string;
  accountNumber: string;
  recorderTotalCount: number;
}

const initialDetailCP = [
  {
    name: "health",
    value: 0,
    label: "Health",
  },
  {
    name: "inventory",
    value: 0,
    label: "Inventory",
  },
  {
    name: "installation",
    value: 0,
    label: "Installation",
  },
];

const initialDetailEU = [
  {
    name: "health",
    value: 0,
    label: "Health",
  },
  {
    name: "cvv",
    value: 0,
    label: "CVV",
  },
  // {
  //   name: "thumbnail",
  //   value: 0,
  //   label: "Thumbnail",
  // },
  {
    name: "inventory",
    value: 0,
    label: "Inventory",
  },
  {
    name: "installation",
    value: 0,
    label: "Installation",
  },
];

const typeFiltersCP = [
  {
    label: "Health",
    value: "health",
  },
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Installation",
    value: "installation",
  },
];

const typeFiltersEU = [
  {
    label: "Health",
    value: "health",
  },
  {
    label: "CVV",
    value: "cvv",
  },
  // {
  //   label: "Thumbnail",
  //   value: "thumbnail",
  // },
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Installation",
    value: "installation",
  },
];

export default function ReportContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [pending, setPending] = useState<boolean>(true);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageReportRules", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageReportRules", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const [selectReport, setSelectReport] = useState<number>();
  const [selectReportRow, setSelectReportRow] = useState<ReportDataRow>();
  const [selectReportEnable, setSelectReportEnable] = useState<boolean>();

  const [selectData, setSelectData] = useState<
    HealthReport | InventoryReport | InstallReport | CVVReport | ThumbnailReport
  >();

  const onViewDetail = (row: ReportDataRow) => {
    navigate({
      pathname: `/reports/${changeTypeNumberForReportText(row.type)}/${row.id}`,
      search: `?${createSearchParams({
        account: selectedAccount.accountId,
      })}`,
    });
  };
  const [reportList, setReportList] = useState<DataRow[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [detailItems, setDetailItems] = useState<
    { name: string; value: number; label: string }[]
  >(
    selectedAccount.accountLevel === LEVEL_TYPE.CP
      ? initialDetailCP
      : initialDetailEU
  );

  const status = {
    totalTitle: "Total",
    totalValue: totalCount,
    items: [
      <DetailButton
        title={"Report Quantities by category"}
        contents={detailItems}
      />,
    ],
  };

  const [reportQueryInfo, setReportQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "createdAt",
    sortDirection: SORT_DIRECTION.ASC,
    type: null,
  });

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setReportQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "createdAt"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
        };
      });
    } else {
      setReportQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "createdAt",
          sortDirection: SORT_DIRECTION.ASC,
          type: null,
        };
      });
    }
  }, [location, navigate]);

  const onChangeReportType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (reportQueryInfo.type !== null) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: reportQueryInfo.pageLimit,
                pageNum: 0,
                keyword: reportQueryInfo.keyword as string,
                sortType: reportQueryInfo.sortType,
                sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
                type: null,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setReportQueryInfo((info) => {
          //   return { ...info, type: null, pageNum: 0 };
          // });
        } else {
          if (reportQueryInfo.type !== value) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: reportQueryInfo.pageLimit,
                pageNum: 0,
                keyword: reportQueryInfo.keyword as string,
                sortType: reportQueryInfo.sortType,
                sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
                type: value,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setReportQueryInfo((info) => {
          //   return { ...info, type: value as string, pageNum: 0 };
          // });
        }
        setPending(true);
      }
    },
    [
      location.pathname,
      navigate,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      reportQueryInfo.type,
      selectedAccount,
    ]
  );

  // table columns
  const columns: TableColumn<ReportDataRow>[] = [
    {
      name: (
        <TableColumnFilterTitle
          title="Type"
          filters={
            selectedAccount.accountLevel === LEVEL_TYPE.CP
              ? typeFiltersCP
              : typeFiltersEU
          }
          onClick={onChangeReportType}
        />
      ),
      grow: 1,
      // sortable: true,
      // sortField: "type",
      minWidth: "110px",
      cell: (row) => (
        <s.Row onClick={() => onViewDetail(row)}>
          {changeTypeForReportType(row.type)}
        </s.Row>
      ),
    },
    {
      name: "Title",
      // sortable: true, // 정렬 활성화,
      grow: 3,
      sortable: true,
      sortField: "title",
      selector: (row) => row.title,
    },
    {
      name: "Organization",
      grow: 1.4,
      sortable: true,
      sortField: "accountName",
      cell: (row) => (
        <s.AccountBox>
          {row.accountName}
          <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
        </s.AccountBox>
      ),
    },
    {
      name: "Recorder",
      grow: 4,
      cell: (row) => (
        <s.RecorderRow status={row.recorderTotalCount > 0 ? true : false}>
          {row.recorder}{" "}
        </s.RecorderRow>
      ),
      hide: Media.SM,
    },
    {
      name: "Created By",
      grow: 2,
      minWidth: "180px",
      sortable: true,
      sortField: "createdBy",
      selector: (row) => row.createdBy,
      hide: Media.SM,
    },
    {
      id: "createdAt",
      name: "Date Created",
      grow: 1,
      sortable: true,
      sortField: "createdAt",
      minWidth: "250px",
      // selector: (row) => changeTimeStampFormat(row.createdDate),
      selector: (row) =>
        row.createdAt !== undefined
          ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
          : "",
      hide: Media.SM,
    },
    // {
    //   name: "Enable",
    //   center: true,
    //   grow: 1,
    //   minWidth: Media.LG ? "120px" : "100px",
    //   cell: (row) =>
    //     row.enable !== null ? (
    //       <Toggle
    //         isToggled={row.enable}
    //         onChange={() => console.log("is toggle on alert rules enable")}
    //       />
    //     ) : (
    //       "N/A"
    //     ),
    // },
    // 마지막 버튼
    {
      name: "",
      minWidth: "140px",
      cell: (row) => (
        <s.Row>
          <RBACWrapper requiredPermissions="manageReportRules">
            {selectedAccount.accountLevel === "EU" ? (
              <TableButton
                label="Edit"
                onClickBtn={() => onClickEdit(row)}
                // disabled={
                //   !isEditAble(
                //     selectedAccount,
                //     auth.user,
                //     row.createdBy,
                //     row.email
                //   )
                // }
              />
            ) : null}
          </RBACWrapper>
          <span className="row-click-button">
            <TableButton label="View" onClickBtn={() => onViewDetail(row)} />
          </span>
        </s.Row>
      ),
      right: true,
    },
  ];

  const mobileColumns: TableColumn<ReportDataRow>[] = [
    {
      name: (
        <TableColumnFilterTitle
          title="Type"
          filters={
            selectedAccount.accountLevel === LEVEL_TYPE.CP
              ? typeFiltersCP
              : typeFiltersEU
          }
          onClick={onChangeReportType}
        />
      ),
      grow: 1,
      // sortable: true,
      // sortField: "type",
      cell: (row) => (
        <s.Row onClick={() => onViewDetail(row)}>
          {changeTypeForReportType(row.type)}
        </s.Row>
      ),
    },
    {
      name: "Title",
      // sortable: true, // 정렬 활성화,
      grow: 3,
      sortable: true,
      sortField: "title",
      selector: (row) => row.title,
    },
    {
      name: "Organization",
      grow: 1.4,
      sortable: true,
      sortField: "accountName",
      cell: (row) => (
        <s.AccountBox>
          {row.accountName}
          <span className="account-id">{row.accountNumber}</span>
        </s.AccountBox>
      ),
    },
    // 마지막 버튼
    {
      name: "",
      cell: (row) => (
        <s.Row>
          <RBACWrapper requiredPermissions="manageReportRules">
            {selectedAccount.accountLevel === "EU" ? (
              <TableButton
                label="Edit"
                onClickBtn={() => onClickEdit(row)}
                // disabled={
                //   !isEditAble(
                //     selectedAccount,
                //     auth.user,
                //     row.createdBy,
                //     row.email
                //   )
                // }
              />
            ) : null}
          </RBACWrapper>
          <span className="row-click-button">
            <TableButton label="View" onClickBtn={() => onViewDetail(row)} />
          </span>
        </s.Row>
      ),
      right: true,
    },
  ];

  const onClickEdit = (row: ReportDataRow) => {
    const reportTypeNumber: number = getNumberTypeForReportType(row.type);
    setSelectReport((report) => {
      return reportTypeNumber;
    });
    setSelectReportRow((report) => {
      return row;
    });

    switch (reportTypeNumber) {
      case 0:
      case 3:
        getReportInfo({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as HealthReport;
            });
            onModal("edit");
          }
        });
        break;
      case 1:
        getReportInfoCVV({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as CVVReport;
            });
            onModal("edit");
          }
        });
        break;
      case 2:
        getReportInfoThumbnail({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as ThumbnailReport;
            });
            onModal("edit");
          }
        });
        break;
      case 4:
        getReportInfoInstall({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as InstallReport;
            });
            onModal("edit");
          }
        });
        break;
      default:
        break;
    }
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("reportList");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("reportList");
      }
      setIsEditModal(!isEditModal);
    }
  };

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const displayRecorder = (
    systems: any[],
    recorderGroup: any[],
    recorderTotalCount: number,
    type: string,
    installationRecorder?: string
  ) => {
    let name = "";
    let count = recorderTotalCount;
    let mergedCount = 0;

    if (systems !== undefined && systems.length > 0) {
      mergedCount += systems.length;
      systems.forEach((merged: any) => {
        if (merged.recorders !== undefined) {
          if (name === "") {
            if (type !== "INSTALLATION") {
              name = merged.mergedSystemName;
            } else {
              if (
                merged.recorders !== undefined &&
                merged.recorders.length > 0
              ) {
                name = merged.recorders[0].name;
              }
            }
          }
        }
      });
    }

    if (recorderGroup !== undefined && recorderGroup.length > 0) {
      recorderGroup.forEach((group: any) => {
        mergedCount += group.recorderCount;
        if (group.systems !== undefined && group.systems.length > 0) {
          group.systems.forEach((merged: any) => {
            if (name === "") {
              name = merged.mergedSystemName;
            }
          });
        }
      });
    }

    if (type === "INSTALLATION") {
      if (systems === undefined && installationRecorder !== undefined) {
        count = 1;
        name = `${installationRecorder} (Deleted)`;
      }
    }
    return count === 0
      ? "No Recorder"
      : count === 1 || mergedCount === 1
      ? name
      : name + `+${count - 1}`;
  };

  const { error, refetch } = useQuery(
    ["reportList", reportQueryInfo],
    () =>
      getReportList({
        payload: reportQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetReportListResposne) => {
        if (res.result === undefined || res.page?.total === 0) {
          setTotalCount(0);
          setDetailItems(
            selectedAccount.accountLevel === LEVEL_TYPE.CP
              ? initialDetailCP
              : initialDetailEU
          );
          setReportList([]);

          return;
        }

        setDetailItems(
          (selectedAccount.accountLevel === LEVEL_TYPE.CP
            ? initialDetailCP
            : initialDetailEU
          ).map((item) => {
            for (const typeInfo of res.result.typeInfos) {
              if (item.name === typeInfo.type.toLowerCase()) {
                return { ...item, value: typeInfo.typeCount };
              }
            }
            return {
              ...item,
            };
          })
        );

        setReportList(
          res.result.reports.map((report: any) => {
            // console.log(report);
            return {
              id: report.id,
              type: report.type,
              title: report.title,
              recorder: report.autoApply
                ? "ALL"
                : displayRecorder(
                    report.systems,
                    report.recorderGroups,
                    report.recorderTotalCount,
                    report.type,
                    report.installationRecorder
                  ),
              createdBy: report.createdBy,
              email: report.email,
              createdAt: report.createdAt,
              accountName: report.accountName,
              accountNumber: report.accountNumber,
              enable: report.isEnable,
              installationRecorder: report.installationRecorder,
              recorderTotalCount: report.recorderTotalCount,
              userId: report.userId,
            };
          })
        );
        setTotalCount(res.page.total);
      },
      onError: (e: any) => {
        setTotalCount(0);
        setDetailItems(
          selectedAccount.accountLevel === LEVEL_TYPE.CP
            ? initialDetailCP
            : initialDetailEU
        );
        setReportList([]);
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.list.search.fail",
            defaultMessage: "Fail to search report List",
          })
        );
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (reportQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: reportQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: reportQueryInfo.sortType,
            sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
            type: reportQueryInfo.type,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
      // setReportQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [
      location.pathname,
      navigate,
      refetch,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      reportQueryInfo.type,
      selectedAccount,
    ]
  );

  const onChangePage = (page: number, totalRows: number) => {
    if (page !== reportQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: reportQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: reportQueryInfo.keyword as string,
          sortType: reportQueryInfo.sortType,
          sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
          type: reportQueryInfo.type,
        },
        selectedAccount
      );
      setPending(true);
      // setReportQueryInfo((info) => {
      //   return { ...info, pageNum: (page - 1) as number };
      // });
    }
  };

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setReportQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };

  return (
    <ReportPresenter
      columns={columns}
      mobileColumns={mobileColumns}
      data={reportList}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      status={status}
      onRowClicked={onViewDetail}
      selectReport={selectReport}
      selectReportRow={selectReportRow}
      selectData={selectData}
      selectedAccount={selectedAccount}
      paginationPerPage={reportQueryInfo.pageLimit}
      paginationInfo={reportQueryInfo}
      onChangePage={onChangePage}
      handleSort={handleSort}
      paginationTotalRows={totalCount}
      onClickSearch={onClickSearch}
      isRowClickEditable={isRowClickEditable}
      tablePending={pending}
    />
  );
}
