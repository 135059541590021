import { useEffect, useState } from "react";
import LicensePresenter from "./LicensePresenter";

import { RequestLicenseType } from "./LicenseRequestModal";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useLocation } from "react-router-dom";
import {
  getLicenseInfo,
  postChannelPayLicense,
  postStoragePayLicense,
} from "api/licenseAPI";
import { useQuery } from "react-query";
import { LicenseInfo } from "api/interfaces/licenseInterface.interface";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useQueryClient } from "react-query";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";

export interface BubbleType {
  [key: string]: boolean;
}
interface Props {}

interface LicenseStateProps {
  accountId: string;
  accountName: string;
}

const initialRequest: LicenseInfo = {
  currentLicenseType: "none",
  cartLicenseType: "",
  cartMessage: "",
  totalLicensedCameraCount: 0,
  totalCameraCount: 0,
  channelLicenseQuota: 0,
  channelRequired: 0,
  channelOldAmount: 0,
  channelNewAmount: 0,
  storageQuotaInBytes: 0,
  usedStorageInBytes: 0,
  storageOldAmount: 0,
  storageNewAmount: 0,
  storageMessage: "",
  channelIsPreparing: false,
  storageIsPreparing: false,
};

export default function LicenseContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  // const accountId: string = location.state
  //   ? location.state.accountId
  //   : selectedAccount.accountId;
  // const accountName: string = location.state
  //   ? location.state.name
  //   : selectedAccount.accountName;

  const [licenseState, setLicenseState] = useState<LicenseStateProps>({
    accountId: location.state
      ? location.state.accountId
      : selectedAccount.accountId,
    accountName: location.state
      ? location.state.name
      : selectedAccount.accountName,
  });

  useEffect(() => {
    // console.log(location, selectedAccount);
    if (location.state) {
      setLicenseState({
        accountId: location.state
          ? location.state.accountId
          : selectedAccount.accountId,
        accountName: location.state
          ? location.state.name
          : selectedAccount.accountName,
      });
    }
  }, [location, selectedAccount.accountId, selectedAccount.accountName]);

  // console.log(accountId, accountName);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isBubble, setIsBubble] = useState<BubbleType>({
    required: false,
    sendLicense: false,
    sendStorage: false,
  });

  const [requestType, setRequestType] = useState<"license" | "storage">(
    "license"
  );

  // console.log(location.state);
  const [request, setRequest] = useState<LicenseInfo>(initialRequest);

  const minChannelNewAmount: number =
    request.totalCameraCount < request.channelLicenseQuota
      ? request.totalCameraCount - request.channelLicenseQuota
      : request.channelRequired;

  const getLicenseDetailInfo = useQuery(
    ["license_info", licenseState.accountId],
    () =>
      getLicenseInfo({
        accountId: selectedAccount.accountId,
        euAccountId: licenseState.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: licenseState.accountId !== "",
      onSuccess: (res: any) => {
        console.log(res, "getLicenseDetailInfo");
        if (res.result === undefined) {
          setRequest(initialRequest);
        } else {
          setRequest({
            ...request,
            ...res.result,
            channelOldAmount: res.result.channelNewAmount,
            storageOldAmount: res.result.storageNewAmount,

            cartLicenseType:
              res.result.currentLicenseType === "none"
                ? "standard"
                : res.result.currentLicenseType,
          });
        }
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.license.notify.search.fail",
            defaultMessage: "License info search error",
          })
        );
        setRequest(initialRequest);
      },
    }
  );

  const mutationCPStorageLicense = useMutation(postStoragePayLicense, {
    onSuccess: (res: any) => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.license.notify.checkout.success",
          defaultMessage: "License checkout successfully.",
        })
      );
      // setRequest(initialRequest);
      // console.log(res, "mutationStorageLicense");
    },
    onError: (e: CustomError) => {
      handleError(
        e,
        intl.formatMessage({
          id: "label.license.notify.checkout.fail",
          defaultMessage: "Fail to checkout license.",
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("license_info");
    },
  });

  const mutationCPChannelLicense = useMutation(postChannelPayLicense, {
    onSuccess: (res: any) => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.license.notify.checkout.success",
          defaultMessage: "License checkout successfully.",
        })
      );
      // setRequest(initialRequest);
      // queryClient.invalidateQueries("license_info");
      // console.log(res, "mutationStorageLicense");
    },
    onError: (e: CustomError) => {
      handleError(
        e,
        intl.formatMessage({
          id: "label.license.notify.checkout.fail",
          defaultMessage: "Fail to checkout license.",
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("license_info");
    },
  });

  const onChangeModal = () => {
    setIsModal(!isModal);
  };

  const onChangeBubble = (name: string) => {
    setIsBubble({
      ...isBubble,
      [name]: !isBubble[name],
    });
  };

  const onSendRequest = (requestType: "license" | "storage") => {
    console.log(request.storageNewAmount, requestType, isModal, "request");
    setRequestType(requestType);
    if (requestType === "storage" && request.storageNewAmount <= 0) {
      // console.log(1);
      return;
    }
    if (
      (requestType === "license" &&
        request.channelNewAmount < minChannelNewAmount) ||
      (requestType === "license" && request.channelNewAmount === 0)
    ) {
      // console.log(2);
      return;
    }
    if (selectedAccount.accountLevel === LEVEL_TYPE.CP) {
      // console.log(3, selectedAccount);
      if (requestType === "license") {
        mutationCPChannelLicense.mutate({
          accountId: licenseState.accountId,
          payload: {
            cartLicenseType: request.cartLicenseType,
            channelLicenseQuota: request.channelLicenseQuota,
            channelNewAmount: request.channelNewAmount,
            channelOldAmount: request.channelOldAmount,
            cartMessage: request.cartMessage,
          },
        });
      } else {
        mutationCPStorageLicense.mutate({
          accountId: licenseState.accountId,
          payload: {
            storageQuotaInBytes: request.storageQuotaInBytes,
            storageNewAmount: request.storageNewAmount,
            storageOldAmount: request.storageOldAmount,
            storageMessage: request.storageMessage,
          },
        });
      }
      return;
    } else {
      // console.log(4, "???");
      setIsModal(true);
    }
  };

  const onChangeRequest = (e: any) => {
    let {
      target: { value, name },
    } = e;

    setRequest({
      ...request,
      [name]: value,
    });
  };

  const onChangeNewAmount = (value: number | string | null, name: string) => {
    if (typeof value === "string") {
      return;
    }

    if (!Number.isInteger(value)) {
      return;
    }

    if (
      name === "channelNewAmount" &&
      typeof value === "number" &&
      request.totalCameraCount < request.channelLicenseQuota &&
      value < request.totalCameraCount - request.channelLicenseQuota
    ) {
      return;
    }

    setRequest({
      ...request,
      [name]: value,
    });
  };

  const onChangeRequestLicenseType = (type: RequestLicenseType) => {
    if (type === "none") return;

    setRequest({
      ...request,
      cartLicenseType: type,
    });
  };

  return (
    <LicensePresenter
      requestType={requestType}
      accountId={licenseState.accountId}
      accountName={licenseState.accountName}
      request={request}
      isModal={isModal}
      isBubble={isBubble}
      minChannelNewAmount={minChannelNewAmount}
      onChangeModal={onChangeModal}
      onSendRequest={onSendRequest}
      onChangeRequest={onChangeRequest}
      onChangeBubble={onChangeBubble}
      onChangeRequestLicenseType={onChangeRequestLicenseType}
      onChangeNewAmount={onChangeNewAmount}
    />
  );
}
