import SearchInput from "components/atoms/input/search/SearchInput";

import * as s from "../AccountStyled";
import {
  GetAccountListQuery,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { useEffect, useState } from "react";
import { SORT_TYPE } from "api/interfaces/commonInterface.interface";
import { Account } from "api/mappers/accountMapper";
import { useQuery } from "react-query";
import { getAccoountList, getSwitchAccountList } from "api/accountAPI";
import { getAccountInfo, getSwitchAccountInfo } from "utils/AccountUtil";
import { notify } from "components/atoms/notification/Notification";
import { setBreadcrumbRoute } from "redux/reducers/breadcrumb/breadcrumb";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useIntl } from "react-intl";
import SwitchAccountList from "./SwitchAccountList";
import ModalTemplate from "components/templates/modal/ModalTemplate";
import ModalHeader from "components/blocks/modalheader/ModalHeader";
import Spinner from "components/atoms/loader/Spinner";
import { menuNavigation } from "utils/MenuUtil";

type Props = {
  onChangeSwitch: () => void;
  isForceSwitch?: boolean;
};

export type SwitchAccount = {
  level: string;
  accounts: Account[];
};

export default function SwitchAccount(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const preventGoBack = () => {
    window.history.pushState(null, "", "");
  };
  useEffect(() => {
    window.history.pushState(null, "", "");
    window.history.replaceState(null, "", window.location.href);
    window.addEventListener("popstate", preventGoBack);
    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, [navigate]);

  const onMoveAccountPage = () => {
    dispatch(setMenu("Organization"));
    navigate("/account", {
      replace: true,
    });
  };

  const onChangeSwitch = () => {
    if (props.isForceSwitch) {
      return;
    }
    props.onChangeSwitch();
  };

  const onClickAccount = (account: Account) => {
    // TODO Breadcrumb선택 시 해당 Account로 이동하는 함수 구현필요.

    // const selectAccount = auth.user?.accounts.find((account, index, array) => {
    //   return account.accountId === account.accountId;
    // });
    // console.log(account);

    getSwitchAccountInfo(account.accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        if (selectAccount.userPermission === undefined) {
          notify(
            "warning",
            intl.formatMessage({
              id: "validateMsg.account.permissionsDeny",
              defaultMessage:
                "The organization needs permissions. Please contact your administrator",
            })
          );
          return;
        }
        dispatch(setAccountSetting(selectAccount));
        if (account.level === "EU") {
          if (
            account.parentAccount !== undefined &&
            account.parentAccount.length > 0
          ) {
            const bcArray = account.parentAccount;
            const setBreadCrumb = bcArray.reverse().map((link: any) => {
              return {
                name: link.accountName,
                routeName: "/account",
                accountId: link.accountId,
              };
            });
            setBreadCrumb.push({
              name: selectAccount.accountName,
              routeName: "/recorder/list",
              accountId: selectAccount.accountId,
            });
            dispatch(setBreadcrumbRoute(setBreadCrumb));
          } else {
            dispatch(
              setBreadcrumbRoute([
                {
                  name: selectAccount.accountName,
                  routeName: "/recorder/list",
                  accountId: selectAccount.accountId,
                },
              ])
            );
          }
          dispatch(setMenu("Recorders"));
          menuNavigation(selectAccount, navigate, "/recorders/list");
          // navigate(`/recorders/list/${selectAccount.accountId}`, {
          //   replace: true,
          // });
        } else {
          // if (
          //   account.parentAccount !== undefined &&
          //   account.parentAccount.length > 0
          // ) {
          //   const bcArray = account.parentAccount;
          //   const setBreadCrumb = bcArray.reverse().map((link: any) => {
          //     return {
          //       name: link.accountName,
          //       routeName: "/account",
          //       accountId: link.accountId,
          //     };
          //   });
          //   setBreadCrumb.push({
          //     name: selectAccount.accountName,
          //     routeName: "/account",
          //     accountId: selectAccount.accountId,
          //   });
          //   dispatch(setBreadcrumbRoute(setBreadCrumb));
          // } else {
          //   dispatch(
          //     setBreadcrumbRoute([
          //       {
          //         name: selectAccount.accountName,
          //         routeName: "/account",
          //         accountId: selectAccount.accountId,
          //       },
          //     ])
          //   );
          // }
          //onMoveAccountPage();
          menuNavigation(selectAccount, navigate, "/account");
          // navigate(`/account/${selectAccount.accountId}`, {
          //   replace: true,
          // });
        }
      }
    });
    onChangeSwitch();
  };
  const [accountQueryInfo, setAccountQueryInfo] = useState<GetAccountListQuery>(
    {
      pageNum: 0,
      pageLimit: 10000,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_TYPE.ASC,
    }
  );

  const [accounts, setAccounts] = useState<SwitchAccount[]>([]);
  const getAccountSwitch = useQuery(
    ["accountSwitch", accountQueryInfo, selectedAccount],
    () =>
      getSwitchAccountList({
        payload: accountQueryInfo
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res);
        if (res.result === undefined) {
          setAccounts([]);
        } else {
          let tempDW: Account[] = [];
          let tempEU: Account[] = [];
          let tempCP: Account[] = [];
          let tempSubCP: Account[] = [];

          res.result.map((acc: Account) => {
            if (acc.level === "EU") {
              tempEU.push(acc);
            }
            if (acc.level === "CP") {
              if (acc.parentLevel === "CP") {
                tempSubCP.push(acc);
              } else {
                tempCP.push(acc);
              }
            }
            if (acc.level === "DW") {
              tempDW.push(acc);
            }
          });
          let tempArr: SwitchAccount[] = [
            {
              level: "DW",
              accounts: tempDW,
            },

            {
              level: "CP",
              accounts: tempCP,
            },
            {
              level: "SUB_CP",
              accounts: tempSubCP,
            },
            {
              level: "EU",
              accounts: tempEU,
            },
          ];
          setAccounts(tempArr);
        }
      },
      onError: (e: any) => {
        setAccounts([]);
      },
    }
  );

  const onClickSearch = (value: string) => {
    setAccountQueryInfo({ ...accountQueryInfo, keyword: value });
  };

  return (
    <ModalTemplate>
      {getAccountSwitch.isFetching ? (
        <s.SpinnerContainer>
          <Spinner />
        </s.SpinnerContainer>
      ) : (
        <s.SwitchInner>
          <ModalHeader
            title={"Switch Organization"}
            onModal={onChangeSwitch}
            disableClose={props.isForceSwitch}
          />
          <div className="inner">
            <SearchInput
              placeholder="Search Organization"
              onClickSearch={onClickSearch}
            />

            <s.GroupWrapper>
              {accounts.map((account: SwitchAccount, idx: number) => {
                if (account.accounts.length > 0) {
                  return (
                    <SwitchAccountList
                      key={idx}
                      account={account}
                      onClickAccount={onClickAccount}
                    />
                  );
                }
              })}
            </s.GroupWrapper>
          </div>
        </s.SwitchInner>
      )}
    </ModalTemplate>
  );
}
