import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import Selects from "components/atoms/select/Select";
import BigButton from "components/atoms/buttons/BigButton";
import Toggle from "components/atoms/toggle/Toggle";
import * as option from "utils/options";
import { Account } from "api/mappers/accountMapper";
import { EditCompanyLogo } from "../AccountStyled";
import Deactivate from "pages/users/add/deactivate";
import TextArea from "components/atoms/input/TextArea";
import { HiUpload } from "react-icons/hi";
import TinyButton from "components/atoms/buttons/TinyButton";
import myDWLogo from "assets/icons/myDW.svg";
import Spinner from "components/atoms/loader/Spinner";

export enum UPDATE_TYPE {
  NAME,
  LEVEL,
  TIMEZONE,
  DATE_FORMAT,
  TIME_FORMAT,
  ADDITIONAL_ACCOUNT_NUMBER,
  PRICING,
  TOW_FACTOR_AUTH,
  DESC,
}
interface Props {
  isToggled: boolean;
  onChange: () => void;
  onModal: () => void;
  onClickDelete: (result: boolean) => void;
  onClickSave: () => void;
  accountInfo: Account;
  onChangeAccountInfo: (
    type: UPDATE_TYPE,
    value: number | string | boolean
  ) => void;
  isDeleteConfirm: boolean;
  user: string;
  onClickDeleteConfirm: () => void;
  logoHandleChange: (e: any) => void;
  logoImage: string;
  isUpdateLoading: boolean;
}
export function EditModalPresenter(props: Props): JSX.Element {
  const {
    onClickDelete,
    onClickSave,
    accountInfo,
    onChangeAccountInfo,
    onClickDeleteConfirm,
    logoHandleChange,
    logoImage,
    isUpdateLoading,
  } = props;
  return (
    <BigPopupTemplate title="Edit Organization" onModal={props.onModal}>
      <s.EditWrapper>
        {isUpdateLoading && (
          <s.LoadingWrapper>
            <Spinner />
          </s.LoadingWrapper>
        )}
        {/* <s.EditLeftWrapper>
          <ModalInput label="Company Logo">
            <EditCompanyLogo>
              <img src={MYDW_LOGO} alt="company logo" />
            </EditCompanyLogo>
          </ModalInput>
          <s.ToggleContainer>
            Two-Factor Authentication (2FA)
            <Toggle
              id="dw_account_input_2fa"
              isToggled={props.isToggled}
              onChange={(checked: boolean) =>
                onChangeAccountInfo(UPDATE_TYPE.TOW_FACTOR_AUTH, checked)
              }
            />
          </s.ToggleContainer>
        </s.EditLeftWrapper> */}
        <s.AddWrapper>
          <s.Row>
            <ModalInput label="Name" essential flex={3}>
              <Input
                id="dw_account_input_name"
                placeholder="Enter a name for this organization"
                value={accountInfo.name}
                maxLength={100}
                onChange={(e) =>
                  onChangeAccountInfo(UPDATE_TYPE.NAME, e.target.value)
                }
              />
            </ModalInput>
            <ModalInput label="Level" essential flex={1}>
              <Selects
                id="dw_account_input_level"
                isDisabled={true}
                options={option.accountLevelOptions}
                value={option.accountLevelOptions.filter(function (option) {
                  return option.value === accountInfo.level;
                })}
                onChange={(value) =>
                  onChangeAccountInfo(UPDATE_TYPE.LEVEL, value)
                }
              />
            </ModalInput>
          </s.Row>
          <ModalInput label="Timezone">
            <Selects
              id="dw_account_input_timezone"
              value={option.timezoneOptions.filter(function (option) {
                return option.value === accountInfo.timezone;
              })}
              options={option.timezoneOptions}
              onChange={(value) =>
                onChangeAccountInfo(UPDATE_TYPE.TIMEZONE, value)
              }
            />
          </ModalInput>
          <s.Row>
            <ModalInput label="Date Format">
              <Selects
                id="dw_account_input_dataformat"
                value={option.dateFormatOptions.filter(function (option) {
                  return option.value === accountInfo.dateFormat;
                })}
                options={option.dateFormatOptions}
                onChange={(value) =>
                  onChangeAccountInfo(UPDATE_TYPE.DATE_FORMAT, value)
                }
              />
            </ModalInput>
            <ModalInput label="Time Format">
              <Selects
                id="dw_account_input_timeformat"
                value={option.timeFormatOptions.filter(function (option) {
                  return option.value === accountInfo.timeFormat;
                })}
                options={option.timeFormatOptions}
                onChange={(value) =>
                  onChangeAccountInfo(UPDATE_TYPE.TIME_FORMAT, value)
                }
              />
            </ModalInput>
          </s.Row>
          <s.EditAccountCompany>
            <div>
              <ModalInput label="Organization ID">
                <Input
                  id="dw_account_input_accountNumber"
                  disabled
                  placeholder=""
                  value={accountInfo.accountNumber}
                />
              </ModalInput>
              <ModalInput label="DW Community ID Number">
                <Input
                  id="dw_account_input_accountNumberAdditional"
                  placeholder="Enter community ID number"
                  value={accountInfo.accountAdditionalNumber}
                  maxLength={100}
                  onChange={(e) =>
                    onChangeAccountInfo(
                      UPDATE_TYPE.ADDITIONAL_ACCOUNT_NUMBER,
                      e.target.value
                    )
                  }
                />
              </ModalInput>
              <s.ToggleContainer>
                Two-Factor Authentication (2FA)
                <Toggle
                  id="dw_account_input_2fa"
                  isToggled={accountInfo.use2faAuth}
                  onChange={(checked: boolean) =>
                    onChangeAccountInfo(UPDATE_TYPE.TOW_FACTOR_AUTH, checked)
                  }
                />
              </s.ToggleContainer>
            </div>
            <s.EditLeftWrapper>
              <ModalInput label="Company Logo">
                <EditCompanyLogo className="company-logo">
                  <TinyButton
                    label="Change"
                    buttonType="cancel"
                    onClickBtn={() =>
                      document.getElementById("logoUPload")?.click()
                    }
                    icon={<HiUpload />}
                  />
                  <input
                    type="file"
                    id="logoUPload"
                    accept="image/png"
                    style={{ display: "none" }}
                    onChange={logoHandleChange}
                  />
                  {logoImage !== "" && logoImage !== undefined && (
                    <img
                      src={
                        logoImage === "" || logoImage === undefined
                          ? myDWLogo
                          : logoImage
                      }
                      alt="company logo"
                    />
                  )}
                </EditCompanyLogo>
              </ModalInput>
            </s.EditLeftWrapper>
          </s.EditAccountCompany>

          <ModalInput label="Description">
            <TextArea
              placeholder="Description"
              value={accountInfo.description}
              maxLength={200}
              onChange={(e) =>
                onChangeAccountInfo(UPDATE_TYPE.DESC, e.target.value)
              }
            />
          </ModalInput>
          {/* 
            Cp Level Only
            props 로 level 받아와 cp 인 경우 활성화
          
          */}
          {/* {level === "cp" && ( */}
          {/* <s.Row>
            <ModalInput label="Pricing (CP Level Only)" half>
              <Selects
                id="dw_account_input_price"
                value={option.pricingOptions[1]}
                options={option.pricingOptions}
                isDisabled
                onChange={(value) =>
                  onChangeAccountInfo(UPDATE_TYPE.PRICING, value)
                }
              />
            </ModalInput>
          </s.Row> */}
          {/* )} */}
        </s.AddWrapper>
      </s.EditWrapper>

      <s.ButtonWrapper>
        <BigButton
          id="dw_account_delete"
          buttonType="warning"
          label="Delete"
          onClickBtn={onClickDeleteConfirm}
        />
        <BigButton
          id="dw_account_button__save"
          label="Save"
          delay
          onClickBtn={onClickSave}
        />
      </s.ButtonWrapper>
      {props.isDeleteConfirm && (
        <Deactivate
          onModal={onClickDelete}
          type="organization"
          targetId={props.accountInfo.name}
        />
      )}
    </BigPopupTemplate>
  );
}
