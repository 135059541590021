import BigButton from "components/atoms/buttons/BigButton";
import Input from "components/atoms/input/Input";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Selects from "components/atoms/select/Select";
import { Text } from "components/atoms/text/Text";
import Toggle from "components/atoms/toggle/Toggle";
import * as s from "../myProfile/MyProfileStyled";
import * as mediaQuery from "components/MediaQuery";
import { Account } from "api/mappers/accountMapper";
import * as option from "utils/options";
import { EditCompanyLogo } from "pages/account/AccountStyled";
import TextArea from "components/atoms/input/TextArea";
import * as cs from "styles/ModalCommonStyled";
import Deactivate from "pages/users/add/deactivate";
import { HiUpload } from "react-icons/hi";
import TinyButton from "components/atoms/buttons/TinyButton";
import AddressInput from "components/atoms/input/address/AddressInput";
import myDWLogo from "assets/icons/myDW.svg";
import { LEVEL_TYPE } from "api/interfaces/accountInterface.interface";
import Spinner from "components/atoms/loader/Spinner";
import SwitchAccount from "pages/account/switch/SwitchAccount";
import { UserInfo } from "api/interfaces/userInterface.interface";

export enum UPDATE_TYPE {
  NAME,
  LEVEL,
  TIMEZONE,
  DATE_FORMAT,
  TIME_FORMAT,
  ADDITIONAL_ACCOUNT_NUMBER,
  PRICING,
  TOW_FACTOR_AUTH,
  DESC,
  COMPANY_NAME,
  COMPANY_LOCATION,
}
interface Props {
  isToggled: boolean;
  onChange: () => void;
  onClickDelete: (result: boolean) => void;
  onClickSave: () => void;
  accountInfo: Account;
  onChangeAccountInfo: (
    type: UPDATE_TYPE,
    value: number | string | boolean
  ) => void;
  isDeleteConfirm: boolean;
  user: string;
  onClickDeleteConfirm: () => void;
  logoHandleChange: (e: any) => void;
  logoImage: string;
  isImageLoading: boolean;
  isImageError: boolean;
  isSwitchModal: boolean;
  onChangeSwitch?: () => void;
  userInfo: UserInfo
}

export function MyAccountPresenter({
  onClickDelete,
  onClickSave,
  accountInfo,
  onChangeAccountInfo,
  onClickDeleteConfirm,
  logoHandleChange,
  logoImage,
  isDeleteConfirm,
  isImageLoading,
  isImageError,
  isSwitchModal,
  onChangeSwitch,
  userInfo
}: Props): JSX.Element {
  return (
    <>
      <s.Container>
        <mediaQuery.Default>
          <s.Title>
            <Text fontSize={24} bold>
              Organization
            </Text>
          </s.Title>
        </mediaQuery.Default>
        <s.Wrapper>
          <s.Item>
            <s.RowInput className="input-item">
              <ModalInput label="Name" essential noBold>
                <Input
                  id="dw_account_input_name"
                  placeholder="Enter a name for this organization"
                  value={accountInfo.name}
                  maxLength={100}
                  onChange={(e) =>
                    onChangeAccountInfo(UPDATE_TYPE.NAME, e.target.value)
                  }
                />
              </ModalInput>
              <ModalInput label="Timezone" noBold>
                <Selects
                  id="dw_account_input_timezone"
                  value={option.timezoneOptions.filter(function (option) {
                    return option.value === accountInfo.timezone;
                  })}
                  options={option.timezoneOptions}
                  onChange={(value) =>
                    onChangeAccountInfo(UPDATE_TYPE.TIMEZONE, value)
                  }
                />
              </ModalInput>
            </s.RowInput>
            <s.RowInput className="input-item">
              <ModalInput label="Date Format" noBold>
                <Selects
                  id="dw_account_input_dataformat"
                  value={option.dateFormatOptions.filter(function (option) {
                    return option.value === accountInfo.dateFormat;
                  })}
                  options={option.dateFormatOptions}
                  onChange={(value) =>
                    onChangeAccountInfo(UPDATE_TYPE.DATE_FORMAT, value)
                  }
                />
              </ModalInput>
              <ModalInput label="Time Format" noBold>
                <Selects
                  id="dw_account_input_timeformat"
                  value={option.timeFormatOptions.filter(function (option) {
                    return option.value === accountInfo.timeFormat;
                  })}
                  options={option.timeFormatOptions}
                  onChange={(value) =>
                    onChangeAccountInfo(UPDATE_TYPE.TIME_FORMAT, value)
                  }
                />
              </ModalInput>
            </s.RowInput>
            <s.RowInput>
              <ModalInput label="Company Name" noBold>
                <Input
                  id="dw_account_input_companyName"
                  placeholder="Enter Company Name"
                  value={accountInfo.companyName}
                  maxLength={100}
                  onChange={(e) =>
                    onChangeAccountInfo(
                      UPDATE_TYPE.COMPANY_NAME,
                      e.target.value
                    )
                  }
                />
              </ModalInput>
              <ModalInput label="Company Address" noBold>
                <AddressInput
                  isLoaded={true}
                  value={accountInfo.companyLocation}
                  onChange={(e) =>
                    onChangeAccountInfo(
                      UPDATE_TYPE.COMPANY_LOCATION,
                      e.currentTarget.value
                    )
                  }
                  onChangeLocationSelect={(
                    address: google.maps.LatLngLiteral,
                    addressString?: string
                  ) => {
                    if (addressString !== undefined) {
                      onChangeAccountInfo(
                        UPDATE_TYPE.COMPANY_LOCATION,
                        addressString
                      );
                    }
                  }}
                />
                {/* <Input
                  id="dw_account_input_companyLocation"
                  placeholder="Enter Company Location"
                  value={accountInfo.companyLocation}
                  onChange={(e) =>
                    onChangeAccountInfo(
                      UPDATE_TYPE.COMPANY_LOCATION,
                      e.target.value
                    )
                  }
                /> */}
              </ModalInput>
            </s.RowInput>
            <cs.EditAccountCompany className="input-item">
              {/* <cs.EditAccountCompany> */}
              <div className="account-block">
                <ModalInput label="Organization ID" noBold>
                  {" "}
                  <Input
                    id="dw_account_input_accountNumber"
                    disabled
                    placeholder=""
                    value={accountInfo.accountNumber}
                  />
                </ModalInput>
                <ModalInput label="DW Community ID Number" noBold>
                  <Input
                    id="dw_account_input_accountNumberAdditional"
                    placeholder="Enter community ID number"
                    value={accountInfo.accountAdditionalNumber}
                    onChange={(e) =>
                      onChangeAccountInfo(
                        UPDATE_TYPE.ADDITIONAL_ACCOUNT_NUMBER,
                        e.target.value
                      )
                    }
                  />
                </ModalInput>
                <cs.ToggleContainer>
                  Two-Factor Authentication (2FA)
                  <Toggle
                    id="dw_account_input_2fa"
                    isToggled={accountInfo.use2faAuth}
                    onChange={(checked: boolean) =>
                      onChangeAccountInfo(UPDATE_TYPE.TOW_FACTOR_AUTH, checked)
                    }
                  />
                </cs.ToggleContainer>
              </div>

              <cs.EditLeftWrapper className="account-block">
                <ModalInput
                  label="Company Logo"
                  noBold
                  isValidated={isImageError}
                  validatedWord={"Company Logo Image upload failed."}
                >
                  <EditCompanyLogo className="company-logo">
                    {isImageLoading && (
                      <div className="image-spinner">
                        <Spinner />
                      </div>
                    )}
                    <TinyButton
                      label="Change"
                      buttonType="cancel"
                      onClickBtn={() =>
                        document.getElementById("logoUPload")?.click()
                      }
                      icon={<HiUpload />}
                    />
                    <input
                      type="file"
                      id="logoUPload"
                      accept="image/png, image/jpeg, image/jpg"
                      style={{ display: "none" }}
                      onChange={logoHandleChange}
                    />
                    {/* {logoImage !== "" && logoImage !== undefined && ( */}
                    <img
                      src={
                        logoImage === "" || logoImage === undefined
                          ? myDWLogo
                          : logoImage
                      }
                      alt="company logo"
                      className="company-log-img"
                    />
                    {/* )} */}
                  </EditCompanyLogo>
                </ModalInput>
              </cs.EditLeftWrapper>
              {/* </cs.EditAccountCompany> */}
            </cs.EditAccountCompany>
            <s.RowInput className="input-item">
              <ModalInput label="Description" noBold>
                <TextArea
                  placeholder="Description"
                  value={accountInfo.description}
                  onChange={(e) =>
                    onChangeAccountInfo(UPDATE_TYPE.DESC, e.target.value)
                  }
                />
              </ModalInput>
            </s.RowInput>
            <s.RowInput>
              <s.RowInput></s.RowInput>
              <s.ButtonWrapper>
                {accountInfo.level !== LEVEL_TYPE.DW && (
                  <ModalInput label="">
                    <BigButton
                      id="dw_account_delete"
                      buttonType="warning"
                      label="Delete"
                      onClickBtn={onClickDeleteConfirm}
                    />
                  </ModalInput>
                )}
                <ModalInput label="">
                  <BigButton
                    id="dw_account_button__save"
                    label="Save"
                    onClickBtn={onClickSave}
                  />
                </ModalInput>
              </s.ButtonWrapper>
            </s.RowInput>
          </s.Item>
        </s.Wrapper>
      </s.Container>

      {isDeleteConfirm && (
        <Deactivate
          onModal={onClickDelete}
          type="organization"
          targetId={accountInfo.name}
          isDeactivateMode={userInfo.totalAccount > 1 ? false : true}
        />
      )}
      {isSwitchModal && (
        <SwitchAccount
          isForceSwitch={true}
          onChangeSwitch={() => {
            if (onChangeSwitch) {
              onChangeSwitch();
            }
          }}
        />
      )}
    </>
  );
}
