import styled from "styled-components";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { calcRem } from "styles/theme";
import TextArea from "components/atoms/input/TextArea";
import EmailAdd from "components/atoms/select/email";
import { useCallback, useState } from "react";
import { MultiValue } from "react-select";
import { useMutation } from "react-query";
import { postAlertSendEmail } from "api/alertAPI";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import SmallPopupTemplate from "../SmallPopupTemplate";
import { CustomError } from "api/interfaces/commonInterface.interface";
import useApiError from "hook/useApiError";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { isEmpty } from "lodash";

export type Props = {
  value?: string;
  recorderId: string;
  onModal: () => void;
};

export default function SendOneEmailPopup({
  onModal,
  value,
  recorderId,
}: Props): JSX.Element {
  console.log(value, recorderId, "vuae");
  const intl = useIntl();
  const { handleError } = useApiError();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [currentData, setCurrentData] = useState<MultiValue<any>>(
    value ? [{ label: value, value: value }] : []
  );
  const [message, setMessage] = useState<string>("");

  const onAddEmail = (value: MultiValue<any>) => {
    // console.log(value, "value");
    setCurrentData(value);
  };

  const mutationSendAlertEmail = useMutation(postAlertSendEmail, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.common.notify.email.success",
          defaultMessage: "Email sent successfully",
        })
      );
      onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.common.notify.email.fail",
          defaultMessage: "Email sent failed",
        })
      );
    },
  });

  const onClickSend = useCallback(() => {
    if (isEmpty(currentData[0].value)) {
      return;
    }

    mutationSendAlertEmail.mutate({
      accountId: selectedAccount.accountId,
      recorderId: recorderId,
      payload: {
        email: currentData[0].value as string,
        message: message,
      },
    });
  }, [value, selectedAccount.accountId, currentData, recorderId, message]);

  return (
    <SmallPopupTemplate
      cancel
      title="Send Report Email"
      subTitle="Email this Report to one or more participants"
      onModal={onModal}
      onClick={onClickSend}
      confirmLabel="Send"
    >
      <EmailSelectWrapper>
        <EmailAdd
          value={currentData}
          onChange={onAddEmail}
          placeholder=""
          isDisabled={true}
        />
      </EmailSelectWrapper>

      <OptionTitle>Message (Optional)</OptionTitle>
      <TextArea
        placeholder="Enter your message here."
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
    </SmallPopupTemplate>
  );
}

const OptionTitle = styled.div`
  font-size: ${calcRem(18)};
  padding-bottom: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  margin-top: 12px;
`;

const EmailSelectWrapper = styled.div`
  padding-bottom: 20px;
  max-width: 100%;
`;
