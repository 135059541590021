import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import BigButton from "components/atoms/buttons/BigButton";
import * as s from "./ViewSupportStyled";
import { CenterlineText, UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { useIntl } from "react-intl";
import ModalSelects from "components/atoms/select/modal/ModalSelect";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import { iAmAOptions, splitFullName, techStatusOptions } from "utils/options";
import TextArea from "components/atoms/input/TextArea";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import { TechSupportDto } from "api/interfaces/managerInterface.interface";
import Link from "antd/es/typography/Link";
import { useAppSelector } from "redux/hooks";
import NormalConfirmModal from "components/templates/modal/small/confirm/normal";
import { notify } from "components/atoms/notification/Notification";
import { updateTechSupport } from "api/managerAPI";
import FileSaver from "file-saver";
import { EditorContainer } from "components/atoms/input/editor/EmailEditor";
import { downloadFromUrl } from "utils/functions";
import { edit } from "react-arborist/dist/module/state/edit-slice";

interface Props {
  isEdit?: boolean; // 편집 모드일 때
  onModal: (type?: string) => void;
  selectedData: TechSupportDto;
  onClickSave: (videoDto: any, update?: boolean) => void;
  onClickDeactive?: (userId: string) => void;
}

export enum UPDATE_TYPE {
  CATEGORY,
  GROUP,
  TITLE,
  VIDEO_URL,
  ORDER,
  DESC,
}

function ViewSupport(props: Props): JSX.Element {
  const intl = useIntl();
  const theme = useAppSelector((state) => state.theme.theme);

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [editInfo, setEditInfo] = useState<TechSupportDto>(props.selectedData);

  const contactInfo = [
    {
      label: "Email",
      placeholder: "Enter email address",
      value: props.selectedData?.email,
    },
    {
      label: "I Am A(n)",
      input: (
        <ModalSelects
          options={iAmAOptions}
          isDisabled
          value={iAmAOptions.filter(
            (option) => option.value === editInfo.userType
          )}
        />
      ),
    },
    {
      label: "First Name",
      placeholder: "first name",
      value: splitFullName(editInfo.name),
    },
    {
      label: "Last Name",
      placeholder: "Last Name",
      value: splitFullName(editInfo.name, true),
    },
    {
      label: "Phone Number",
      input: <PhoneInput disabled={true} selected={editInfo.phone} />,
    },
  ];

  const issueContactInfo = [
    {
      label: "Email",
      value: props.selectedData?.email,
    },
    {
      label: "Organization",
      value: props.selectedData?.accountNumber,
    },
    {
      label: "Name",
      value: editInfo.name,
    },
    {
      label: "Phone Number",
      input: <PhoneInput disabled={true} selected={editInfo.phone} />,
    },
  ];

  const onChangeProcess = useCallback(
    (treatment: string) => {
      setEditInfo({
        ...editInfo,
        treatment: treatment,
      });
    },
    [editInfo]
  );

  const productInfo = [
    {
      label: "Address",
      input: <Input disabled value={editInfo.address} />,
    },
    {
      label: "Problem Description",
      input: (
        <TextArea
          placeholder="Tell us what's wrong"
          disabled
          value={editInfo.description}
        />
      ),
    },
    {
      label: "",
      input: (
        <TextArea
          placeholder="Enter Process Result"
          value={editInfo.treatment}         
          onChange={(e) => onChangeProcess(e.currentTarget.value)}
        />
      ),
    },
  ];

  const onClickDelete = useCallback(() => {}, []);

  const mutationUpdateTech = useMutation(updateTechSupport, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.manager.tech.modify.success",
          defaultMessage: "The tech report updated successfully.",
        })
      );
      props.onModal();
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.manager.tech.modify.fail",
          defaultMessage: "Fail to modify tech report.",
        })
      );
    },
  });
  const onSaveBefore = useCallback(() => {
    const formData = new FormData();
    const json = JSON.stringify(editInfo);
    const blob = new Blob([json], { type: "application/json" });
    formData.append("techSupportDto", blob);
    mutationUpdateTech.mutate({
      id: editInfo.id,
      payload : formData
    })
  }, [editInfo, mutationUpdateTech]);

  const onClickAttachDownload = useCallback((editInfo: TechSupportDto) => {
    downloadFromUrl(editInfo.fileUrl, editInfo.fileName); 
  }, []);

  const onChangeStatus = useCallback((status:string)=>{
    setEditInfo({
      ...editInfo,
      status: status,
    });
  },[editInfo]);

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit Tech Support" : "Add Tech Support"}
      onModal={props.onModal}
    >
      <s.FormItem>
        <s.SubTitle>
          <CenterlineText color={COLORS.BUTTONS1} fontSize={20} bold>
            Your Contact Information
          </CenterlineText>
        </s.SubTitle>

        <s.FormWrapper>
          {editInfo.category === "issueReport"
            ? issueContactInfo.map((item, index) => (
                <ModalInput label={item.label} key={index}>
                  {item.input ? (
                    item.input
                  ) : (
                    <Input disabled value={item.value} />
                  )}
                </ModalInput>
              ))
            : contactInfo.map((item, index) => (
                <ModalInput label={item.label} key={index}>
                  {item.input ? (
                    item.input
                  ) : (
                    <Input disabled value={item.value} />
                  )}
                </ModalInput>
              ))}
        </s.FormWrapper>
        <ModalInput
          label={productInfo[0].label}
          key={"address_problem_description"}
        >
          {productInfo[0].input}
        </ModalInput>
        <ModalInput
          label={productInfo[1].label}
          key={"textarea_problem_description"}
        >
          {productInfo[1].input}
        </ModalInput>
        {editInfo.category === "issueReport" && editInfo.fileName !== "" && (
          <ModalInput label={"Attachment"} key={"attach_problem_description"}>
            <UnderlineText
              color={theme === "light" ? COLORS.LIGHTGRAY : COLORS.SELECTED}
              fontSize={14}
              onClick={() => onClickAttachDownload(editInfo)}
              className="web-app-link"
            >
              {props.selectedData.fileName}
            </UnderlineText>
          </ModalInput>
        )}
         {editInfo.category === "techSupport" && editInfo.fileName !== "" && (
          <ModalInput label={"Attachment"} key={"attach_problem_description"}>
            <UnderlineText
              color={theme === "light" ? COLORS.LIGHTGRAY : COLORS.SELECTED}
              fontSize={14}
              onClick={() => onClickAttachDownload(editInfo)}
              className="web-app-link"
            >
              {props.selectedData.fileName}
            </UnderlineText>
          </ModalInput>
        )}
        <s.SubTitle2>
          <CenterlineText color={COLORS.BUTTONS1} fontSize={20} bold>
            Process Result
          </CenterlineText>
          <div style={{width:"40%"}}>
          <ModalSelects            
            options={techStatusOptions}
            value={techStatusOptions.filter(
              (option) => option.value === editInfo.status
            )}
            onChange={onChangeStatus}
          />
          </div>
        </s.SubTitle2>
        <ModalInput
          label={productInfo[2].label}
          key={"textarea_process_result"}
        >
          {productInfo[2].input}
        </ModalInput>
      </s.FormItem>

      <s.ButtonWrapper>
        {/* {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Close"
            onClickBtn={() => setIsDeleteConfirm(true)}
          />
        )} */}
        <BigButton label="Save" onClickBtn={onSaveBefore} />
      </s.ButtonWrapper>
      {props.isEdit && isDeleteConfirm && (
        <NormalConfirmModal
          label="techSupport"
          onConfirm={() => setIsDeleteConfirm(false)}
          onConfirmCancel={onClickDelete}
        />
      )}
    </BigPopupTemplate>
  );
}

export default ViewSupport;
