// import ARROWDOWN from "assets/icons/arrow_down.svg";
import Select, {
  StylesConfig,
  components,
  IndicatorsContainerProps,
} from "react-select";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { timeOptions } from "utils/options";
import { Option } from "./util";
import TIME_ICON from "assets/icons/time.svg";
import * as mediaQuery from "components/MediaQuery";

// type OptionType = {
//   options: Array<Option>;
//   isDisabled?: boolean;
// };

// type OptionType = { [string: string]: any; isDisabled?: boolean };
type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options?: OptionsType;
  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  value?: any;
  defaultValue?: any;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  id?: string;
  controlShouldRenderValue?: boolean;
};

const customStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    const backgroundColor = isDisabled ? COLORS.BUTTONS1 : COLORS.WHITE;
    const borderColor = "#cacaca";
    const minHeight = "unset";
    const height = "35px";
    const maxHeight = "35px";

    return {
      ...styles,
      backgroundColor,
      borderColor,
      minHeight,
      height,
      maxHeight,
    };
  },
  container: (styles) => {
    const width = "100%";
    const maxWidth = "100%";

    return { ...styles, width, maxWidth };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
};

const IndicatorsContainer = () => {
  return (
    <Indicator>
      <img src={TIME_ICON} alt="time icon" />
    </Indicator>
  );
};

const TimeSelect = (props: CommonProps) => {
  return (
    <Select
      {...props}
      closeMenuOnSelect={true}
      defaultValue={props.options ? props.options[0] : timeOptions[0]}
      options={props.options ? props.options : timeOptions}
      components={{ IndicatorsContainer }}
      styles={customStyles}
      placeholder={props.placeholder ? props.placeholder : "Select"}
      menuPortalTarget={document.querySelector("body")}
      onChange={({ value }) => {
        if (props.onChange) {
          props.onChange(value);
        }
      }}
    />
  );
};

const Indicator = styled.div`
  padding: 5px;
  background-color: ${COLORS.BUTTONS2};
  border-radius: 4px;
  margin: 2px;
  ${mediaQuery.isDefault} {
    height: max-content;

    img {
      width: 16px;
    }
  }

  ${mediaQuery.isMobile} {
    /* padding: 4px; */
    img {
      width: 14px;
    }
  }
`;

export default TimeSelect;
