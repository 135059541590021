import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  CustomError,
  REPORT_DETECT_TIME,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  HealthReport,
  NotifyUser,
  NotifyUserGroup,
} from "api/interfaces/reportInterface.interface";
import {
  deleteReport,
  postHealthReportCreate,
  putHealthReportCreate,
  putReportEnable,
} from "api/reportAPI";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import { NOTIFICATION_TYPE } from "../common/AddNotification";
import AddHealthPresenter from "./AddHealthPresenter";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import useApiError from "hook/useApiError";

export enum UPDATE_TYPE {
  AUTO_APPLY,
  RECORDER,
  RECORDER_GROUP,
  SEND_TIME,
  INTERVAL,
  WEEKLY_VALUE,
  MONTHLY_VALUE,
  IS_END_OF_MONTH,
  USER_EMAIL,
  USER_GROUP_ID,
  REPORT_NAME,
  DESC,
  USER,
  REPORT_ENABLE,
}

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  reportRow?: ReportDataRow;
  data?: HealthReport;
};

export default function AddHealthContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();
  
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const [isConfirm, SetIsConfirm] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [healthReport, setHealthReport] = useState<HealthReport>({
    recorder: {
      autoApply:
        props.isEdit && props.data !== undefined
          ? (props.data.recorder.autoApply as boolean)
          : false,
      systems:
        props.isEdit &&
        props.data !== undefined &&
        props.data.recorder.systems !== undefined
          ? props.data.recorder.systems
          : [],
      recorderGroupId:
        props.isEdit &&
        props.data !== undefined &&
        props.data.recorder.recorderGroupId !== undefined
          ? props.data.recorder.recorderGroupId
          : [],
    },
    notification: {
      dayTime:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.dayTime as string)
          : "09:00", // 하나있을때는 DAY만
      nightTime:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.nightTime as string)
          : "00:00",
      intervalType:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.intervalType as REPORT_DETECT_TIME)
          : REPORT_DETECT_TIME.WEEK, //Month, Week
      weeklyValue:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.weeklyValue as Array<boolean>)
          : Array(7).fill(true),
      monthlyValue:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.monthlyValue as number)
          : 1,
      isEndOfMonth:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.isEndOfMonth as boolean)
          : false,
      users:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.users as Array<NotifyUser>)
          : [],
      userGroups:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.userGroups as Array<NotifyUserGroup>)
          : [],
    },
    preferences: {
      host:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.host as string)
          : auth.user.name.concat(`(${auth.user.email})`),
      title:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.title as string)
          : "",
      description:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.description as string)
          : "",
      isEnable:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.isEnable as boolean)
          : true,
      userId :
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.userId as string)
          : undefined,
    },
  });

  useEffect(() => {
    if (props.data !== undefined) {
      setHealthReport({
        recorder: {
          autoApply:
            props.isEdit && props.data !== undefined
              ? (props.data.recorder.autoApply as boolean)
              : false,
          systems:
            props.isEdit &&
            props.data !== undefined &&
            props.data.recorder.systems !== undefined
              ? props.data.recorder.systems
              : [],
          recorderGroupId:
            props.isEdit &&
            props.data !== undefined &&
            props.data.recorder.recorderGroupId !== undefined
              ? props.data.recorder.recorderGroupId
              : [],
        },
        notification: {
          dayTime:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.dayTime as string)
              : "09:00", // 하나있을때는 DAY만
          nightTime:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.nightTime as string)
              : "00:00",
          intervalType:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.intervalType as REPORT_DETECT_TIME)
              : REPORT_DETECT_TIME.WEEK, //Month, Week
          weeklyValue:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.weeklyValue as Array<boolean>)
              : Array(7).fill(true),
          monthlyValue:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.monthlyValue as number)
              : 1,
          isEndOfMonth:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.isEndOfMonth as boolean)
              : false,
          users:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.users as Array<NotifyUser>)
              : [],
          userGroups:
            props.isEdit && props.data !== undefined
              ? (props.data.notification.userGroups as Array<NotifyUserGroup>)
              : [],
        },
        preferences: {
          host:
            props.isEdit && props.data !== undefined
              ? (props.data.preferences.host as string)
              : auth.user.name.concat(`(${auth.user.email})`),
          title:
            props.isEdit && props.data !== undefined
              ? (props.data.preferences.title as string)
              : "",
          description:
            props.isEdit && props.data !== undefined
              ? (props.data.preferences.description as string)
              : "",
          isEnable:
            props.isEdit && props.data !== undefined
              ? (props.data.preferences.isEnable as boolean)
              : true,
          userId :
            props.isEdit && props.data !== undefined
              ? (props.data.preferences.userId as string)
              : undefined,
        },
      });
    }
  }, [auth.user.email, auth.user.name, props.data, props.isEdit]);

  const steps = [
    {
      label: "Recorders",
    },
    {
      label: "Notification",
    },
    {
      label: "Preferences",
    },
  ];

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      console.log(name, index);
      setPage(index);
    }
  };

  const mutationCreateReport = useMutation(postHealthReportCreate, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.health.create.success",
        defaultMessage: "Health report created successfully.",
      }));
      props.onModal("add");
    },
    onError: (err:CustomError) => {
      handleError(err,  intl.formatMessage({
        id: "label.report.notify.health.create.fail",
        defaultMessage: "Failed to create Health report.",
      }));
    },
  });

  const mutationUpdateReport = useMutation(putHealthReportCreate, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.health.update.success",
        defaultMessage: "Health report updated successfully.",
      }));
      props.onModal("edit");
    },
    onError: (err:CustomError) => {
      handleError(err,  intl.formatMessage({
        id: "label.report.notify.health.update.fail",
        defaultMessage: "Failed to update Health report.",
      }));
    },
  });

  const [reportRuleValidate, setReportRuleValidate] = useState<Validate>({
    isRecorder: false,
    isRuleName: false,
  });
  const [reportRuleValidateMsg, setReportRuleValidateMsg] =
    useState<ValidateMsg>({
      isRecorder: "",
      isRuleName: "",
    });

  const initReportRuleValidate = useCallback((field: string) => {
    setReportRuleValidate((info) => {
      return { ...info, [field as keyof typeof reportRuleValidate]: false };
    });
    setReportRuleValidateMsg((info) => {
      return { ...info, [field as keyof typeof reportRuleValidateMsg]: "" };
    });
  }, []);

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);

    initReportRuleValidate("isRecorder");
    initReportRuleValidate("isRuleName");

    if (
      healthReport.recorder === undefined ||
      healthReport.recorder.autoApply === false
    ) {
      if (
        (healthReport.recorder.systems === undefined ||
          healthReport.recorder.systems.length === 0) &&
        (healthReport.recorder.recorderGroupId === undefined ||
          healthReport.recorder.recorderGroupId.length === 0)
      ) {
        setReportRuleValidate((info) => {
          return { ...info, isRecorder: true as boolean };
        });

        setReportRuleValidateMsg((info) => {
          return {
            ...info,
            isRecorder: intl.formatMessage({
              id: "validateMsg.report.rule.emptyRecorder",
              defaultMessage: "Select one or more recorders",
            }) as string,
          };
        });
        setPage(0);
        return;
      }
    }

    if (isEmpty(healthReport.preferences.title)) {
      setReportRuleValidate((info) => {
        return { ...info, isRuleName: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRuleName: intl.formatMessage({
            id: "validateMsg.report.rule.emptyRuleName",
            defaultMessage: "Please enter Report Rule Name",
          }) as string,
        };
      });
      setPage(2);
      return;
    }

    if (props.isEdit) {
      mutationUpdateReport.mutate({
        payload: healthReport,
        accountId: selectedAccount.accountId,
        reportId: props.reportRow?.id as string,
      });
    } else {
      mutationCreateReport.mutate({
        payload: healthReport,
        accountId: selectedAccount.accountId,
      });
    }
  }, [mutationCreateReport, selectedAccount.accountId]);

  const onChangeNotification = useCallback(
    (
      type: NOTIFICATION_TYPE,
      value: string | number | boolean | string[] | boolean[] | number[]
    ) => {
      if (type === NOTIFICATION_TYPE.SEND_TIME) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              dayTime: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.INTERVAL) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              intervalType: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.WEEKLY_VALUE) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              weeklyValue: value as boolean[],
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.MONTHLY_VALUE) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              monthlyValue: value as number,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.IS_END_OF_MONTH) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              isEndOfMonth: value as boolean,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              users: (value as string[]).map((data) => {
                return {
                  userId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUser>,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER_GROUP) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              userGroups: (value as string[]).map((data) => {
                return {
                  userGroupId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUserGroup>,
            },
          };
        });
      }
    },
    []
  );

  const onChangeHealthReportInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | boolean | string[] | boolean[] | number[]
    ) => {
      if (type === UPDATE_TYPE.AUTO_APPLY) {
        setHealthReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              autoApply: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER) {
        setHealthReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              systems: value as string[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUP) {
        setHealthReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              recorderGroupId: value as number[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.SEND_TIME) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              dayTime: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.INTERVAL) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              intervalType: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.WEEKLY_VALUE) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              weeklyValue: value as boolean[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.MONTHLY_VALUE) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              monthlyValue: value as number,
            },
          };
        });
      } else if (type === UPDATE_TYPE.IS_END_OF_MONTH) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              isEndOfMonth: value as boolean,
            },
          };
        });
        // } else if (type === UPDATE_TYPE.USER_EMAIL) {
        //   setHealthReport((info) => {
        //     return { ...info, notifyUserMail: value as string[] };
        //   });
      } else if (type === UPDATE_TYPE.REPORT_NAME) {
        setHealthReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              title: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setHealthReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              description: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_ENABLE) {
        setHealthReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              isEnable: value as boolean,
            },
          };
        });
      }
    },
    []
  );

  const mutationDeleteReport = useMutation(deleteReport, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.health.delete.success",
        defaultMessage: "Health report deleted successfully.",
      }));
      props.onModal("edit");
    },
    onError: (err:CustomError) => {
      handleError(err,  intl.formatMessage({
        id: "label.report.notify.health.delete.fail",
        defaultMessage: "Failed to delete Health report.",
      }));
    },
  });

  const onConfirmDelete = () => {
    mutationDeleteReport.mutate({
      reportId: props.reportRow?.id as string,
      accountId: selectedAccount.accountId,
    });
  };
  const setIsDeleteConfirm = (confirm: boolean) => {
    SetIsConfirm(confirm);
  };
  const onDelete = (value: boolean) => {
    SetIsConfirm(value);
  };

  const mutationEnableReport = useMutation(putReportEnable, {
    onSuccess: (res: any, variables: any) => {
      onChangeHealthReportInfo(
        UPDATE_TYPE.REPORT_ENABLE,
        variables.payload.enable
      );
      //notify("success", "Success : Success to Enable Health Report.");
    },
    onError: () => {
      //notify("error", "Error : Failed to delete Health Report.");
    },
  });

  const onChangeEnable = (value: boolean) => {
    mutationEnableReport.mutate({
      reportId: props.reportRow?.id as string,
      payload: {
        enable: value,
      },
    });
  };

  return (
    <AddHealthPresenter
      steps={steps}
      page={page}
      reportRow={props.reportRow}
      onChangePage={onChangePage}
      selectedAccount={selectedAccount}
      onChangeHealthReportInfo={onChangeHealthReportInfo}
      onChangeNotification={onChangeNotification}
      hostName={auth.user?.name + "(" + auth.user?.email + ")"}
      onClickCreate={onClickCreate}
      data={healthReport}
      isEdit={props.isEdit}
      setIsDeleteConfirm={setIsDeleteConfirm}
      isConfirm={isConfirm}
      onDelete={onDelete}
      onConfirmDelete={onConfirmDelete}
      onChangeEnable={onChangeEnable}
      reportRuleValidate={reportRuleValidate}
      reportRuleValidateMsg={reportRuleValidateMsg}
    />
  );
}
