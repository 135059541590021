import { useCallback, useEffect, useState } from "react";
import { SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import { NoticePresenter } from "./NoticePresenter";
import * as s from "./NoticeStyled";
import ToggleComponent from "components/atoms/toggle/Toggle";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getNotices, putDisplayNotice } from "api/noticeAPI";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { notify } from "components/atoms/notification/Notification";
import { useDispatch } from "react-redux";
import { setNotices } from "redux/reducers/notices/noticeReducer";
import { NoticeInfo } from "api/interfaces/noticeInterface.interface";
import { useIntl } from "react-intl";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";

export default function EmailContainer(): JSX.Element {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();
  const location:Location = useLocation();
  const navigate = useNavigate();
  
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const notices: NoticeInfo[] = useAppSelector((state) => state.notice.notices);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [pending, setPending] = useState<boolean>(true);

  const [selectedNotice, setSelectedNotice] = useState<
    NoticeInfo | undefined
  >();

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });

  const [paginationInfo, setPaginationInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "subject",
    sortDirection: SORT_DIRECTION.ASC,
  });
  const [noticeList, setNoticeList] = useState<NoticeInfo[]>([]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setPaginationInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "subject"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setPaginationInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "subject",
          sortDirection: SORT_DIRECTION.DESC,
        };
      });
    }
  },[location]);


  const { error, refetch } = useQuery(
    ["getNoticeInNoticePage", paginationInfo],
    () =>
      getNotices({
        payload: paginationInfo,
      }),

    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        console.log(res, "res Notice!!");
        if (res.error !== 0) {
          setNoticeList([]);
          setStatus((info) => {
            return {
              ...info,
              totalValue: 0,
            };
          });
          return;
        }
        setNoticeList(res.result);
        setStatus((info) => {
          return {
            ...info,
            totalValue: res.page.total,
          };
        });
      },
      onError: (e: any) => {
        setNoticeList([]);
        setStatus((info) => {
          return {
            ...info,
            totalValue: 0,
          };
        });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  //temp columns
  const columns: TableColumn<NoticeInfo>[] = [
    {
      name: "Title",
      sortable: true, // 정렬 활성화,
      sortField: "subject",
      selector: (row) => row.subject,
      grow: 1,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      grow: 3,
    },
    {
      name: "Color",
      cell: (row) => <s.Color color={row.color} />,
      maxWidth: "50px",
      center: true,
      grow: 1,
    },
    {
      name: "Display",
      maxWidth: "50px",
      cell: (row) => (
        <ToggleComponent
          isToggled={row.isDisplay}
          onChange={(checked: boolean) => {
            if (row.noticeId) {
              onUpdateDisplay(checked, row.noticeId);
            }
          }}
        />
      ),
      center: true,
      grow: 1,
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "160px",
      maxWidth: "160px",
      grow: 1,
      cell: (row) => (
        <s.ButtonWrapper>
          <TableButton
            label="Edit"
            onClickBtn={() => {
              onModal("edit");
              setSelectedNotice(row);
            }}
          />
          <span className="row-click-button">
            <TableButton label="Preview" onClickBtn={() => onRowClicked(row)} />
          </span>
        </s.ButtonWrapper>
      ),
      right: true,
    },
  ];

  const onRowClicked = useCallback((row: NoticeInfo) => {
    let tempArr = [...notices, { ...row, preview: true }];
    dispatch(setNotices(tempArr));
    // console.log(tempArr, notices);
    setTimeout(() => {
      dispatch(setNotices(notices));
    }, 3000);
  }, []);

  const onModal = (type?: string) => {
    if (type === "add") {
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      setIsEditModal(!isEditModal);
    }
  };

  const mutationUpdateDisplayNotice = useMutation(putDisplayNotice, {
    onSuccess: () => {
      // console.log("success");
      queryClient.invalidateQueries("getNoticeInNoticePage");
      setIsEditModal(false);
    },
    onError: () => {
      // console.log("fail");
      notify("error", intl.formatMessage({
        id: "label.setting.notice.notify.display.update.fail",
        defaultMessage: "Fail to update display of Notice.",
      }));
    },
  });

  const onUpdateDisplay = useCallback(
    (isDisplay: boolean, noticeId: string) => {
      mutationUpdateDisplayNotice.mutate({
        isDisplay: isDisplay,
        noticeId: noticeId,
        accountId: selectedAccount.accountId,
      });
    },
    [mutationUpdateDisplayNotice, noticeList, selectedAccount]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if(paginationInfo.keyword !== keyword){
        queryNavigate(navigate, location.pathname, {
          pageLimit: paginationInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: paginationInfo.sortType,
          sortDirection: paginationInfo.sortDirection as SORT_DIRECTION,
        }, selectedAccount);
      }else{
        refetch();
      }
      setPending(true);
      // setPaginationInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [location.pathname, navigate, paginationInfo.keyword, paginationInfo.pageLimit, paginationInfo.sortDirection, paginationInfo.sortType, refetch, selectedAccount]
  );

  const onChangePage = (page: number, totalRows: number) => {
    // console.log(page, totalRows);
    if (page !== paginationInfo.pageNum + 1) {
      queryNavigate(navigate, location.pathname, {
        pageLimit: paginationInfo.pageLimit,
        pageNum: page - 1,
        keyword: paginationInfo.keyword as string,
        sortType: paginationInfo.sortType,
        sortDirection: paginationInfo.sortDirection as SORT_DIRECTION,
      }, selectedAccount);
      setPending(true);
      // setPaginationInfo((info) => {
      //   return { ...info, pageNum: (page - 1) as number };
      // });
    }
  };

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setPaginationInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setPending(true);
    }
  };
  return (
    <NoticePresenter
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      columns={columns}
      onClickSearch={onClickSearch}
      onRowClicked={onRowClicked}
      data={noticeList}
      selectedNotice={selectedNotice}
      onChangePage={onChangePage}
      handleSort={handleSort}
      paginationInfo={paginationInfo}
      status={status}
      tablePending={pending}
    />
  );
}
