import { useRef, useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import DATE_ICON from "assets/icons/date.svg";
import {
  DateFromString,
  changeTimeStampFormatDaySelect,
} from "utils/functions";
import { FLEX } from "styles/flex";
import CalendarComponent from "../calendar/Calendar";
import { calcRem } from "styles/theme";
import { CalendarTileProperties } from "react-calendar";
import { changeTimeDaySelect, convertDateToString } from "utils/timeUtil";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type Props = {
  disabled?: boolean;
  value?: string;
  timezone?: string;
  placeholder?: string;
  right?: boolean;
  onChange?: (date: Date) => void;
  tileContent?:
    | string
    | JSX.Element
    | ((props: CalendarTileProperties) => JSX.Element | null)
    | undefined;
  tileDisabled?: ((props: CalendarTileProperties) => boolean) | undefined;
  markDays?: string[];
};

const DateSelect = ({
  value,
  disabled,
  timezone,
  right,
  onChange,
  tileContent,
  tileDisabled,
  markDays,
}: Props) => {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const calendarRef = useRef<any>(null);
  const [calendarDay, setCalendarDay] = useState(new Date());

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (null != value) {
      setCalendarDay(DateFromString(value));
    }
  }, [value]);

  useEffect(() => {
    const onClick = (event: any) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", onClick);
    document.body.addEventListener("touchend", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
      window.removeEventListener("touchend", onClick);
    };
  }, [calendarRef]);

  const onClickCalendar = (date: Date) => {
    setCalendarDay(date);
    setIsOpen(!isOpen);
    if (onChange !== undefined) {
      onChange(date);
    }
  };

  return (
    <Container ref={calendarRef}>
      <SelectWrapper
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
        className="select-wrapper"
      >
        <img className="calendar-icon" src={DATE_ICON} alt="calendar icon" />
        <span>{changeTimeDaySelect(calendarDay, selectedAccount)}</span>
        {/* <span>{getTodayFormat(timezone)}</span> */}
        {/* {value ? (
          <span>{datetoString(calendarDay)}</span>
        ) : (
          <span className={"placeholder"}>
            {placeholder ? placeholder : "Click here to select a date."}
          </span>
        )} */}
      </SelectWrapper>
      {isOpen && !disabled && (
        <CalendarContainer right={right}>
          <CalendarComponent
            onChange={onClickCalendar}
            value={calendarDay}
            tileDisabled={tileDisabled}
            tileContent={({ date, view }) => {
              if (
                markDays !== undefined &&
                markDays.find((x) => x === convertDateToString(date))
              ) {
                return (
                  <>
                    <DotWrapper>
                      <DotItem />
                    </DotWrapper>
                  </>
                );
              } else {
                return <Fragment />;
              }
            }}
          />
        </CalendarContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  /* max-width: 175px; */
`;

const SelectWrapper = styled.div<{ disabled?: boolean }>`
  background-color: ${COLORS.WHITE};
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 6px 14px;
  ${FLEX.FlexStartCenter};
  gap: 10px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
  `}

  max-width: 100%;
  cursor: pointer;

  .placeholder {
    color: #abb5c5;
  }

  .calendar-icon {
    height: 21px;
  }

  span {
    display: inline-block;
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${COLORS.BLACK} !important;
    font-size: ${calcRem(14)};
  }
`;

const CalendarContainer = styled.div<{ right?: boolean }>`
  z-index: 999;
  position: absolute;
  /* bottom: -295px; */
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  /* top: 45px; */
  color: ${COLORS.BLACK} !important;

  ${({ right }) =>
    right
      ? `
    right: 0;
  `
      : `
    left: 0;
  `}
`;

const DotWrapper = styled.div`
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DotItem = styled.div`
  height: 4px;
  width: 4px;
  background-color: #63a1ff;
  border-radius: 50%;
  display: flex;
  margin-left: 1px;
`;

export default DateSelect;
