import { useEffect, useMemo, useRef, useState } from "react";
import * as s from "../AlertViewStyled";

import {
  ActiveAlert,
  AlertList2,
  AlertTraceList,
  AlertType,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";

import "react-multi-carousel/lib/styles.css";

import { Option, alertStatusOptions } from "utils/options";
import AlertTypeSelect from "components/atoms/select/modal/AlertTypeSelect";
import CCTVRED from "assets/icons/cctv_red.svg";
import SYSTEMALERT from "assets/icons/system_alert.svg";
// import { ReactComponent as CCTVRED } from "assets/icons/cctv_red.svg";
// import { ReactComponent as SYSTEMALERT } from "assets/icons/system_alert.svg";
// import { ReactComponent as STORAGE } from "assets/icons/storage.svg";
import { useLocation } from "react-router-dom";

import { useIntl } from "react-intl";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import { useQueryClient } from "react-query";
import { cameraUniqueValue, filteringDiskAlertBoolean } from "utils/AlertUtil";
import { isCheckPermission } from "utils/AccountUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";

interface Props {
  alertType: AlertType;
  onChangeCurrentAlert: (alertType: AlertType, value?: string) => void;
  currentAlert: AlertList2;
  onChangeStatus: (value: string) => void;
}

export const splitCameraId = (cameraId: string) => {
  return cameraId.split(":")[1];
};

const AlertTypeItem = (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isMap = location.pathname.includes("map");
  const selectRef = useRef(null);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const alertListStyled = s.AlertTypeListStyled;

  // console.log(props.alertType, props.currentAlert);

  const [selectIds, setSelectIds] = useState({
    cameraId:
      props.alertType.type === "Video Loss"
        ? props.alertType.cameraList
          ? `${props.alertType.cameraList[0].cameraId}:${props.alertType.cameraList[0].channel}`
          : ""
        : "",
    storageName: filteringDiskAlertBoolean(props.alertType)
      ? props.alertType.storageList && props.alertType.storageList.length > 0
        ? props.alertType.storageList[0].value
        : ""
      : "",
  });

  const onChangeDetailId = (alertType: AlertType, value: string) => {
    if (alertType.type === "Video Loss") {
      setSelectIds((select) => {
        return {
          ...select,
          cameraId: value,
        };
      });
    }
    if (filteringDiskAlertBoolean(alertType)) {
      setSelectIds((select) => {
        return {
          ...select,
          storageName: value,
        };
      });
    }
  };

  const onChangeTypeSelect = (value: string) => {
    // console.log(value, "value");
    // let realValue =
    //   alertType.type === "Video Loss" ? splitCameraId(value) : value;

    onChangeDetailId(props.alertType, value);
    props.onChangeCurrentAlert(props.alertType, value);
  };

  const onChangeCurrentAlert = (event: any) => {
    // console.log(event.target.classList.value);
    // if (event.target.classList.value.includes("alert-type-select-box")) {
    //   // console.log("Child component clicked, but stopped propagation");
    //   // event.stopPropagation(); // 이벤트 버블링 중지
    // } else {
    //   // console.log("Child component clicked");
    // }

    if (!event.target.classList.value.includes("alert-type-select-box")) {
      if (props.alertType.type === "Video Loss") {
        props.onChangeCurrentAlert(props.alertType, selectIds.cameraId);
      } else if (filteringDiskAlertBoolean(props.alertType)) {
        props.onChangeCurrentAlert(props.alertType, selectIds.storageName);
      } else {
        props.onChangeCurrentAlert(props.alertType);
      }
    }
  };

  const icon = (type: string) => {
    if (type === "Video Loss") {
      return CCTVRED;
    } else {
      return SYSTEMALERT;
    }
  };

  useEffect(() => {
    // console.log(props.currentAlert.camera, 123);
    if (props.currentAlert.type === "Video Loss" && props.currentAlert.camera) {
      setSelectIds({
        ...selectIds,
        cameraId: cameraUniqueValue(props.currentAlert.camera),
      });
    }

    if (
      filteringDiskAlertBoolean(props.currentAlert) &&
      props.currentAlert.storageName
    ) {
      setSelectIds({
        ...selectIds,
        storageName: props.currentAlert.storageName,
      });
    }
  }, [props.currentAlert]);

  return (
    <alertListStyled.AlertItem
      selected={props.currentAlert.type === props.alertType.type}
      isMap={isMap}
      onClick={onChangeCurrentAlert}
    >
      <alertListStyled.TitleWrapper isMap={isMap}>
        <div className="title">
          <div className="alert-type-icon">
            <img src={icon(props.alertType.type)} />
          </div>
          <span>{props.alertType.type}</span>
        </div>
        {props.alertType.type === "Video Loss" &&
          props.alertType.cameraList !== undefined && (
            <alertListStyled.VideoLossThumbnail>
              <VideoThumbnail
                videoLoss={false}
                thumbnailUrl={
                  selectIds.cameraId === ""
                    ? props.alertType.cameraList[0].thumbnailUrl
                    : props.alertType.cameraList.find(
                        (cam) =>
                          cam.cameraId === splitCameraId(selectIds.cameraId)
                      )?.thumbnailUrl
                }
                isRecorderList={true}
              />
            </alertListStyled.VideoLossThumbnail>
          )}
      </alertListStyled.TitleWrapper>
      <alertListStyled.SelectWrapper isMap={isMap}>
        {props.alertType.type === "Video Loss" ||
        filteringDiskAlertBoolean(props.alertType) ? (
          <div className="select-box video" ref={selectRef}>
            {props.alertType.type === "Video Loss" &&
              props.alertType.cameraList !== undefined && (
                <AlertTypeSelect
                  classNamePrefix="alert-type-select-box"
                  options={props.alertType.cameraList}
                  value={props.alertType.cameraList.find(function (option) {
                    return option.value === selectIds.cameraId;
                  })}
                  onChange={(value: Option) => {
                    onChangeTypeSelect(value.value);
                  }}
                  isDisabled={props.alertType.cameraList.length === 1}
                />
              )}

            {filteringDiskAlertBoolean(props.alertType) &&
              props.alertType.storageList !== undefined && (
                <AlertTypeSelect
                  // defaultValue={props.alertType.storageList[0]}
                  classNamePrefix="alert-type-select-box"
                  options={props.alertType.storageList}
                  value={props.alertType.storageList.find(function (option) {
                    return option.value === selectIds.storageName;
                  })}
                  onChange={(value: Option) => {
                    onChangeTypeSelect(value.value);
                  }}
                  isDisabled={props.alertType.storageList.length === 1}
                />
              )}
          </div>
        ) : (
          <div></div>
        )}
        {isCheckPermission("manageAlertRules", selectedAccount) && (
          <div className="select-box" ref={selectRef}>
            <AlertTypeSelect
              options={alertStatusOptions}
              onChange={(value: Option) => props.onChangeStatus(value.value)}
              isDisabled={props.alertType.status === "Closed"}
              value={
                props.alertType.status === "Assigned"
                  ? alertStatusOptions.find(function (option) {
                      return option.value === "Active";
                    })
                  : alertStatusOptions.find(function (option) {
                      return option.value === props.alertType.status;
                    })
              }
              // defaultValue={defaultValue}
            />
          </div>
        )}
      </alertListStyled.SelectWrapper>
    </alertListStyled.AlertItem>
  );
};

export default AlertTypeItem;
