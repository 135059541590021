import { getVideoTokenInfo, postResendCodeForGuest } from "api/videoshareAPI";
import VideoPlayer from "components/blocks/video/VideoPlayer";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as cs from "pages/auth/SignCommonStyled";
import VerifyTitle from "components/blocks/verify/VerifyTitle";
import VerfiyCode from "components/blocks/verify/VerfiyCode";
import { FormattedMessage, useIntl } from "react-intl";
import { Text, UnderlineText, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import {
  GetVideoStringResponse,
  GuestVideoInfo,
  VideoPlayList,
} from "api/interfaces/videoshareInterface.interface";
import { CustomError } from "api/interfaces/commonInterface.interface";
import LinkExpired from "assets/images/video/expired.png";
import { calcRem } from "styles/theme";
import styled from "styled-components";

enum GUEST_VIDEO_STEP {
  SEND_CODE = "SEND_CODE",
  VERIFY_CODE = "VERIFY_CODE",
}

export default function GuestShare({}): JSX.Element {
  const intl = useIntl();
  const { pathname } = useLocation();
  const playerReference = React.useRef(null);
  const [videoSource, setVideoSource] = useState<string>("");

  const [step, setStep] = useState<GUEST_VIDEO_STEP>(
    GUEST_VIDEO_STEP.SEND_CODE
  );

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [retVerifyCode, setRetVerifyCode] = useState<String>("");
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [shareEmail, setShareEmail] = useState<string>("");
  const [canDownload, setCanDownload] = useState<boolean>(false);
  const [startMs, setStartMs] = useState<string>("");
  const [shareEnd, setShareEnd] = useState<boolean>(false);
  //BUG [RND-360]
  const [videoId, setVideoId] = useState<string>("");
  const [timezone, setTimezone] = useState<string>("");

  const onChangeVerifyCode = useCallback((value: string) => {
    setRetVerifyCode(value);
  }, []);

  //get video token info 에러나서 일단 주석처리해놓음
  useEffect(() => {
    const getToken = async (payload: GuestVideoInfo) => {
      const data = getVideoTokenInfo(payload)
        .then((res: GetVideoStringResponse) => {
          if (res.error === 0 && res.result !== undefined) {
            setVerifyCode(res.result.verifiedNumber);
            setShareEmail(res.result.sharedEmail);
            setVideoSource(res.result.url);
            setCanDownload(
              res.result.canDownload !== undefined
                ? res.result.canDownload
                : false
            );
            setStartMs(res.result.startTimeMs);
            setTimezone(res.result.timeZone);
            setVideoId(res.result.videoId);
          } else {
            setShareEnd(true);
          }
        })
        .catch((error: CustomError) => {
          //BUG  [RND-295] expiredLink
          if (error.response?.data !== undefined) {
            if (error.response?.data.error === 11003) {
              setShareEnd(true);
            }
          }
        });
    };
    const params = new URLSearchParams(window.location.search);
    const videoId = params.get("videoId") as string;
    const email = params.get("userId") as string;

    if (videoId !== undefined && email !== undefined) {
      const payload: GuestVideoInfo = {
        videoId: videoId,
        userId: email,
      };
      getToken(payload);
    }
  }, []);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
  };

  const playerReady = (player: any) => {
    playerReference.current = player;

    // handling video player
    player.on("waiting", () => {
      console.log("Video Player is waiting");
    });
    player.on("dispose", () => {
      console.log("Video player will dispose");
    });
  };

  const mutationResendCode = useMutation(postResendCodeForGuest, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        setStep(GUEST_VIDEO_STEP.VERIFY_CODE);
        setVerifyCode(res.result.verifiedNumber);
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.videoshare.guest.verify.send.fail",
            defaultMessage: "Fail to send Verify message",
          })
        );
      }
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.videoshare.guest.verify.send.fail",
          defaultMessage: "Fail to send Verify message",
        })
      );
    },
  });

  const verifyType: string = "EMAIL";
  const onClickVerifyResend = useCallback(() => {
    mutationResendCode.mutate({
      payload: {
        twoFactorType: verifyType,
        email: shareEmail,
      },
    });
  }, [mutationResendCode, shareEmail]);

  const onClickVerify = useCallback(() => {
    // mutationVerify.mutate(verifyCode.join(""));
    if (retVerifyCode === verifyCode) {
      setIsVerified(true);
    } else {
      notify(
        "error",
        intl.formatMessage({
          id: "label.videoshare.guest.verify.fail",
          defaultMessage: "Fail to verify Authentication.",
        })
      );
    }
  }, [retVerifyCode, verifyCode]);

  return (
    <cs.BG noticeLength={0}>
      {isVerified ? (
        <VideoPlayer
          videoUrl={videoSource}
          options={videoJsOptions}
          onReady={playerReady}
          canDownload={canDownload}
          isAuth={false}
          videoId={videoId}
          timezone={timezone}
          title="Guest"
          guestStartMs={startMs}
        />
      ) : (
        <cs.Container>
          <cs.LoginBox>
            {shareEnd ? (
              <cs.CenterBox>
                <img src={LinkExpired} width="185" alt="expired_link" />
                <ValidateWord>
                  {intl.formatMessage({
                    id: "validateMsg.videoShare.err.expiredLink",
                    defaultMessage: "This link has expired",
                  })}
                </ValidateWord>
              </cs.CenterBox>
            ) : step === GUEST_VIDEO_STEP.VERIFY_CODE ? (
              <>
                <VerifyTitle
                  title={intl.formatMessage({
                    id: "shared_email_authentication",
                    defaultMessage: "Video Share Authentication",
                  })}
                  desc={intl.formatMessage({
                    id: "enter_pin_message_shared",
                    defaultMessage:
                      "Enter the 6-digit code sent to your email.",
                  })}
                />
                <VerfiyCode
                  verifyCode={verifyCode}
                  onChange={onChangeVerifyCode}
                  onClickOtpEnter={onClickVerify}
                />
                <cs.CenterBox>
                  {!shareEnd && (
                    <UnderlineText
                      fontSize={14}
                      color={COLORS.BUTTONS2}
                      onClick={onClickVerifyResend}
                    >
                      Resend Code
                    </UnderlineText>
                  )}
                </cs.CenterBox>
                <cs.BigButton
                  onClick={onClickVerify}
                  disabled={shareEnd}
                  cancel={shareEnd}
                >
                  Verify
                </cs.BigButton>
              </>
            ) : (
              <>
                <VerifyTitle
                  title={intl.formatMessage({
                    id: "shared_email_authentication",
                    defaultMessage: "Video Share Authentication",
                  })}
                  desc={""}
                />
                <p className="description">
                  {intl.formatMessage({
                    id: "label.videoshare.guest.verification.sendCode",
                    defaultMessage:
                      "For Security reasons, myDW required an email verification to access shared videos. Click the button below to send a verification code to",
                  })}
                </p>
                <Text fontSize={14} className="user-name">
                  {shareEmail}
                </Text>
                <cs.CenterBox>
                  <br />
                  <br />
                </cs.CenterBox>
                <cs.Bottom>
                  <cs.BigButton
                    onClick={onClickVerifyResend}
                    disabled={shareEnd}
                    cancel={shareEnd}
                  >
                    Send Code
                  </cs.BigButton>
                </cs.Bottom>
              </>
            )}
          </cs.LoginBox>
        </cs.Container>
      )}
    </cs.BG>
  );
}
