import {
  NavigateFunction,
  NavigateOptions,
  To,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import accountSVG from "assets/icons/sidenav/account.svg";
import alertSVG from "assets/icons/sidenav/alerts.svg";
import videoSVG from "assets/icons/sidenav/videoshare.svg";
import billingSVG from "assets/icons/sidenav/billing.svg";
import helpSVG from "assets/icons/sidenav/help.svg";
import reportSVG from "assets/icons/sidenav/reports.svg";
import settingSVG from "assets/icons/sidenav/settings.svg";
import userSVG from "assets/icons/sidenav/users.svg";
import { useAppDispatch } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { RoutesProps } from "api/interfaces/menuInterface.interface";
import {
  BasePagination,
  DefaultQueryParam,
} from "api/interfaces/commonInterface.interface";
import { pickBy, replace } from "lodash";
import _ from "lodash";
import {
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import { string } from "prop-types";

export const dwMenuData: Array<RoutesProps> = [
  {
    svg: null,
    name: "Dashboard",
    routeName: "/dashboard",
    isRole: "rw",
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Map",
        routeName: "/dashboard/map",
        ishidden: false,
      },

      {
        svg: null,
        isRole: "rw",
        name: "Stats",
        routeName: "/dashboard/admin",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Widgets",
        routeName: "/dashboard/chart",
        ishidden: false,
      },
    ],
    ishidden: true,
  },
  {
    svg: accountSVG,
    name: "Organization",
    routeName: "/account",
    isRole: "rw",
    ishidden: false,
    children: [],
  },
  {
    svg: accountSVG,
    name: "Recorders",
    routeName: "/recorders",
    isRole: "rw",
    ishidden: true,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Recorders",
        title: "Recorder List",
        routeName: "/recorders/list",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Recorder Groups",
        title: "Recorder Groups",
        routeName: "/recorders/groups",
        ishidden: false,
      },
    ],
  },
  {
    svg: alertSVG,
    name: "Alert",
    isRole: "rw",
    routeName: "/alert/active",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Alerts",
        title: "Alert",
        routeName: "/alert/active",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Alert History",
        title: "Alert History",
        routeName: "/alert/history",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Rules",
        title: "Rules",
        routeName: "/alert/rules",
        ishidden: false,
      },
    ],
  },
  {
    svg: videoSVG,
    name: "Video Share",
    routeName: "/videoshare",
    isRole: "rw",
    ishidden: false,
    children: [],
  },
  {
    svg: reportSVG,
    name: "Reports",
    routeName: "/reports",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "",
        title: "Report List",
        routeName: "/reports",
        ishidden: false,
      },
    ],
  },
  {
    svg: userSVG,
    name: "Users",
    routeName: "/users",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Users",
        title: "User List",
        routeName: "/users/list",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "User Groups",
        title: "User Groups",
        routeName: "/users/groups",
        ishidden: false,
      },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Roles",
      //   title: "User Roles",
      //   routeName: "/users/roles",
      //   ishidden: false,
      // },
    ],
  },
  {
    svg: billingSVG,
    name: "Billing",
    routeName: "/billing",
    isRole: "rw",
    ishidden: false,
    children: [],
    subRouteName: ["/billing/license"],
  },
  {
    svg: settingSVG,
    name: "Settings",
    routeName: "/settings",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "My Profile",
        routeName: "/settings/myaccount",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "My Organization",
        routeName: "/settings/adminAccount",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Licenses",
        routeName: "/settings/license",
        ishidden: false,
      },
    ],
  },
  {
    svg: helpSVG,
    name: "Help",
    routeName: "/help/videoguides",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Video Guides",
        title: "Video Guides",
        routeName: "/help/videoguides",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Tech Support",
        title: "Tech Support",
        routeName: "/help/techsupport",
        ishidden: false,
      },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Setup Guide",
      //   title: "Setup Guide",
      //   routeName: "/help/setupguide",
      //   ishidden: false,
      // },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Speed Test",
      //   title: "Speed Test",
      //   routeName: "/help/speedtest",
      //   ishidden: false,
      // },
      {
        svg: null,
        isRole: "rw",
        name: "About",
        title: "About",
        routeName: "/help/about",
        ishidden: false,
      },
    ],
  },
  {
    svg: settingSVG,
    name: "Management",
    routeName: "/manager/guideSetting",
    isRole: "rw",
    ishidden: false,
    children: [
      {
        svg: null,
        isRole: "rw",
        name: "Video Guide Setting",
        title: "Video Guide Setting",
        routeName: "/manager/guideSetting",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Tech Support",
        title: "Tech Support",
        routeName: "/manager/techSummit",
        ishidden: false,
      },
      // {
      //   svg: null,
      //   isRole: "rw",
      //   name: "Operation Log",
      //   title: "Operation Log",
      //   routeName: "/manager/operationLog",
      //   ishidden: false,
      // },
      {
        svg: null,
        isRole: "rw",
        name: "Site Notices",
        routeName: "/manager/sitenotices",
        ishidden: false,
      },
      {
        svg: null,
        isRole: "rw",
        name: "Emails",
        routeName: "/manager/emails",
        ishidden: false,
      },
    ],
  },
];

export const GoPageNav = (
  navigate: NavigateFunction,
  dispatch: Dispatch<AnyAction>,
  to: string,
  options?: NavigateOptions
) => {
  const selectMenu = dwMenuData.find((menu) => {
    return (
      menu.routeName === to ||
      (menu.routeName !== undefined && to.startsWith(menu.routeName)) ||
      (menu.subRouteName !== undefined && menu.subRouteName.includes(to)) ||
      menu.children.find((subMenu) => subMenu.routeName === to) !== undefined
    );
  });

  if (selectMenu !== undefined) {
    navigate(to, options);
    dispatch(setMenu(selectMenu.name));
    return true;
  } else {
    return false;
  }
};

export const getSubMenu = (to: string) => {
  const selectMenu = dwMenuData.find((menu) => {
    return (
      menu.routeName === to ||
      (menu.routeName !== undefined && to.startsWith(menu.routeName)) ||
      (menu.subRouteName !== undefined && menu.subRouteName.includes(to)) ||
      menu.children.find((subMenu) => subMenu.routeName === to) !== undefined
    );
  });

  if (selectMenu) {
    return selectMenu;
  } else {
    return undefined;
  }
};

export const queryNavigate = (
  navigate: NavigateFunction,
  to: string,
  queryInfo: DefaultQueryParam,
  selectAccount?: ProfileAccountInfo
) => {
  let realQuery = Object.entries(queryInfo)
    .filter((data) => data[1] !== null && data[1] !== undefined)
    .map((e) => {
      if (e[1] !== undefined && e[1] !== null) {
        return e[0].concat("=").concat(encodeURIComponent(e[1]));
      } else {
        return "";
      }
    })
    .join("&");
  //navigate(`${to}?${realQuery}`);
  if (selectAccount !== undefined) {
    realQuery = `account=${selectAccount.accountId}&${realQuery}`;
  }

  navigate({
    pathname: to,
    search: `?${realQuery}`,
  });
  //window.history.pushState(null, "", `${to}?${encodeURIComponent(realQuery)}`);
};

export const menuNavigation = (
  selectedAccount: ProfileAccountInfo | ProfileAccountInfoFromServer | string,
  navigate: NavigateFunction,
  to: string,
  replace?: boolean,
  state?: any,
  queryString?: Object
) => {
  let realQuery =
    queryString !== undefined
      ? Object.entries(queryString)
          .map((e) => e.join("="))
          .join("&")
      : undefined;
  if (state !== undefined) {
    navigate(
      {
        pathname: to,
        search:
          realQuery !== undefined
            ? `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}&${realQuery}`
            : `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}`,
      },
      {
        state: state,
        replace: replace !== undefined ? replace : false,
      }
    );
  } else {
    navigate(
      {
        pathname: to,
        search:
          realQuery !== undefined
            ? `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}&${realQuery}`
            : `?${createSearchParams({
                account:
                  typeof selectedAccount === `string`
                    ? selectedAccount
                    : selectedAccount.accountId,
              })}`,
      },
      {
        replace: replace !== undefined ? replace : false,
      }
    );
  }
};
