import {
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import { getSwitchAccount, getUserDetailAccount, getUserDetailAll } from "api/userAPI";
import {
  RecorderPermission,
  UserInfo,
  UserPermission,
} from "api/interfaces/userInterface.interface";
import { changeFirstWordToUppercase } from "./functions";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { isEmpty } from "lodash";

export const getAllAccountInfo = () => {
  return new Promise<UserInfo>(function (resolve, reject) {
    getUserDetailAll()
      .then((response) => {
        console.log(response, " response");
        resolve(response);
      })
      .catch(function (err) {
        reject("communication fail");
      });
  });
};

export const getAccountInfo = (accountId: string) => {
  return new Promise<ProfileAccountInfoFromServer>(function (resolve, reject) {
    getUserDetailAccount(accountId)
      .then((response) => {
        // console.log(response, " response");
        resolve(response.accounts);
      })
      .catch(function (err) {
        reject("communication fail");
      });
  });
};

export const getSwitchAccountInfo = (accountId: string) => {
  return new Promise<ProfileAccountInfoFromServer>(function (resolve, reject) {
    getSwitchAccount(accountId)
      .then((response) => {
        // console.log(response, " response");
        resolve(response.accounts);
      })
      .catch(function (err) {
        reject("communication fail");
      });
  });
};

export const checkPermission = (array: UserPermission[]) => {
  const UserPermission = {
    enableAllPermissions:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.enableAllPermissions ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageAlertRules:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageAlertRules ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewAlerts:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewAlerts ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageUsersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageUsersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewUsersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewUsersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageReportRules:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageReportRules ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewReports:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewReports ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageRecordersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageRecordersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewRecordersAndGroups:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewRecordersAndGroups ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageAccount:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageAccount ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewAccount:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewAccount ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    view:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.view ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    manageBillingAndLicenses:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.manageBillingAndLicenses ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
  };
  return UserPermission;
};

export const isCheckPermission = (
  requiredPermissions: string,
  selectedAccount: ProfileAccountInfo
) => {
  const hasRequiredPermissions =
    selectedAccount.userPermission[
      requiredPermissions as keyof typeof selectedAccount.userPermission
    ];
  return hasRequiredPermissions as boolean;
};

export const checkGroup = (permission: UserPermission[], group: string) => {
  const check = permission.filter(
    (permit) =>
      !permit.enableDelete && permit.groupName?.toLowerCase() === group
  );
  return check.length !== 0 ? true : false;
};

export const checkRecorderPermission = (array: RecorderPermission[]) => {
  const recorderPermission = {
    cameraSetting:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.cameraSetting ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewArchive:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewArchive ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    exportArchive:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.exportArchive ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewBookmark:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewBookmark ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    modifyBookmark:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.modifyBookmark ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    viewEventLog:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.viewEventLog ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
    cameraUserInput:
      array !== undefined && array.length > 0
        ? array.reduce(function (sum, permission) {
            return sum + (permission.cameraUserInput ? 1 : 0);
          }, 0) > 0
          ? true
          : false
        : true,
  };
  return recorderPermission;
};

export const isHiddenGroup = (array: UserPermission[]) => {
  if (array === undefined || array.length > 1) {
    return false;
  } else {
    if (
      array.length === 1 &&
      array[0].groupName?.toUpperCase() === "HIDDEN" &&
      array[0].isHidden !== undefined &&
      array[0].isHidden
    ) {
      return true;
    } else {
      return false;
    }
  }
};

// isTrial 과 licenseType 에 따른 menu 보여주기
export const checkTrialShowMenu = (
  accounts: ProfileAccountInfo | ProfileAccountInfoFromServer
) => {
  if (!accounts.isTrial && accounts.licenseType === "none") return true;
  else return false;
};

export const changeToTrialType = (accounts: ProfileAccountInfo) => {
  if (
    accounts.trialDays === undefined ||
    accounts.isTrial === undefined ||
    accounts.licenseType === undefined
  )
    return "";

  if (accounts.isTrial && accounts.trialDays > 0) {
    return "Trial";
  }

  if (!accounts.isTrial && accounts.licenseType !== "none") {
    return changeFirstWordToUppercase(accounts.licenseType);
  } else return "Free";
};

export const getSpectrumAuthUrl = (loginEmail?: string) => {
  const OATUH_HOST = "https://dwspectrum.digital-watchdog.com/authorize";
  const client_id = "mydw-api";
  const redirect_uri = process.env.REACT_APP_OAUTH_CALLBACK_URL;
  const response_type = "code";
  const email =
    loginEmail !== undefined && !isEmpty(loginEmail)
      ? loginEmail.toLowerCase()
      : null;

  const AUTHORIZE_URI = `${OATUH_HOST}?${qs.stringify(
    {
      client_id,
      redirect_uri,
      response_type,
      email,
    },
    { skipNulls: true }
  )}`;

  return AUTHORIZE_URI;
};
