// import ARROWDOWN from "assets/icons/arrow_down.svg";
import { ReactNode, useState } from "react";
import Select, {
  components,
  GroupBase,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  ValueContainerProps,
} from "react-select";
import Group from "react-select/dist/declarations/src/components/Group";
import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { Option } from "./util";
import { calcRem } from "styles/theme";
import { useAppSelector } from "redux/hooks";

type OptionsType = Array<Option>;

interface CommonProps {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;

  //selectOption: OptionType => void;
  // selectProps: any,
  //setValue: (ValueType: any, ActionTypes: any) => void,
  // emotion: any,
  value?: any;
  defaultValue?: any;
  isDisabled?: boolean;
  onChange?: (value: MultiValue<any>) => void;
  placeholder?: string;
  id?: string;
}

const customStyles: StylesConfig<Option> = {
  control: (styles, state) => {
    let isLightTheme = state.selectProps.className === "select-light-mode";
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";
    const maxHeight = "33px";
    const minHeight = "33px";

    return { ...styles, backgroundColor, borderColor, maxHeight, minHeight };
  },
  container: (styles, state) => {
    let isLightTheme = state.selectProps.className === "select-light-mode";

    return {
      ...styles,
      width: "100%",
      height: "100%",
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : COLORS.WHITE
        : COLORS.BLACK,
      fontSize: calcRem(14),
      fontWeight: "normal",
    };
  },
  valueContainer: (styles, state) => {
    let isLightTheme = state.selectProps.className === "select-light-mode";

    return {
      ...styles,
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : `${COLORS.WHITE}60`
        : COLORS.BLACK,
      fontSize: calcRem(14),
      fontWeight: "normal",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles) => ({ ...styles, padding: 0, height: "33px" }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    color: COLORS.BUTTONS1,
  }),
};

const CustomOption = (props: OptionProps<any, boolean, GroupBase<Option>>) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  const InnerProps = {
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };
  return (
    <div>
      <components.Option {...props}>
        <Inner>
          <input type="checkbox" checked={props.isSelected} readOnly={true} />
          {props.children}
        </Inner>
      </components.Option>
    </div>
  );
};
type SelectedBadgeProps = {
  items: string[];
};
const MoreSelectedBadge = (props: SelectedBadgeProps) => {
  const style = {
    // marginRight: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = props.items.join(", ");
  const length = props.items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const CustomMultiValue = (
  props: MultiValueProps<any, boolean, GroupBase<Option>>
) => {
  const maxToShow = 0;
  const overflow: string[] = props
    .getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return props.index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : props.index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const CustomValueContainer = (
  props: ValueContainerProps<any, boolean, GroupBase<Option>>
) => {
  let [values, input] = props.children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values =
      values.length === 1
        ? `${values[0].props.data.label}`
        : `${values[0].props.data.label} +${values.length - 1}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const SelectsCombo = (props: CommonProps) => {
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <Select
      id={props.id}
      {...props}
      isMulti
      styles={customStyles}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      placeholder={props.placeholder ? props.placeholder : "Select"}
      onChange={props.onChange}
      components={{
        Option: CustomOption,
        ValueContainer: CustomValueContainer,
      }}
      className={`select-${theme}-mode`}
    />
  );
};
export default SelectsCombo;

const Inner = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 4px;
`;
