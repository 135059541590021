import { Media, SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import Type from "components/atoms/text/labels/Type";
import HealthPresenter from "./HealthPresenter";
import {
  RecorderHealthRow,
  ReportHealthList,
} from "api/interfaces/reportInterface.interface";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import * as s from "./HealthStyled";
import {
  checkStatus,
  convertType,
  formatBytesFromMB,
  isFullStorage,
} from "utils/functions";
import TableTitle from "components/blocks/table/TableTitle";
import Status from "components/atoms/text/labels/Status";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import { useAppSelector } from "redux/hooks";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getHealthMergedReportList, getHealthReportList } from "api/reportAPI";
import { useQuery } from "react-query";
// import { healthTableData } from "pages/reports/tempData";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import WebAppLink from "components/blocks/link/WebAppLink";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";

type Props = {
  state: ReportDataRow;
};

interface HealthStateProps {
  id: string | undefined;
  title: string;
  mergedSystemId: string;
  mergedSystemName: string;
}

export default function HealthContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const isDetail: boolean = location.pathname.includes("merged");

  const reportId = params.reportId as string;
  const mergedSystemId = params.mergedSystemId as string;
  // const state =
  //   location.state !== null
  //     ? (location.state as HealthStateProps)
  //     : {
  //         id: undefined,
  //         title: "",
  //         mergedSystemId: "",
  //         mergedSystemName: "",
  //       };
  // const [healthStateProps, SetHealthStateProps] =
  //   useState<HealthStateProps>(state);

  useEffect(() => {
    // if (location.state) {
    //   SetHealthStateProps(location.state);
    // }

    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      if (!isDetail) {
        setReportQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 20
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("keyword"))
              ? "name"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
          };
        });
      } else {
        setReportMergedQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 20
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("keyword"))
              ? "name"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
          };
        });
      }
    } else {
      if (!isDetail) {
        setReportQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 20,
            keyword: "",
            sortType: "name",
            sortDirection: SORT_DIRECTION.ASC,
          };
        });
      } else {
        setReportMergedQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 20,
            keyword: "",
            sortType: "name",
            sortDirection: SORT_DIRECTION.ASC,
          };
        });
      }
    }
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [mergedState, setMergedState] = useState({
  //   mergedSystemId: healthStateProps.mergedSystemId,
  //   mergedSystemName: healthStateProps.mergedSystemName,
  //   reportId: healthStateProps.id,
  // });

  const [reportList, setReportList] = useState<RecorderHealthRow[]>([]);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [mouseIndex, setMouseIndex] = useState<number | null>(null);
  const [reportTitle, setReportTitle] = useState<string>("");
  const [mergedSystemName, setMergedSystemName] = useState<string>("");
  // header 에 있는 상태 표시용 (healthy : healthy + warning / alert: alert + critical + assigned)
  const [totalCount, setTotalCount] = useState({
    total: 0,
    healthy: 0,
    alert: 0,
  });

  const [alertDetail, setAlertDetail] = useState({
    system: 0,
    disk: 0,
    video: 0,
  });

  useEffect(() => {}, [location]);

  // table column
  const columns: TableColumn<RecorderHealthRow>[] = !isDetail
    ? [
        {
          //name: <TableColumnFilterTitle title="Type" />,
          name: "Type",
          grow: 1,
          maxWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "name",
          name: "Title",
          sortable: true,
          sortField: "name",
          grow: 6,
          minWidth: Media.SM ? "100px" : "400px",
          maxWidth: "560px",
          cell: (row) => (
            <TableTitle
              report
              row={row}
              //onUnderlineClick={onUnderlineClick}
              onCellClick={() => onRowClicked(row)}
            />
          ),
        },
        {
          name: "",
          minWidth: "140px",
          grow: 1,
          cell: (row) => (
            <s.Row>
              {selectedAccount.accountLevel === LEVEL_TYPE.EU &&
                !selectedAccount.isHidden && (
                  <WebAppLink
                    type={row.type}
                    accountId={selectedAccount.accountId}
                    recorderId={row.recorderId}
                    mergedSystemId={row.mergedSystemId}
                    cloudSystemId={row.cloudSystemId}
                  />
                )}
            </s.Row>
          ),
          right: true,
          hide: Media.SM,
        },
        {
          //name: <TableColumnFilterTitle title="Status" />,
          name: "Status",
          center: true,
          grow: 2,
          cell: (row, index) => (
            <s.StatusButton
              onMouseEnter={() => {
                setIsMouseOver(true);
                setMouseIndex(index);
              }}
              onMouseLeave={() => {
                setIsMouseOver(false);
                setMouseIndex(null);
              }}
            >
              <Status
                status={checkStatus(row.recorderCount, row.onlineStatusCount)}
              />

              <s.StatusBubble>
                {isMouseOver && mouseIndex !== null && mouseIndex === index && (
                  <InfoBubble>
                    Online : {row.onlineStatusCount}/{row.recorderCount}
                  </InfoBubble>
                )}
              </s.StatusBubble>
            </s.StatusButton>
          ),
        },
        {
          name: "Local Storage",
          grow: 2,
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 1 ? (
              <></>
            ) : row.totalStorage !== 0 ? (
              <s.StorageText
                full={isFullStorage(
                  row.totalStorage - row.freeStorage,
                  row.totalStorage
                )}
              >
                {formatBytesFromMB(row.totalStorage - row.freeStorage)} /{" "}
                {formatBytesFromMB(row.totalStorage)}
              </s.StorageText>
            ) : (
              "N/A"
            ),
          hide: Media.SM,
        },
        {
          name: "Cameras (Loss / Total)",
          grow: 2,
          minWidth: "150px",
          cell: (row) => (
            <s.CameraRow>
              <s.CameraItem>
                <span className="loss">{row.lossCamera}</span> /{" "}
                <span className="title">{row.totalCamera}</span>
              </s.CameraItem>
            </s.CameraRow>
          ),
          hide: Media.SM,
        },
        // 마지막 버튼
        {
          name: "",
          grow: 1,
          minWidth: "100px",
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 1 ? (
              <></>
            ) : (
              <span className="row-click-button">
                <TableButton
                  label="View"
                  onClickBtn={() => onRowClicked(row)}
                />
              </span>
            ),
          right: true,
        },
      ]
    : [
        {
          name: "Type",
          grow: 1,
          maxWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "name",
          name: "Title",
          sortable: true,
          sortField: "name",
          grow: 6,
          minWidth: Media.SM ? "100px" : "400px",
          maxWidth: "560px",
          cell: (row) => (
            <TableTitle
              report
              row={row}
              //onUnderlineClick={onUnderlineClick}
              onCellClick={() => onRowClicked(row)}
            />
          ),
        },
        {
          name: "",
          minWidth: "140px",
          grow: 1,
          cell: (row) => (
            <s.Row>
              {selectedAccount.accountLevel === LEVEL_TYPE.EU &&
                !selectedAccount.isHidden &&
                row.recorderCount === 1 && (
                  <WebAppLink
                    type={row.type}
                    accountId={selectedAccount.accountId}
                    recorderId={row.recorderId}
                    mergedSystemId={row.mergedSystemId}
                    cloudSystemId={row.cloudSystemId}
                  />
                )}
            </s.Row>
          ),
          right: true,
          hide: Media.SM,
        },
        {
          // name: <TableColumnFilterTitle title="Status" />,
          name: "Status",
          center: true,
          grow: 2,
          cell: (row, index) => (
            <s.StatusButton
              onMouseEnter={() => {
                setIsMouseOver(true);
                setMouseIndex(index);
              }}
              onMouseLeave={() => {
                setIsMouseOver(false);
                setMouseIndex(null);
              }}
            >
              <Status
                status={checkStatus(
                  row.recorderCount !== undefined ? row.recorderCount : 1,
                  row.onlineStatusCount
                )}
              />

              <s.StatusBubble>
                {isMouseOver && mouseIndex !== null && mouseIndex === index && (
                  <InfoBubble>
                    Online : {row.onlineStatusCount}/
                    {row.recorderCount !== undefined ? row.recorderCount : 1}
                  </InfoBubble>
                )}
              </s.StatusBubble>
            </s.StatusButton>
          ),
        },
        {
          name: "Local Storage",
          grow: 2,
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 1 ? (
              <></>
            ) : row.totalStorage !== 0 ? (
              <s.StorageText
                full={isFullStorage(
                  row.totalStorage - row.freeStorage,
                  row.totalStorage
                )}
              >
                {formatBytesFromMB(row.totalStorage - row.freeStorage)} /{" "}
                {formatBytesFromMB(row.totalStorage)}
              </s.StorageText>
            ) : (
              "N/A"
            ),
          hide: Media.SM,
        },
        {
          name: "Cameras (Loss / Total)",
          grow: 2,
          minWidth: "150px",
          cell: (row) => (
            <s.CameraRow>
              <s.CameraItem>
                <span className="loss">{row.lossCamera}</span> /{" "}
                <span className="title">{row.totalCamera}</span>
              </s.CameraItem>
            </s.CameraRow>
          ),
          hide: Media.SM,
        },
        // 마지막 버튼
        {
          name: "",
          grow: 1,
          minWidth: "100px",
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 1 ? (
              <></>
            ) : (
              <span className="row-click-button">
                <TableButton
                  label="View"
                  onClickBtn={() => onRowClicked(row)}
                />
              </span>
            ),
          right: true,
        },
      ];

  const mobileColumns: TableColumn<RecorderHealthRow>[] = !isDetail
    ? [
        {
          name: "Type",
          grow: 1,
          minWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "name",
          name: "Title",
          sortable: true,
          sortField: "name",
          grow: 2,
          cell: (row) => (
            <TableTitle
              report
              row={row}
              onUnderlineClick={onUnderlineClick}
              onCellClick={() => onRowClicked(row)}
            />
          ),
        },

        {
          name: <TableColumnFilterTitle title="Status" />,
          center: true,
          grow: 1,

          cell: (row, index) => (
            <s.StatusButton
              onMouseEnter={() => {
                setIsMouseOver(true);
                setMouseIndex(index);
              }}
              onMouseLeave={() => {
                setIsMouseOver(false);
                setMouseIndex(null);
              }}
            >
              <Status
                status={checkStatus(row.recorderCount, row.onlineStatusCount)}
              />

              {/* <s.StatusBubble>
                {isMouseOver && mouseIndex !== null && mouseIndex === index && (
                  <InfoBubble>
                    Online : {row.onlineStatusCount}/{row.recorderCount}
                  </InfoBubble>
                )}
              </s.StatusBubble> */}
            </s.StatusButton>
          ),
        },

        // 마지막 버튼
        {
          name: "",
          grow: 1,
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 1 ? (
              <></>
            ) : (
              <span className="row-click-button">
                <TableButton
                  label="View"
                  onClickBtn={() => onRowClicked(row)}
                />
              </span>
            ),
          right: true,
        },
      ]
    : [
        {
          name: "Type",
          grow: 1,
          minWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "name",
          name: "Title",
          sortable: true,
          sortField: "name",
          grow: 2,
          ignoreRowClick: true,
          cell: (row) => (
            <TableTitle
              report
              row={row}
              onUnderlineClick={onUnderlineClick}
              onCellClick={() => console.log("on cell click")}
            />
          ),
        },

        {
          // name: <TableColumnFilterTitle title="Status" />,
          name: "Status",
          center: true,
          cell: (row, index) => (
            <s.StatusButton
              onMouseEnter={() => {
                setIsMouseOver(true);
                setMouseIndex(index);
              }}
              onMouseLeave={() => {
                setIsMouseOver(false);
                setMouseIndex(null);
              }}
            >
              <Status
                status={checkStatus(
                  row.recorderCount !== undefined ? row.recorderCount : 1,
                  row.onlineStatusCount
                )}
              />

              {/* <s.StatusBubble>
                {isMouseOver && mouseIndex !== null && mouseIndex === index && (
                  <InfoBubble>
                    Online : {row.onlineStatusCount}/
                    {row.recorderCount !== undefined ? row.recorderCount : 1}
                  </InfoBubble>
                )}
              </s.StatusBubble> */}
            </s.StatusButton>
          ),
        },

        // 마지막 버튼
        {
          name: "",
          grow: 1,
          cell: (row) =>
            row.recorderCount !== undefined && row.recorderCount > 1 ? (
              <></>
            ) : (
              <span className="row-click-button">
                <TableButton
                  label="View"
                  onClickBtn={() => onRowClicked(row)}
                />
              </span>
            ),
          right: true,
        },
      ];

  const onUnderlineClick = (systemName: string, mergedSystemId: string) => {
    // navigate(`/reports/health/merged`, {
    //   state: {
    //     title: healthStateProps.title,
    //     mergedSystemId: mergedSystemId,
    //     mergedSystemName: systemName,
    //     id: healthStateProps.id,
    //   },
    // });
  };

  const onRowClicked = (row: RecorderHealthRow) => {
    if (row.recorderCount > 1) {
      navigate({
        pathname: `/reports/health/merged/${reportId}/${row.mergedSystemId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    } else {
      navigate({
        pathname: `/reports/health/detail/${reportId}/${row.recorderId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    }
  };

  const [reportQueryInfo, setReportQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 20,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const [pending, setPending] = useState<boolean>(true);

  const healthReportQuery = useQuery(
    ["healthList", reportQueryInfo, reportId, selectedAccount.accountId],
    () =>
      getHealthReportList({
        payload: reportQueryInfo,
        reportId: reportId as string,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      enabled:
        !isDetail && reportId !== undefined && selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        if (
          res.result !== undefined &&
          res.result.healthReports !== undefined
        ) {
          setReportTitle(res.result.title);
          setReportList(
            res.result.healthReports.map((report: ReportHealthList) => {
              return {
                type: report.type,
                title: report.mergedSystemName,
                version: report.firmwareVersion,
                recorderCount: report.recorderCount,
                status: "status",
                //totalStorage : report.cloudStorage.totalStorage !== undefined ? report.cloudStorage.totalStorage : 0,
                //usedStorage :report.cloudStorage.usedStorage !== undefined ? report.cloudStorage.usedStorage : 0,
                totalStorage: report.storages.reduce(function (
                  prev: any,
                  next: any
                ) {
                  return prev + next.total;
                },
                0),
                freeStorage: report.storages.reduce(function (
                  prev: any,
                  next: any
                ) {
                  return prev + next.free;
                },
                0),
                cloudSystemId: report.cloudSystemId,
                totalCamera: report.cameraCount,
                lossCamera: report.videoLoss,
                onlineStatusCount: report.onlineStatusCount,
                mergedSystemId: report.mergedSystemId,
                mergedSystemName: report.mergedSystemName,
                recorderId: report.recorderId,
                recorderName: report.recorderName,
                recorderModel: report.recorderModel,
              };
            })
          );
          setTotalCount((info) => {
            return {
              ...info,
              total: res.page.total,
              healthy: res.result.healthy,
              alert:
                res.result.systemAlerts +
                res.result.diskAlerts +
                res.result.videoLossAlerts,
            };
          });

          setAlertDetail({
            system: res.result.systemAlerts,
            disk: res.result.diskAlerts,
            video: res.result.videoLossAlerts,
          });
        }
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.health.list.error",
            defaultMessage: "Health search error.",
          })
        );
      },
      onSettled: (e:any) =>{
        setPending(false);
      }
    }
  );

  const [reportMergedQueryInfo, setReportMergedQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 1,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

  const healthReportMergedQuery = useQuery(
    ["healthMergedList", reportMergedQueryInfo, mergedSystemId],
    () =>
      getHealthMergedReportList({
        payload: reportMergedQueryInfo,
        reportId: reportId as string,
        mergedSystemId: mergedSystemId as string,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      enabled: isDetail,
      onSuccess: (res: any) => {
        console.log("healthReportMergedQuery");
        setReportTitle(res.result.title);
        setReportList(
          res.result.healthReports.map((report: any) => {
            setMergedSystemName(report.mergedSystemName);
            return {
              type: report.type,
              title: report.recorderName,
              version: report.firmwareVersion,
              recorderCount: report.recorderCount,
              status: "status",

              // totalStorage:
              //   report.cloudStorage.totalStorage !== undefined
              //     ? report.cloudStorage.totalStorage
              //     : 0,
              // usedStorage:
              //   report.cloudStorage.usedStorage !== undefined
              //     ? report.cloudStorage.usedStorage
              //     : 0,
              totalStorage: report.storages.reduce(function (
                prev: any,
                next: any
              ) {
                return prev + next.total;
              },
              0),
              freeStorage: report.storages.reduce(function (
                prev: any,
                next: any
              ) {
                return prev + next.free;
              },
              0),
              totalCamera: report.cameraCount,
              lossCamera: report.videoLoss,
              mergedSystemId: report.mergedSystemId,
              mergedSystemName: report.mergedSystemName,
              cloudSystemId: report.cloudSystemId,
              onlineStatusCount: report.onlineStatusCount,
              recorderId: report.recorderId,
              recorderName: report.recorderName,
              recorderModel: report.recorderModel,
            };
          })
        );
        setTotalCount((info) => {
          return {
            ...info,
            healthy: res.result.healthy,
            alert:
              res.result.systemAlerts +
              res.result.diskAlerts +
              res.result.videoLossAlerts,
          };
        });
        setAlertDetail({
          system: res.result.systemAlerts,
          disk: res.result.diskAlerts,
          video: res.result.videoLossAlerts,
        });
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.health.list.error",
            defaultMessage: "Health search error.",
          })
        );
      },
      onSettled: (e:any) =>{
        setPending(false);
      }
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);
      if (!isDetail) {
        if (page !== reportQueryInfo.pageNum + 1) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportQueryInfo.pageLimit,
              pageNum: page - 1,
              keyword: reportQueryInfo.keyword as string,
              sortType: reportQueryInfo.sortType,
              sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
          setPending(true);
          // setReportQueryInfo((info) => {
          //   return { ...info, pageNum: page as number };
          // });
        }
      } else {
        if (page !== reportMergedQueryInfo.pageNum + 1) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportMergedQueryInfo.pageLimit,
              pageNum: page - 1,
              keyword: reportMergedQueryInfo.keyword as string,
              sortType: reportMergedQueryInfo.sortType,
              sortDirection:
                reportMergedQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
          setPending(true);
          // setReportMergedQueryInfo((info) => {
          //   return { ...info, pageNum: page as number };
          // });
        }
      }
    },
    [
      isDetail,
      location.pathname,
      navigate,
      reportMergedQueryInfo.keyword,
      reportMergedQueryInfo.pageLimit,
      reportMergedQueryInfo.pageNum,
      reportMergedQueryInfo.sortDirection,
      reportMergedQueryInfo.sortType,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.pageNum,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      selectedAccount,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (isDetail) {
        if(reportMergedQueryInfo.keyword !== keyword){
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportMergedQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: reportMergedQueryInfo.sortType,
              sortDirection:
                reportMergedQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        }else{
          healthReportMergedQuery.refetch();
        }
        setPending(true);
        // setReportMergedQueryInfo((query) => {
        //   return {
        //     ...query,
        //     keyword,
        //   };
        // });
        // healthReportMergedQuery.refetch();
      } else {
        if(reportQueryInfo.keyword !== keyword){
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: reportQueryInfo.sortType,
              sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        }else{
          healthReportQuery.refetch();
        }
        setPending(true);
      }
    },
    [healthReportMergedQuery, healthReportQuery, isDetail, location.pathname, navigate, reportMergedQueryInfo.keyword, reportMergedQueryInfo.pageLimit, reportMergedQueryInfo.sortDirection, reportMergedQueryInfo.sortType, reportQueryInfo.keyword, reportQueryInfo.pageLimit, reportQueryInfo.sortDirection, reportQueryInfo.sortType, selectedAccount]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      isDetail
        ? setReportMergedQueryInfo((info) => {
            return {
              ...info,
              sortType: selectedColumn.sortField as string,
              sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
            };
          })
        : setReportQueryInfo((info) => {
            return {
              ...info,
              sortType: selectedColumn.sortField as string,
              sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
            };
          });
        //setPending(true);
    }
  };

  return (
    <HealthPresenter
      selectedAccount={selectedAccount}
      title={reportTitle}
      columns={columns}
      mobileColumns={mobileColumns}
      data={reportList}
      isDetail={isDetail}
      // mergedState={mergedState}
      reportId={reportId}
      mergedSystemName={mergedSystemName}
      totalCount={totalCount}
      alertDetail={alertDetail}
      onRowClicked={onRowClicked}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      handleSort={handleSort}
      paginationInfo={isDetail ? reportMergedQueryInfo : reportQueryInfo}
      tablePending={pending}
    />
  );
}
