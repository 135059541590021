import { Text } from "components/atoms/text/Text";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { useState } from "react";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import AddHealth from "./health";
import AddCvv from "./cvv";
import AddInstall from "./installation";
import AddInventory from "./inventory";
import AddThumbnail from "./thumbnail";
import AddTimelapse from "./timelapse";
import { COLORS } from "styles/colors";
import {
  CVVReport,
  HealthReport,
  InstallReport,
  InventoryReport,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  type?: number;
  reportRow?: ReportDataRow;
  data?:
    | HealthReport
    | InventoryReport
    | InstallReport
    | CVVReport
    | ThumbnailReport;
};

export default function AddReport(props: Props): JSX.Element {
  type ObjType = {
    [key: number]: React.ReactNode;
  };
  // add 종류
  const [isChecked, setIsChecked] = useState<number>(
    props.type !== undefined ? props.type : 0
  );
  // next 버튼 클릭 시 true 로 전환 (이 방식이 맞는 지 모르겠음. by.ydoni)
  const [isAdd, setIsAdd] = useState<boolean>(
    props.type !== undefined ? true : false
  );

  const addType: ObjType = {
    0: (
      <AddHealth
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as HealthReport}
      />
    ),
    1: (
      <AddCvv
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as CVVReport}
      />
    ),
    2: (
      <AddThumbnail
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as ThumbnailReport}
      />
    ),
    3: (
      <AddInventory
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as InventoryReport}
      />
    ),
    4: (
      <AddInstall
        onModal={props.onModal}
        isEdit={props.isEdit}
        reportRow={props.reportRow}
        data={props.data as InstallReport}
      />
    ),
    5: <AddTimelapse onModal={props.onModal} />,
  };

  const addList = [
    {
      title: "Health",
      subTitle: "Provides a usage summary of one or more sites.",
      value: "health",
      disabled: false,
    },
    {
      title: "CVV (Camera View Verification)",
      subTitle: "Uses CVV to inform you of any scenery changes.",
      value: "cvv",
      disabled: false,
    },
    {
      title: "Thumbnail",
      subTitle: "Save thumbnails periodically.",
      value: "thumbnail",
      disabled: true,
    },
    {
      title: "Inventory",
      subTitle: "Displays a list of sites with detailed information.",
      value: "inventory",
      disabled: false,
    },
    {
      title: "Installation",
      subTitle:
        "Provides installation confirmation with recorder and camera information.",
      value: "installation",
      disabled: false,
    },
    {
      title: "Timelapse",
      subTitle:
        "Create timelapses at a set duration and speed for any of your camera channels.",
      value: "timelapse",
      disabled: true,
    },
  ];

  return isAdd ? (
    <BigPopupTemplate
      title={props.isEdit ? "Edit Report" : "Add Report"}
      subTitle={addList[isChecked].title}
      onModal={props.onModal}
    >
      {addType[isChecked]}
    </BigPopupTemplate>
  ) : (
    <SmallPopupTemplate
      title="Create a New Report"
      subTitle="Select a type of report"
      onClick={() => setIsAdd(true)}
      onModal={props.onModal}
    >
      <RadioWrapper>
        {addList.map((item, index) => (
          <RadioItem
            key={index}
            aria-disabled={item.disabled}
            isDisabled={item.disabled}
            // onClick={() => {
            //   if(!item.disabled){
            //     setIsChecked(index);
            //   }
            // }}
          >
            <input
              type="radio"
              name="addType"
              value={index}
              checked={isChecked === index}
              onChange={(e) => {
                setIsChecked(index);
              }}
              readOnly={item.disabled}
              disabled={item.disabled}
            />
            <p>
              <Text
                className="report-radio-item"
                fontSize={18}
                bold
                aria-disabled={item.disabled}
                // color={item.disabled ? `${COLORS.WHITE}80` : COLORS.WHITE}
              >
                {item.title}
              </Text>
              <Text color="#80879C" fontSize={14} className="subtitle">
                {item.subTitle}
              </Text>
            </p>
          </RadioItem>
        ))}
      </RadioWrapper>
    </SmallPopupTemplate>
  );
}

const RadioWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 20px;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.COLORS.WHJITE};
  /* width: 400px;
  max-width: 400px; */
  /* height: 300px; */
`;

const RadioItem = styled.label<{ isDisabled: boolean }>`
  ${FLEX.FlexStartStart};
  gap: 10px;
  width: 100%;
  cursor: pointer;

  > input {
    width: 15px;
    height: 15px;
  }

  p {
    width: 80%;
  }

  .subtitle {
    padding-top: 4px;
    white-space: normal;
    line-height: ${calcRem(18)};
  }

  .report-radio-item {
    color: ${({ isDisabled, theme }) =>
      isDisabled ? `${theme.COLORS.WHITE}80` : theme.COLORS.WHITE};
  }
`;
