import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import SEARCH_ICON from "assets/icons/search.svg";
import SEARCHGRAY_ICON from "assets/icons/search_gray.svg";
import Input from "components/atoms/input/Input";
import * as mediaQuery from "components/MediaQuery";
import { useEffect, useState, InputHTMLAttributes } from "react";
import { checkBlankSpace, checkKeywordSpace } from "utils/functions";

export type ClickSearch = (keyword: string) => void;
export interface SearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  isValidated?: boolean;
  placeholder?: string;
  children?: any;
  keyword?: string;
  map?: boolean;
  onClickSearch?: ClickSearch;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

const SearchInput = ({
  isValidated, // validation true 일 때 빨간색 border
  placeholder,
  keyword,
  map,
  onClickSearch,
  disabled,
  onChange,
  ...rest
}: SearchInputProps): JSX.Element => {
  const onClick = onClickSearch ? onClickSearch : () => {};
  const [value, setValue] = useState<string>(keyword ? keyword : "");

  useEffect(() => {
    if (keyword !== undefined) setValue(keyword);
  }, [keyword]);

  return (
    <Wrapper
      map={map}
      isValidated={isValidated}
      disabled={disabled !== undefined ? disabled : false}
    >
      <Input
        {...rest}
        type="search"
        value={value}
        isValidated={isValidated}
        placeholder={placeholder ? placeholder : "Search"}
        disabled={disabled !== undefined ? disabled : false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (checkKeywordSpace(value)) return;
            if (onClick) {
              onClick(value);
            }
          }
        }}
        onChange={(e) => {
          onChange && onChange(e);
          setValue(e.target.value);
        }}
      />
      <span className="search-clear-button"></span>

      <SearchButton
        map={map}
        onClick={() => {
          if (checkKeywordSpace(value)) return;
          onClick(value);
        }}
      >
        <img
          className="search-input-icon"
          src={map ? SEARCHGRAY_ICON : SEARCH_ICON}
          alt="search icon"
        />
      </SearchButton>
    </Wrapper>
  );
};

export default SearchInput;

const Wrapper = styled.label<{
  map?: boolean;
  isValidated?: boolean;
  disabled?: boolean;
}>`
  ${FLEX.FlexBetweenCenter};
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  flex: 2;
  border: 1px solid #abb5c5;
  max-width: 100%;
  width: 100%;
  position: relative;

  ${({ isValidated }) =>
    isValidated &&
    `
    border: 1px solid #FF2E2E;
      background-color: #FFD5D5;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${COLORS.BUTTONS1};
  `}

  input {
    border: none;

    ${({ map, theme }) =>
      map && {
        backgroundColor: theme.COLORS.FILTERITEM,
        padding: "8px 10px",
        color: theme.COLORS.WHITE,
        lineHeight:1
      }}
    ::placeholder {
      ${({ map }) =>
        map && {
          color: "#ABB5C5",
        }}
    }

    ${mediaQuery.isMobile} {
      ${({ map }) =>
        map &&
        `
        padding: 10px;
      `}
    }
  }

  ${({ map, theme }) =>
    map && {
      width: "250px",
      maxWidth: "250px",
      backgroundColor: theme.COLORS.FILTERITEM,
      borderRadius: 4,
      overflow: "hidden",
      border: `1px solid #ABB5C5`,
      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    }}
`;

const SearchButton = styled.button<{ map?: boolean }>`
  ${FLEX.FlexCenterCenter};
  padding: 0 8px;

  > img {
    height: 18px;
  }
`;
