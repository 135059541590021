import {
  RecorderHealthDetailRow,
  RecorderHealthRow,
} from "api/interfaces/reportInterface.interface";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import WebAppLink from "components/blocks/link/WebAppLink";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { RecorderDetail } from "../HealthDetail";
import { Recorder } from "api/interfaces/recorderInterface.interface";

type Props = {
  items: Array<RecorderHealthDetailRow>;
  recorderInfo: RecorderDetail;
};

export default function HealthHeader(props: Props): JSX.Element {
  //BUG [RND-199] web/app link at health report
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return (
    <Container>
      {props.items.map((item, idx) => (
        <Item key={`health-header-${idx}`}>
          <span className="label">{item.label}</span>
          <span className="value">{item.value}</span>
        </Item>
      ))}
      {selectedAccount.accountLevel === LEVEL_TYPE.EU &&
        !selectedAccount.isHidden && (
          <Row>
            <WebAppLink
              type={props.recorderInfo.type}
              accountId={selectedAccount.accountId}
              recorderId={props.recorderInfo.recorderId}
              mergedSystemId={props.recorderInfo.mergedSystemId}
              cloudSystemId={props.recorderInfo.cloudSystemId}
            />
          </Row>
        )}
    </Container>
  );
}

const Container = styled.ul`
  border-radius: 5px;
  padding: 15px 20px;
  width: 100%;
  grid-area: header;

  ${mediaQuery.isDefault} {
    background-color: ${({ theme }) => theme.COLORS.PRIMARY3}50;
    ${FLEX.FlexStartStart};
    gap: 50px;
  }
  ${mediaQuery.isMobile} {
    background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 20px;
  }
`;

const Item = styled.li`
  ${FLEX.FlexStartStart};
  .label {
    font-weight: 500;
  }

  ${mediaQuery.isDefault} {
    gap: 8px;
    flex-direction: column;
    color: ${({ theme }) => theme.COLORS.WHITE};

    .label {
      color: ${({ theme }) => theme.COLORS.BUTTONS1};
    }
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    gap: 18px;
    color: #828b9b;
    .label {
      flex: 1;
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
    .value {
      flex: 2;
    }
  }
`;
const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 7px;
`;
