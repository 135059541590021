import { AxiosResponse } from "axios";
import { GetTechSupportListResponse, GetVideoGuideCategoryListResponse, GetVideoGuideGroupListResponse, GetVideoGuideListResponse, TechSupportSubmit, VideoGuideGroupDto, VideoGuideVideoDto } from "./interfaces/managerInterface.interface";
import { AxiosInstance } from "./axiosInstance";
import { CUDGroupResponse } from "./interfaces/userInterface.interface";
import { BasePagination, CommonResponse } from "./interfaces/commonInterface.interface";
import qs from "qs";

const VIDEO_API_PATH = "/help/videoGuide";
const TECH_API_PATH = "/help/techSupport";

export const getVideoGuideCategories = async (): Promise<GetVideoGuideCategoryListResponse> => {
    try {
      const { data }: AxiosResponse<GetVideoGuideCategoryListResponse> =
        await AxiosInstance.get(`${VIDEO_API_PATH}/getAllCategories`);
      // console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const getVideoGuideCategory = async ({
    category
  }: {
    category: string;
  }): Promise<GetVideoGuideGroupListResponse> => {
    try {
      const { data }: AxiosResponse<GetVideoGuideGroupListResponse> =
        await AxiosInstance.get(`${VIDEO_API_PATH}/getAllGroups/${category}`);
      // console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const getVideoGuideVideoList = async ({
    groupId
  }: {
    groupId: string;
  }): Promise<GetVideoGuideListResponse> => {
    try {
      const { data }: AxiosResponse<GetVideoGuideListResponse> =
        await AxiosInstance.get(`${VIDEO_API_PATH}/getAllByGroup/${groupId}`);
      // console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const createGuideGroup = async ({
    payload
  }: {
    payload: VideoGuideGroupDto;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.post(
        `${VIDEO_API_PATH}/addGroup`,
        payload
      );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const createGuideVideo = async ({
    payload
  }: {
    payload: VideoGuideVideoDto;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.post(
        `${VIDEO_API_PATH}/add`,
        payload
      );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const updateGuideGroup = async ({
    groupId,
    payload
  }: {
    groupId:string,
    payload: VideoGuideGroupDto;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.put(
        `${VIDEO_API_PATH}/updateGroup/${groupId}`,
        payload
      );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const updateGuideVideo = async ({
    videoId,
    payload
  }: {
    videoId : string;
    payload: VideoGuideVideoDto;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.put(
        `${VIDEO_API_PATH}/update/${videoId}`,
        payload
      );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const deleteGuideVideo = async ({
    videoId
  }: {
    videoId: string;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.delete(
        `${VIDEO_API_PATH}/delete/${videoId}`
      );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const deleteGuideGroup = async ({
    groupId
  }: {
    groupId: string;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.delete(
        `${VIDEO_API_PATH}/deleteGroup/${groupId}`
      );
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const getTechSupportList = async ({
    payload,
  }: {
    payload: BasePagination;
  }): Promise<GetTechSupportListResponse> => {
    try {
      const { data }: AxiosResponse<GetTechSupportListResponse> =
        await AxiosInstance.get(`${TECH_API_PATH}/getAll?${qs.stringify(
          payload, {
            skipNulls: true
          })}`
      );
      // console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const createTechSupport = async ({
    accountId,
    form
  }: {
    accountId:string;
    form: FormData;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.post(
        `${TECH_API_PATH}/submit/${accountId}`,
         form
      );
      // console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };

  export const updateTechSupport = async ({
    id,
    payload
  }: {
    id:string;
    payload: FormData;
  }): Promise<CUDGroupResponse> => {
    try {
      const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.put(
        `${TECH_API_PATH}/update/${id}`,
         payload
      );
      // console.log(data);
      return data;
    } catch (e) {
      console.error(e);
      throw new Error();
    }
  };