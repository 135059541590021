import { CustomError } from "api/interfaces/commonInterface.interface";
import * as s from "./LoginStyled";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import Input from "components/atoms/input/Input";
import { ButtonWrapper } from "components/templates/modal/ModalTemplateStyled";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { postLocalConnectCreate, postLocalConnectTest } from "api/recorderAPI";
import { LocalConnectionInfo } from "api/interfaces/recorderInterface.interface";
import { InputNumber } from "antd";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import { Text } from "components/atoms/text/Text";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { FaInfoCircle } from "react-icons/fa";
import DW_Spectrum_logo from "assets/images/dw_spectrum_logo.png";
import DW_Spectrum_logo_black from "assets/images/dw_spectrum_logo_b.png";
import { useAppSelector } from "redux/hooks";

interface Props {
  accountId: string;
  mergedSystemId: string;
  onClose: () => void;
  onClickSave: (connection: LocalConnectionInfo) => void;
}

export enum UPDATE_TYPE {
  HOST,
  PORT,
  USER,
  PWD,
}

export function SpectrumLogin(props: Props): JSX.Element {
  const intl = useIntl();
  const { handleError } = useApiError();
  const themeMode = useAppSelector((state) => state.theme.theme);

  const [isTestDone, setIsTestDone] = useState<boolean>(false);
  const [connection, setConnection] = useState<LocalConnectionInfo>({
    host: "",
    port: 7001,
    username: "",
    password: "",
  });

  const [isBubble, setIsBubble] = useState<boolean>(false);
  const [errors, setErrors] = useState({});

  const mutationConnectionTest = useMutation(postLocalConnectTest, {
    onSuccess: () => {
      setIsTestDone(true);
      notify(
        "success",
        intl.formatMessage({
          id: "label.recorder.notify.connectionTest.success",
          defaultMessage: "The connection test was successful.",
        })
      );
    },
    onError: (err: CustomError) => {
      setIsTestDone(false);
      handleError(
        err,
        intl.formatMessage({
          id: "label.recorder.notify.connectionTest.fail",
          defaultMessage: "The connection test fail.",
        })
      );
    },
  });

  const onClickTest = useCallback(() => {
    mutationConnectionTest.mutate({
      payload: connection,
    });
  }, [connection, mutationConnectionTest]);

  const onChangeConnectionInfo = useCallback(
    (type: UPDATE_TYPE, value: string | number) => {
      if (type === UPDATE_TYPE.HOST) {
        setConnection((info) => {
          return { ...info, host: value as string };
        });
      } else if (type === UPDATE_TYPE.PORT) {
        const newValue = value as number;
        if (newValue > 65535) {
          setConnection((info) => {
            return { ...info, port: 65535 as number };
          });
        } else {
          setConnection((info) => {
            return { ...info, port: value as number };
          });
        }
      } else if (type === UPDATE_TYPE.USER) {
        setConnection((info) => {
          return { ...info, username: value as string };
        });
      } else if (type === UPDATE_TYPE.PWD) {
        setConnection((info) => {
          return { ...info, password: value as string };
        });
      } else {
        //nothing
      }
    },
    []
  );

  const preCheckSave = () => {
    if (isTestDone) {
      props.onClickSave(connection);
    } else {
      alert("test먼저");
    }
  };

  const buttons = (
    <ButtonWrapper>
      <PrimaryButton
        label="Save"
        onClickBtn={preCheckSave}
        disabled={!isTestDone}
      />
      <PrimaryButton
        label="Cancel"
        buttonType="cancel"
        onClickBtn={props.onClose}
      />
    </ButtonWrapper>
  );

  return (
    <SmallPopupDefaultTemplate onModal={props.onClose} button={buttons}>
      <s.SpectrumWrapper>
        <s.SpeectrumLogo>
          <img
            src={
              themeMode === "light" ? DW_Spectrum_logo_black : DW_Spectrum_logo
            }
            alt="dw-spectrum-logo"
          />
        </s.SpeectrumLogo>
        <s.TitleWrapper>
          <Text fontSize={20} bold>
            Connect to Server
          </Text>
          <s.Bubble>
            <button
              className="bubble-parent"
              onMouseEnter={() => setIsBubble(true)}
              onMouseLeave={() => setIsBubble(false)}
            >
              <FaInfoCircle />
              {isBubble && (
                <s.BubbleWrapper>
                  <InfoBubble>
                    This system hasn't been registered with DW Spectrum Cloud.{" "}
                    <br />
                    Please provide your connection details to try accessing
                    locally.
                  </InfoBubble>
                </s.BubbleWrapper>
              )}
            </button>
          </s.Bubble>
        </s.TitleWrapper>
        <div className="row">
          <s.SpectrumInputWrapper>
            <span className="label">Host</span>
            <div className="value">
              <Input
                value={connection.host}
                onChange={(e) => {
                  onChangeConnectionInfo(
                    UPDATE_TYPE.HOST,
                    e.currentTarget.value
                  );
                }}
              />
            </div>
          </s.SpectrumInputWrapper>
          <s.SpectrumInputWrapper>
            <span className="label">Port</span>
            <div className="value">
              <Input
                type="number"
                value={connection.port}
                min={1}
                max={65535}
                onChange={(e) => {
                  onChangeConnectionInfo(
                    UPDATE_TYPE.PORT,
                    e.currentTarget.value
                  );
                }}
              />
            </div>
          </s.SpectrumInputWrapper>
        </div>
        <s.SpectrumInputWrapper>
          <span className="label">Login ID</span>
          <div className="value">
            <Input
              value={connection.username}
              onChange={(e) => {
                onChangeConnectionInfo(UPDATE_TYPE.USER, e.currentTarget.value);
              }}
            />
          </div>
        </s.SpectrumInputWrapper>
        <s.SpectrumInputWrapper>
          <span className="label">Password</span>
          <div className="value">
            <Input
              type="password"
              autoComplete="new-password"
              value={connection.password}
              onChange={(e) => {
                onChangeConnectionInfo(UPDATE_TYPE.PWD, e.currentTarget.value);
              }}
            />
            <PrimaryButton
              label="Test"
              buttonType="cancel"
              onClickBtn={onClickTest}
            />
          </div>
        </s.SpectrumInputWrapper>
      </s.SpectrumWrapper>
    </SmallPopupDefaultTemplate>
  );
}
