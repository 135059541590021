import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { COLORS } from "../../../styles/colors";

interface Props {
  color?: string;
  fontSize?: number;
  bold?: boolean;
  border?: number;
  center?: boolean;
  isDeleted?: boolean;
  isRowClickEditable?: boolean;
}

interface InputLabelProps {
  label: string;
  essential?: boolean;
  noBold?: boolean;
}

export const Text = styled.div<Props>`
  color: ${(props) =>
    props.isDeleted
      ? props.theme.COLORS.BUTTONS1
      : props.color
      ? props.color
      : props.theme.COLORS.WHITE};
  font-size: ${(props) =>
    props.fontSize ? calcRem(props.fontSize) : calcRem(16)};
  line-height: ${(props) =>
    props.fontSize ? calcRem(props.fontSize + 2) : calcRem(18)};
  white-space: nowrap;
  ${(props) =>
    props.bold
      ? `
      font-weight: 500;
    `
      : `
    font-weight: 300;
  
    `};
`;

export const UnderlineText = styled(Text)<Props & { disabled?: boolean }>`
  text-decoration: underline;
  ${FLEX.FlexStartCenter}
  gap: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${({ color, theme }) =>
    color ? color : theme.value === "light" ? COLORS.COMPANY : COLORS.SELECTED};

  ${({ disabled, theme }) =>
    disabled
      ? `
    cursor: default;
    color: ${COLORS.LIGHTGRAY};
  `
      : `
    cursor: pointer;

  `}
`;

export const CenterlineText = styled(Text)<Props>`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  align-items: center;

  :after {
    content: "";
    flex-grow: 1;
    margin: 0 10px;
    background: ${(props) => (props.color ? `${props.color}50` : COLORS.WHITE)};
    height: ${({ border }) => (border ? `${border}px` : "1px")};
    font-size: 0px;
    line-height: 0px;
  }

  ${(props) =>
    props.center &&
    `
      :before {
      content: "";
      flex-grow: 1;
      margin: 0 10px;
      background: ${props.color ? `${props.color}50` : COLORS.WHITE};
      height: ${props.border ? `${props.border}px` : "1px"};
      font-size: 0px;
      line-height: 0px;
    }
    `}
`;

const InputLabelBox = styled.label`
  ${FLEX.FlexStartCenter};
`;

const EssentialPoint = styled.span`
  color: ${COLORS.RED};
`;

export function InputLabel({
  label,
  essential,
  noBold,
}: InputLabelProps): JSX.Element {
  return (
    <InputLabelBox>
      <Text bold={!noBold} className="modal-input-label-title">
        {label}
      </Text>
      {essential && <EssentialPoint>*</EssentialPoint>}
    </InputLabelBox>
  );
}

const ValidateWordWrapper = styled.span`
  ${FLEX.FlexStartCenter};
  color: ${COLORS.VALIDATERED};
  padding-top: 10px;
`;

export function ValidateWord({ children }: any): JSX.Element {
  return (
    <ValidateWordWrapper className="validate-word">
      {children}
    </ValidateWordWrapper>
  );
}
