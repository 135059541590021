import {
  VideoShareUserListResult,
  VideoShareUserUpdate,
} from "api/interfaces/videoshareInterface.interface";
import { deleteUserVideoShare, updateUserVideoShare } from "api/videoshareAPI";
import { notify } from "components/atoms/notification/Notification";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import EditVSPresenter from "./EditVSPresenter";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { convertDateToStringOrigin, convertVideoShareTime, getNextDayString, setVideoShareExpire } from "utils/timeUtil";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";

type Props = {
  videoId: string;
  state: VideoShareUserListResult;
  onChangeModal: (type: string, value: boolean) => void;
};

export default function EditVSContainer({
  videoId,
  state,
  onChangeModal,
}: Props): JSX.Element {
  const intl = useIntl();
  const { handleError } = useApiError();
  
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isExpired, setIsExpired] = useState<boolean>(
    state.expires !== undefined ? true : false
  );

  const [newState, setNewState] = useState<VideoShareUserUpdate>({
    id: state.id,
    message: state.message,
    expires: state.expires !== undefined ? convertVideoShareTime(state.expires, selectedAccount.timezone) :getNextDayString(7),
    canDownload: state.canDownload,
    shareDate : state.shareDate
  });

  useEffect(()=>{
    if(state){
      setNewState({
        id: state.id,
        message: state.message,
        expires: state.expires !== undefined ? convertVideoShareTime(state.expires, selectedAccount.timezone) : getNextDayString(7),
        canDownload: state.canDownload,
        shareDate : state.shareDate
      })
    }
  },[state])

  const onChangeValues = (e: any) => {
    let {
      target: { name, value, checked },
    } = e;

    if (name === "isExpired") {
      setIsExpired(checked);
      return;
    }
    if (name === "canDownload") {
      setNewState({
        ...newState,
        [name]: checked,
      });
      return;
    }
    setNewState({
      ...newState,
      [name]: value,
    });
  };
  const onChangeDateValue = (date: Date) => {
    // console.log(date);
    const selectDate = convertDateToStringOrigin(date);
    setNewState({
      ...newState,
      expires: selectDate,
    });
  };

  const queryClient = useQueryClient();

  const mutationUpdateUser = useMutation(updateUserVideoShare, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.videoshare.notify.user.update.success",
        defaultMessage: "Shared information update successfully.",
      }));
      queryClient.invalidateQueries("videoshareViewUserList");
      onChangeModal("edit", false);
    },
    onError: (err:CustomError) => {
      handleError(err, intl.formatMessage({
        id: "label.videoshare.notify.user.update.fail",
        defaultMessage: "Fail to update Shared information.",
      }));
    },
  });

  const onFinish = () => {
    mutationUpdateUser.mutate({
      videoId: videoId,
      accountId: selectedAccount.accountId,
      payload: { ...newState, expires: isExpired ?setVideoShareExpire(newState.expires, selectedAccount.timezone): null },
    });
  };

  const mutationDeleteUser = useMutation(deleteUserVideoShare, {
    onSuccess: () => {
      notify("success", intl.formatMessage({
        id: "label.videoshare.notify.user.delete.success",
        defaultMessage: "Shared user deleted successfully.",
      }));
      queryClient.invalidateQueries("videoshareViewUserList");
      onChangeModal("edit", false);
    },
    onError: (err:CustomError) => {
      handleError(err, intl.formatMessage({
        id: "label.videoshare.notify.user.delete.fail",
        defaultMessage: "Failed to delete Shared user.",
      }));
    },
  });

  const onClickDelete = useCallback (()=>{
    mutationDeleteUser.mutate({
      userId: state.id,
    });
  },[mutationDeleteUser, state.id])

  return (
    <EditVSPresenter
      selectedAccount={selectedAccount}
      onChangeModal={onChangeModal}
      onChangeValues={onChangeValues}
      onChangeDateValue={onChangeDateValue}
      onFinish={onFinish}
      state={state}
      newState={newState}
      isExpired={isExpired}
      onClickDelete={onClickDelete}
    />
  );
}
