import { useIntl } from "react-intl";
import { VideoManagerPresenter } from "./VideoManagerPresenter";
import { useAuth } from "components/Auth";
import { useCallback, useRef, useState } from "react";
import {
  GetVideoGuideCategoryListResponse,
  GetVideoGuideListResponse,
  VideoGuideGroup,
  VideoGuideGroupDto,
  VideoGuideSetting,
  VideoGuideVideoDto,
} from "api/interfaces/managerInterface.interface";
import { TableColumn } from "react-data-table-component";
import * as s from "./VideoManagerStyled";
import TableButton from "components/atoms/buttons/TableButton";
import { useQuery } from "react-query";
import {
  createGuideGroup,
  createGuideVideo,
  getVideoGuideCategories,
  getVideoGuideVideoList,
  updateGuideGroup,
  updateGuideVideo,
} from "api/managerAPI";
import { Data } from "./tree/VideoTree";
import { notify } from "components/atoms/notification/Notification";
import { useMutation } from "react-query";
import { NodeApi } from "react-arborist";
import { isEmpty } from "lodash";

export enum VIDEO_GUIDE_TYPE {
  GROUP,
  VIDEO,
}

export interface SelectGroup {
  category: string;
  groupId: string;
  groupName: string;
}

export interface SelectedCategory {
  categoryId: string;
  categoryName: string;
}

export const categoryOrder = [
  "about",
  "account",
  "recorders",
  "report",
  "alert",
  "equipment",
];
export const categoryOrderStr = [
  "About myDW",
  "User/Organization",
  "Recorder",
  "Report",
  "Alert",
  "Supported Equipment",
];

export default function VideoManagerContainer(): JSX.Element {
  const intl = useIntl();
  const auth = useAuth();

  const treeRef = useRef(null);
  const [isGroupAddModal, setIsGroupAddModal] = useState(false);
  const [isGroupEditModal, setIsGroupEditModal] = useState(false);

  const [isVideoAddModal, setIsVideoAddModal] = useState(false);
  const [isVideoEditModal, setIsVideoEditModal] = useState(false);

  const [videoTreeData, setVideoTreeData] = useState<Data[]>([]);

  const [videoSettingList, setVideoSettingList] = useState<VideoGuideSetting[]>(
    []
  );
  const [selectedVideo , setSelectedVideo] = useState<VideoGuideSetting>();
  const [selectedCategory , setSelectedCategory] = useState<SelectedCategory>({
    categoryId: "",
    categoryName: ""
  });

  const onModal = (type: VIDEO_GUIDE_TYPE, isEdit: boolean) => {
    if (type === VIDEO_GUIDE_TYPE.GROUP) {
      if (!isEdit) {
        setIsGroupAddModal(!isGroupAddModal);
      }else{
        setIsGroupEditModal(!isGroupEditModal);
        categoryQuery.refetch();
      }
      //queryClient.invalidateQueries("alertRule");
    } else if (type === VIDEO_GUIDE_TYPE.VIDEO) {
      if (isEdit) {
        setIsVideoEditModal(!isVideoEditModal);
        videoListQuery.refetch();
      } else {
        if(!isEmpty(selectedGroup.groupId)){ 
          setIsVideoAddModal(!isVideoAddModal);
        }
      }
    }
  };

  const columns: TableColumn<VideoGuideSetting>[] = [
    {
      name: "Title",
      grow: 1,
      selector: (row) => row.title,
      center: true,
    },
    {
      name: "Thumbnail",
      grow: 1,
      width: "150px",
      // selector: (row) => row.thumbnailUrl,
      // center: true,
      cell: (row) => (
        <img style={{ width: 150 }} src={row.thumbnailUrl} alt={row.id} />
      ),
    },
    {
      name: "Video Url",
      grow: 1,
      selector: (row) => row.videoUrl,
      center: true,
    },
    {
      name: "Description",
      grow: 1,
      cell: (row) => row.description,
      center: true,
    },
    {
      name: "Order",
      grow: 1,
      cell: (row) => row.position,
      center: true,
    },
    // {
    //   name: "Control",
    //   grow: 0.5,
    //   cell: (row) => (
    //     <s.Row>
    //       <TableButton
    //         label={"UP"}
    //         onClickBtn={function (): void {
    //           throw new Error("Function not implemented.");
    //         }}
    //       />
    //       <TableButton
    //         label={"DOWN"}
    //         onClickBtn={function (): void {
    //           throw new Error("Function not implemented.");
    //         }}
    //       />
    //     </s.Row>
    //   ),
    // },
    {
      name: "",
      grow: 0.5,
      cell: (row) => (
        <s.Row>
          <TableButton
            label={"Edit"}
            onClickBtn={()=> {
              setSelectedVideo(row);
              onModal(VIDEO_GUIDE_TYPE.VIDEO, true);
            }}
          />
        </s.Row>
      ),
    },
  ];

  const convertGroup = (group: VideoGuideGroup[]) => {
    if (group !== undefined && group.length !== 0) {
      return group.map((data) => {
        return {
          id: data.groupId,
          name: data.name,
          position: data.position,
        };
      });
    } else {
    }
  };

  const convertCategoryTree = (res: GetVideoGuideCategoryListResponse) => {
    const totalList: Data[] = [];

    categoryOrder.forEach((value: string, index: number) => {
      const childModel = convertGroup(res.result[value]);
      const addModel =
        childModel !== undefined
          ? {
              id: value,
              name: categoryOrderStr[index],
              position: index + 1,
              children: childModel,
            }
          : {
              id: value,
              name: categoryOrderStr[index],
              position: index + 1,
            };
      if(index === 0){
        setSelectedCategory({
          categoryId : value,
          categoryName : categoryOrderStr[index]
        });
      }
      totalList.push(addModel);
    });
    return totalList;
  };

  const categoryQuery = useQuery(
    ["categories"],
    () => getVideoGuideCategories(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoGuideCategoryListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setVideoTreeData([]);
        }
        setVideoTreeData(convertCategoryTree(res));
      },
      onError: (e: any) => {
        setVideoTreeData([]);
      },
    }
  );

  const [selectedGroup, setSelectedGroup] = useState<SelectGroup>({
    category: "about",
    groupId: "",
    groupName: "",
  });

  const onClickGroup = (group: SelectGroup) => {
    setSelectedGroup(group);
  };

  const onClickEditGroup = (group: SelectGroup) => {
    setSelectedGroup(group);
    setIsGroupEditModal(true);
  }

  const videoListQuery = useQuery(
    ["videoList", selectedGroup],
    () =>
      getVideoGuideVideoList({
        groupId: selectedGroup.groupId as string,
      }),
    {
      retry: false,
      enabled: selectedGroup.groupId !== "",
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoGuideListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setVideoSettingList([]);
        }
        setVideoSettingList(res.result);
      },
      onError: (e: any) => {
        setVideoSettingList([]);
      },
    }
  );

  //Add Group
  const mutationCreateGroup = useMutation(createGuideGroup, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.manager.video.group.create.success",
          defaultMessage: "The video group add successfully.",
        })
      );
      categoryQuery.refetch();
      setIsGroupAddModal(false);
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.manager.video.group.create.fail",
          defaultMessage: "Fail to add video group.",
        })
      );
    },
  });

  const mutationUpdateGroup = useMutation(updateGuideGroup, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.manager.video.group.modify.success",
          defaultMessage: "The video group updated successfully.",
        })
      );
      categoryQuery.refetch();
      setIsGroupEditModal(false);
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.manager.video.group.modify.fail",
          defaultMessage: "Fail to update video group.",
        })
      );
    },
  });

  const onClickAddGroup = (
    groupDto: VideoGuideGroupDto,
    update?: boolean | undefined
  ) => {
    if (update) {
      mutationUpdateGroup.mutate({
        groupId: selectedGroup.groupId,
        payload: groupDto,
      });
    } else {
      mutationCreateGroup.mutate({
        payload: groupDto,
      });
    }
  };

  const mutationCreateVideo = useMutation(createGuideVideo, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.manager.video.create.success",
          defaultMessage: "The video add successfully.",
        })
      );
      videoListQuery.refetch();
      setIsVideoAddModal(false);
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.manager.video.create.fail",
          defaultMessage: "Fail to add video.",
        })
      );
    },
  });
  const mutationUpdateVideo = useMutation(updateGuideVideo, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.manager.video.modify.success",
          defaultMessage: "The video  updated successfully.",
        })
      );
      videoListQuery.refetch();
      setIsVideoEditModal(false);
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.manager.video.modify.fail",
          defaultMessage: "Fail to update video.",
        })
      );
    },
  });

  const onClickAddVideo = (
    videoDto: VideoGuideVideoDto,
    update?: boolean | undefined
  ) => {
    if (update) {
      mutationUpdateVideo.mutate({
        videoId: videoDto.id,
        payload: videoDto,
      });
    } else {
      mutationCreateVideo.mutate({
        payload: videoDto,
      });
    }
  };
  const onClickCategory = useCallback((category:SelectedCategory)=> {
    setSelectedCategory(category);
  },[])


  return (
    <VideoManagerPresenter
      data={videoSettingList}
      treeData={videoTreeData}
      columns={columns}
      onModal={onModal}
      isGroupAddModal={isGroupAddModal}
      isGroupEditModal={isGroupEditModal}
      isVideoAddModal={isVideoAddModal}
      isVideoEditModal={isVideoEditModal}
      treeRef={treeRef}
      selectedCategory={selectedCategory}
      selectGroup={selectedGroup}
      selectedVideo={selectedVideo}
      onClickCategory={onClickCategory}
      onClickGroup={onClickGroup}
      onClickEditGroup={onClickEditGroup}
      onClickAddGroup={onClickAddGroup}
      onClickAddVideo={onClickAddVideo}
    />
  );
}
