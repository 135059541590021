import * as s from "./VideoGuideStyled";
import Header from "./VideoGuidesHeader";
import { useState, useEffect, useRef } from "react";
import { CenterlineText, Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import CHEVRON_LEFT from "assets/icons/chevron_left_slide.svg";
import CHEVRON_RIGHT from "assets/icons/chevron_right_slide.svg";
import VideoGuidesPlay from "./VideoGuidesPlay";
import { ImageLabel } from "pages/videoshare/VideoShareStyled";
import * as mediaQuery from "components/MediaQuery";
import { useQuery } from "react-query";
import { getVideoGuideCategory } from "api/managerAPI";
import {
  GetVideoGuideGroupListResponse,
  VideoGuideGroup,
} from "api/interfaces/managerInterface.interface";
import NoData from "components/templates/nopage/NoData";
import { getIDfromYoutubeUrl } from "utils/functions";

type Props = {};

export default function VideoGuides(): JSX.Element {
  const modalRef = useRef<any>(null);
  const horizontalScrollRef = useRef<any>(null);
  const [isCurrentMenu, setIsCurrentMenu] = useState<string>("about");
  const [isModal, setIsModal] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>("");

  const handleNextButtonClick = (nextType: "prev" | "next") => {
    if (!horizontalScrollRef.current) return;
    if (nextType === "prev") {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft -
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    } else {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft +
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const onClickMenu = (index: string) => {
    setIsCurrentMenu(index);
  };

  const onChangeModal = (id?: string) => {
    if (id) {
      const youtubeId = getIDfromYoutubeUrl(id);
      if (youtubeId !== "") {
        setVideoId(id);
        setIsModal(true);
      }
    } else {
      setIsModal(false);
      setVideoId("");
    }
  };

  const [guideVideo, setGuideVideo] = useState<VideoGuideGroup[]>([]);
  const getVideoGuideListInfo = useQuery(
    ["videoGuideList", isCurrentMenu],
    () =>
      getVideoGuideCategory({
        category: isCurrentMenu,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetVideoGuideGroupListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setGuideVideo([]);
        }
        setGuideVideo(res.result);
      },
      onError: (e: any) => {
        setGuideVideo([]);
      },
    }
  );

  return (
    <>
      <s.Container>
        <Header isCurrentMenu={isCurrentMenu} onClickMenu={onClickMenu} />
        <s.VideoContainer>
          {guideVideo?.map((item, index) => (
            <div>
              <CenterlineText color={COLORS.BUTTONS1} bold fontSize={20}>
                {item.name}
              </CenterlineText>
              <s.VideoDiv>
                <mediaQuery.Default>
                  <button
                    className="button-left"
                    onClick={() => handleNextButtonClick("prev")}
                  >
                    <img src={CHEVRON_LEFT} />
                  </button>
                </mediaQuery.Default>
                <s.VideoWrapper ref={horizontalScrollRef}>
                  {item.videoGuideList?.map((video, index) => (
                    <s.VideoItem
                      key={index}
                      onClick={() => onChangeModal(video.videoUrl)}
                    >
                      <s.VideoThumbnail>
                        {/* <ImageLabel time>02:04</ImageLabel> */}
                        <img src={video.thumbnailUrl} alt="youtube thumbnail" />
                      </s.VideoThumbnail>
                      <Text className="title" bold>
                        {video.title}
                      </Text>
                      <Text color={COLORS.BUTTONS1} fontSize={14}>
                        {video.description}
                      </Text>
                    </s.VideoItem>
                  ))}
                </s.VideoWrapper>
                <mediaQuery.Default>
                  <button
                    className="button-right"
                    onClick={() => handleNextButtonClick("next")}
                  >
                    <img src={CHEVRON_RIGHT} />
                  </button>
                </mediaQuery.Default>
              </s.VideoDiv>
            </div>
          ))}
        </s.VideoContainer>
      </s.Container>
      {/* 왜 두번 렌더링 되는지 모르겠음 */}
      {isModal && (
        <VideoGuidesPlay videoId={videoId} onChangeModal={onChangeModal} />
      )}
    </>
  );
}
