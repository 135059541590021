/**
 * @author ydoni
 * @date 23.01.09
 * @desctription alert history list 조회
 */

import { getAlertHistoryList, getAlertHistoryMergedList } from "api/alertAPI";
import {
  AlertDetail,
  AlertHistoryList,
} from "api/interfaces/alertInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import AlertHistoryPresenter from "./AlertHistoryPresenter";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { UnderlineText } from "components/atoms/text/Text";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import TableButton from "components/atoms/buttons/TableButton";
import * as s from "./../AlertStyled";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import Type from "components/atoms/text/labels/Type";
import * as mediaQuery from "components/MediaQuery";
import DetailButton from "components/atoms/buttons/detail/DetailButton";
import { notify } from "components/atoms/notification/Notification";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { AlertRecorerTypeFilter } from "utils/AlertUtil";
import { convertType } from "utils/functions";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";

// type Props = {
//   onModal: (type?: string) => void;
// };

interface HistoryStateProps {
  systemName: string;
  mergedSystemId: string;
}

export default function AlertHistoryContainer(): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { mergedSystemId } = useParams();
  const isDetail: boolean = location.pathname.includes("merged");
  const themeMode = useAppSelector((state) => state.theme.theme);

  const [isPending, setIsPending] = useState<boolean>(true);

  // const [historyStateProps, setHistoryStateProps] = useState<HistoryStateProps>(
  //   {
  //     systemName: location.state !== null ? location.state.systemName : "",
  //     mergedSystemId:
  //       location.state !== null ? location.state.mergedSystemId : "",
  //   }
  // );
  // const mergedSystemId = location.state ? location.state.mergedSystemId : "";
  // const systemName = location.state ? location.state.systemName : "";

  useEffect(() => {
    // if (location.state) {
    //   setHistoryStateProps({
    //     systemName: location.state !== null ? location.state.systemName : "",
    //     mergedSystemId:
    //       location.state !== null ? location.state.mergedSystemId : "",
    //   });
    // }

    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      if (!isDetail) {
        setAlertHistoryRuleQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 20
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("sortType"))
              ? "mergedSystemName"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
            type: isNull(changeParam.get("type"))
              ? null
              : (changeParam.get("type") as string),
          };
        });
      } else {
        setAlertHistoryMergedQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 20
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("sortType"))
              ? "name"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
          };
        });
      }
    } else {
      if (!isDetail) {
        setAlertHistoryRuleQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 20,
            keyword: "",
            sortType: "mergedSystemName",
            sortDirection: SORT_DIRECTION.ASC,
            type: null,
          };
        });
      } else {
        setAlertHistoryMergedQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 20,
            keyword: "",
            sortType: "name",
            sortDirection: SORT_DIRECTION.ASC,
          };
        });
      }
    }
  }, [location]);

  const [data, setData] = useState<AlertHistoryList[]>([]);
  const [listTotal, setListTotal] = useState<number>(0);
  const [alertTotal, setAlertTotal] = useState<number>(0);

  const [systemTitle, setSystemTitle] = useState<string>("");

  const titleComponent = isDetail
    ? [
        {
          label: "Alert History",
          onClick: () => navigate("/alert/history"),
        },
        {
          label: systemTitle,
        },
      ]
    : "Alert History";

  const [alertDetail, setAlertDetail] = useState<AlertDetail>({
    system: 0,
    disk: 0,
    video: 0,
  });

  const detailItems = [
    {
      name: "system",
      value: alertDetail.system,
      label: "System",
    },
    {
      name: "storage",
      value: alertDetail.disk,
      label: "Disk",
    },
    {
      name: "video",
      value: alertDetail.video,
      label: "Video",
    },
  ];
  const detailStatus = {
    totalTitle: "Total Active Alerts",
    totalValue: alertTotal,
    items: [
      <DetailButton
        title="Alert Quantities by category"
        contents={detailItems}
      />,
    ],
  };

  const [alertHistoryRuleQueryInfo, setAlertHistoryRuleQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "mergedSystemName",
      sortDirection: SORT_DIRECTION.ASC,
      type: null,
    });

  const onClickAlertRecorderType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: alertHistoryRuleQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryRuleQueryInfo.keyword as string,
              sortType: alertHistoryRuleQueryInfo.sortType,
              sortDirection:
                alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
              type: null,
            },
            selectedAccount
          );
          // setAlertHistoryRuleQueryInfo((info) => {
          //   return { ...info, type: null, pageNum: 0 };
          // });
        } else {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: alertHistoryRuleQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryRuleQueryInfo.keyword as string,
              sortType: alertHistoryRuleQueryInfo.sortType,
              sortDirection:
                alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
              type: value,
            },
            selectedAccount
          );
          // setAlertHistoryRuleQueryInfo((info) => {
          //   return { ...info, type: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertHistoryRuleQueryInfo.keyword,
      alertHistoryRuleQueryInfo.pageLimit,
      alertHistoryRuleQueryInfo.sortDirection,
      alertHistoryRuleQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  // alert history
  const columns: TableColumn<AlertHistoryList>[] = isDetail
    ? [
        {
          name: "Type",
          grow: 0,

          minWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
          style: {
            paddingRight: 0,
          },
        },
        {
          name: "Recorder Title",
          grow: 2,
          // minWidth: "160px",
          selector: (row) => row.recorderName,
          sortable: true,
          sortField: "name",
          style: {
            // paddingLeft: 10,
          },
        },
        {
          name: "System",
          grow: 1,
          maxWidth: "150px",
          selector: (row) =>
            row.systemCount !== undefined ? row.systemCount : 0,
          center: true,
          hide: Media.SM,
        },
        {
          name: "Disk",
          grow: 1,
          maxWidth: "150px",
          selector: (row) => (row.diskCount !== undefined ? row.diskCount : 0),
          center: true,
          hide: Media.SM,
        },
        {
          name: "Video",
          grow: 1,
          maxWidth: "150px",
          selector: (row) =>
            row.videoCount !== undefined ? row.videoCount : 0,
          center: true,
          hide: Media.SM,
        },
        {
          name: "Last Alert",
          grow: 2,
          sortable: true,
          selector: (row) =>
            row.date !== undefined
              ? getRecorderTimeFormat(
                  row.date,
                  selectedAccount,
                  row.timezone,
                  true
                )
              : "",
          hide: Media.SM,
        },
        {
          name: "",
          minWidth: "180px",
          cell: (row) => (
            <span className="row-click-button">
              <TableButton
                label="View"
                onClickBtn={() => onRowClick(row)}
                // disabled={
                //   row.systemCount + row.diskCount + row.videoCount === 0
                //     ? true
                //     : false
                // }
              />
            </span>
          ),
          right: true,
        },
      ]
    : [
        {
          name: (
            <TableColumnFilterTitle
              title="Type"
              filters={AlertRecorerTypeFilter}
              onClick={onClickAlertRecorderType}
            />
          ),
          grow: 1,
          minWidth: "110px",
          // maxWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "mergedSystemName",
          name: "Recorder Title",
          grow: 2,
          cell: (row) => (
            <RecorderTitle
              mergedSystemName={row.name}
              recorderName={row.recorderName}
            />
          ),
          //row.type === "Spectrum" ? row.name : row.recorderName,
          sortable: true,
          sortField: "mergedSystemName",
        },
        {
          name: "",
          grow: 1,
          cell: (row) => (
            <s.Row>
              {row.recorderCount > 1 ? (
                <UnderlineText
                  color={
                    themeMode === "light" ? COLORS.BUTTONS2 : COLORS.SELECTED
                  }
                  fontSize={14}
                  onClick={() => OnClickMerged(row)}
                >
                  Recorders
                  <mediaQuery.Default>: {row.recorderCount}</mediaQuery.Default>
                </UnderlineText>
              ) : (
                ""
              )}
            </s.Row>
          ),
          right: true,
          hide: Media.SM,
        },
        {
          name: "System",
          grow: 1,
          // maxWidth: "50px",
          selector: (row) =>
            row.systemCount !== undefined ? row.systemCount : 0,
          center: true,
          hide: Media.SM,
        },
        {
          name: "Disk",

          grow: 1,
          // maxWidth: "50px",
          selector: (row) => (row.diskCount !== undefined ? row.diskCount : 0),
          center: true,
          hide: Media.SM,
        },
        {
          name: "Video",
          grow: 1,
          // maxWidth: "50px",
          selector: (row) =>
            row.videoCount !== undefined ? row.videoCount : 0,
          center: true,
          hide: Media.SM,
        },
        {
          name: "Last Alert",
          grow: 2,
          // sortable: true,
          // sortField: "date",
          selector: (row) =>
            row.date !== undefined
              ? getRecorderTimeFormat(
                  row.date,
                  selectedAccount,
                  row.timezone,
                  true
                )
              : "",
          hide: Media.SM,
        },
        // 마지막 버튼
        {
          name: "",
          minWidth: "150px",
          grow: Media.SM ? 1 : 5,
          cell: (row) => (
            <span className="row-click-button">
              {row.recorderCount === 1 ? (
                <TableButton
                  label="View"
                  onClickBtn={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                />
              ) : null}
            </span>
          ),
          right: true,
        },
      ];

  const mobileColumns: TableColumn<AlertHistoryList>[] = isDetail
    ? [
        {
          name: "Type",
          grow: 0,

          minWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
          style: {
            paddingRight: 0,
          },
        },
        {
          name: "Recorder Title",
          grow: 2,
          // minWidth: "160px",
          selector: (row) => row.recorderName,
          sortable: true,
          sortField: "name",
          style: {
            // paddingLeft: 10,
          },
        },

        {
          name: "",
          minWidth: "0",
          cell: (row) => (
            <span className="row-click-button">
              <TableButton
                label="View"
                onClickBtn={() => onRowClick(row)}
                // disabled={
                //   row.systemCount + row.diskCount + row.videoCount === 0
                //     ? true
                //     : false
                // }
              />
            </span>
          ),
          right: true,
        },
      ]
    : [
        {
          name: (
            <TableColumnFilterTitle
              title="Type"
              filters={AlertRecorerTypeFilter}
              onClick={onClickAlertRecorderType}
            />
          ),
          grow: 1,
          minWidth: "110px",
          // maxWidth: "110px",
          cell: (row) => <Type type={convertType(row.type)} />,
        },
        {
          id: "mergedSystemName",
          name: "Recorder Title",
          grow: 2,
          selector: (row) =>
            row.type === "Spectrum" ? row.name : row.recorderName,
          sortable: true,
          sortField: "mergedSystemName",
        },

        // 마지막 버튼
        {
          name: "",
          minWidth: "0",

          cell: (row) => (
            <span className="row-click-button">
              {row.recorderCount === 1 ? (
                <TableButton
                  label="View"
                  onClickBtn={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                />
              ) : null}
            </span>
          ),
          right: true,
        },
      ];

  const OnClickMerged = (row: AlertHistoryList) => {
    navigate({
      pathname: `/alert/history/merged/${row.mergedSystemId}`,
      search: `?${createSearchParams({
        account: selectedAccount.accountId,
      })}`,
    });
  };

  const onRowClick = (row: AlertHistoryList) => {
    if (row.recorderCount !== undefined && row.recorderCount !== 1) {
      //BUG [RND-455] Row Click시 빈화면 출력
      navigate({
        pathname: `/alert/history/merged/${row.mergedSystemId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    } else {
      navigate({
        pathname: `/alert/history/detail/${row.recorderId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    }
  };

  const alertHistoryQuery = useQuery(
    [
      "alertHistoryList",
      alertHistoryRuleQueryInfo,
      isDetail,
      selectedAccount.accountId,
    ],
    () =>
      getAlertHistoryList({
        accountId: selectedAccount.accountId,
        payload: alertHistoryRuleQueryInfo,
      }),
    {
      retry: 0,
      enabled: !isDetail && selectedAccount.accountId !== "",
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setAlertTotal(0);
          setAlertDetail({
            system: 0,
            disk: 0,
            video: 0,
          });
          setListTotal(0);
        } else {
          setAlertTotal(res.result.total);
          setAlertDetail({
            system:
              res.result.systemCount !== undefined ? res.result.systemCount : 0,
            disk: res.result.diskCount !== undefined ? res.result.diskCount : 0,
            video:
              res.result.videoCount !== undefined ? res.result.videoCount : 0,
          });
          setData(res.result.alertRule);
          setListTotal(res.page.total);
        }
      },
      onError: (e: any) => {
        setData([]);
        setAlertTotal(0);
        setAlertDetail({
          system: 0,
          disk: 0,
          video: 0,
        });
        setListTotal(0);
        if (e.response?.data !== undefined) {
          if (e.response?.data.error !== 5001) {
            notify(
              "error",
              intl.formatMessage({
                id: "label.alertHistory.notify.search.fail",
                defaultMessage: "Alert History search error",
              })
            );
          }
        }
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const [alertHistoryMergedQueryInfo, setAlertHistoryMergedQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

  const alertHistoryMergedQuery = useQuery(
    [
      "alertHistoryMerged",
      alertHistoryMergedQueryInfo,
      isDetail,
      mergedSystemId,
    ],
    () =>
      getAlertHistoryMergedList({
        accountId: selectedAccount.accountId,
        systemId: mergedSystemId as string,
        payload: alertHistoryMergedQueryInfo,
      }),
    {
      retry: 0,
      enabled: isDetail,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 && res.result === undefined) {
          setSystemTitle("");
          setData([]);
          setAlertTotal(0);
          setAlertDetail({
            system: 0,
            disk: 0,
            video: 0,
          });
          setListTotal(0);
        } else {
          setAlertTotal(res.result.total);
          setSystemTitle(res.result.mergedSystemName);
          setAlertDetail({
            system:
              res.result.systemCount !== undefined ? res.result.systemCount : 0,
            disk: res.result.diskCount !== undefined ? res.result.diskCount : 0,
            video:
              res.result.videoCount !== undefined ? res.result.videoCount : 0,
          });
          setData(res.result.alertRule);
          setListTotal(res.page.total);
        }
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.alertHistory.notify.subSystem.fail",
            defaultMessage: "Recorder search error",
          })
        );
        setData([]);
        setAlertTotal(0);
        setAlertDetail({
          system: 0,
          disk: 0,
          video: 0,
        });
        setListTotal(0);
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (!isDetail) {
        if(alertHistoryRuleQueryInfo.keyword !== keyword){
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: alertHistoryRuleQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: alertHistoryRuleQueryInfo.sortType,
              sortDirection:
                alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
              type: alertHistoryRuleQueryInfo.type,
            },
            selectedAccount
          );
        }else{
          alertHistoryQuery.refetch();
        }
        // setAlertHistoryMergedQueryInfo((query) => {
        //   return {
        //     ...query,
        //     keyword,
        //   };
        // });
        // alertHistoryMergedQuery.refetch();
      } else {
        if(alertHistoryMergedQueryInfo.keyword !== keyword){
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: alertHistoryMergedQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: alertHistoryMergedQueryInfo.sortType,
              sortDirection:
                alertHistoryMergedQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        }else{
          alertHistoryMergedQuery.refetch();
        }
        // setAlertHistoryRuleQueryInfo((query) => {
        //   return {
        //     ...query,
        //     keyword,
        //   };
        // });
        // alertHistoryQuery.refetch();
      }
      setIsPending(true);
    },
    [alertHistoryMergedQuery, alertHistoryMergedQueryInfo.keyword, alertHistoryMergedQueryInfo.pageLimit, alertHistoryMergedQueryInfo.sortDirection, alertHistoryMergedQueryInfo.sortType, alertHistoryQuery, alertHistoryRuleQueryInfo.keyword, alertHistoryRuleQueryInfo.pageLimit, alertHistoryRuleQueryInfo.sortDirection, alertHistoryRuleQueryInfo.sortType, alertHistoryRuleQueryInfo.type, isDetail, location.pathname, navigate, selectedAccount]
  );

  const onChangePage = (page: number, totalRows: number) => {
    if (isDetail) {
      if (page !== alertHistoryMergedQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertHistoryMergedQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: alertHistoryMergedQueryInfo.keyword as string,
            sortType: alertHistoryMergedQueryInfo.sortType,
            sortDirection:
              alertHistoryMergedQueryInfo.sortDirection as SORT_DIRECTION,
            type: alertHistoryRuleQueryInfo.type,
          },
          selectedAccount
        );
        setIsPending(true);
        // setAlertHistoryMergedQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    } else {
      if (page !== alertHistoryRuleQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertHistoryRuleQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: alertHistoryRuleQueryInfo.keyword as string,
            sortType: alertHistoryRuleQueryInfo.sortType,
            sortDirection:
              alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setIsPending(true);
        // setAlertHistoryRuleQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        //});
      }
    }
   
  };

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      if (isDetail) {
        setAlertHistoryMergedQueryInfo((info) => {
          return {
            ...info,
            sortType: selectedColumn.sortField as string,
            sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
          };
        });
        //setIsPending(true);
      } else {
        setAlertHistoryRuleQueryInfo((info) => {
          return {
            ...info,
            sortType: selectedColumn.sortField as string,
            sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
          };
        });
        //setIsPending(true);
      }
    }
  };

  return (
    <AlertHistoryPresenter
      data={data}
      columns={columns}
      mobileColumns={mobileColumns}
      titleComponent={titleComponent}
      onRowClick={onRowClick}
      status={detailStatus}
      onChangePage={onChangePage}
      handleSort={handleSort}
      paginationPerPage={
        isDetail
          ? alertHistoryMergedQueryInfo.pageLimit
          : alertHistoryRuleQueryInfo.pageLimit
      }
      paginationInfo={
        isDetail ? alertHistoryMergedQueryInfo : alertHistoryRuleQueryInfo
      }
      paginationTotalRows={listTotal}
      onClickSearch={onClickSearch}
      isPending={isPending}
    />
  );
}
