import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { DataRow } from "./ListContainer";
import AddListModal from "../add/list";
import AddListModal2 from "../edit/list";
import Status, {
  StatusName,
  StatusType,
  StatusValue,
} from "components/blocks/status/Status";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Text, UnderlineText } from "components/atoms/text/Text";
import * as s from "../RecordersStyled";
import { COLORS } from "styles/colors";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/license/LicenseStyled";
import { LicenseCount } from "api/interfaces/recorderInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { changeToTrialType } from "utils/AccountUtil";
import * as mediaQuery from "components/MediaQuery";
import WebAppLink from "components/blocks/link/WebAppLink";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { menuNavigation } from "utils/MenuUtil";

type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  isLicenseModal: boolean;
  isRemoveLicenseModal: boolean;
  isBubble: boolean;
  licenseCount: LicenseCount;
  setIsBubble: any;
  onModal: (type: string) => void;
  columns: TableColumn<DataRow>[];
  mobileColumns: TableColumn<DataRow>[];
  data: Array<DataRow>;
  status: StatusType;
  isDetail: boolean;
  selectRow: DataRow;
  paginationPerPage: number | undefined;
  paginationInfo: BasePagination;
  totalRows: number | undefined;
  onChangePage: (page: number, totalRows: number) => void;
  onClickEdit: (row: DataRow) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  onClickSearch: ClickSearch;
  selectedMergedSystemId: string;
  selectedMergedSystemName: string;
  selectedMergedSystemVersion: string;
  selectedCloudSystemId: string;
  // onClickWebLink: (row: DataRow) => void;
  // onClickAppLink: (row: DataRow) => void;
  isRowClickEditable: boolean;
  initialPending: boolean;
  selectedAccount: ProfileAccountInfo;
};

export function RecordersPresenter({
  isAddModal,
  isEditModal,
  isBubble,
  licenseCount,
  setIsBubble,
  onModal,
  columns,
  mobileColumns,
  data,
  status,
  isDetail,
  selectRow,
  paginationPerPage,
  paginationInfo,
  totalRows,
  onChangePage,
  onClickEdit,
  handleSort,
  onClickSearch,
  selectedMergedSystemId,
  selectedMergedSystemName,
  selectedMergedSystemVersion,
  selectedCloudSystemId,
  // onClickWebLink,
  // onClickAppLink,
  isRowClickEditable,
  initialPending,
  selectedAccount,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const centerChildren = (
    <>
      <Status status={status} />{" "}
      <mediaQuery.Default>
        {isDetail && data.length > 0 && (
          <s.Row>
            <WebAppLink
              type={data[0].type}
              accountId={selectedAccount.accountId}
              recorderId={data[0].recordId}
              mergedSystemId={selectedMergedSystemId}
              cloudSystemId={selectedCloudSystemId}
            />
          </s.Row>
        )}
      </mediaQuery.Default>
    </>
  );

  const titleComponent = isDetail
    ? [
        {
          label: "Recorder List",
          onClick: () =>
            menuNavigation(selectedAccount, navigate, "/recorders/list"),
        },
        {
          label: (
            <s.SubTitleWrapper>
              <Text fontSize={24} id="selected-merged-name">
                {selectedMergedSystemName}
              </Text>
              <mediaQuery.Default>
                <Text fontSize={12} color="#828B9B">
                  [Ver.{selectedMergedSystemVersion}]
                </Text>
              </mediaQuery.Default>
            </s.SubTitleWrapper>
          ),
        },
      ]
    : "Recorder List";

  const licenseInfo = (
    <s.LicenseInfo>
      {/*
      isTrial === true && traialDays > 0 && license > 0 일경우에는 안보여줌
      trial 기간에 license 구매하면 안보여줌
      */}
      {!(
        selectedAccount.isTrial &&
        selectedAccount.trialDays !== undefined &&
        selectedAccount.trialDays > 0 &&
        licenseCount.totalLicensedCameraCount > 0
      ) && (
        <li className="trial-type">
          {changeToTrialType(selectedAccount)}
          {/* trial 일때 trial 기간 보여주기 */}
          {changeToTrialType(selectedAccount) === "Trial" &&
            selectedAccount.trialDays !== undefined &&
            selectedAccount.trialDays > 0 &&
            ` ${selectedAccount.trialDays} day(s)`}
        </li>
      )}

      <s.LicenseInfoItem
        isLicense={selectedAccount.isAdmin}
        onClick={() => {
          if (selectedAccount.isAdmin) {
            menuNavigation(selectedAccount, navigate, "/settings/license");
            // navigate("/settings/license", {
            //   state: { accountId: selectedAccount.accountId },
            // });
            // dispatch(setMenu("Settings"));
          }
        }}
      >
        <StatusName className="license-title">Licensed&nbsp;&nbsp;</StatusName>
        <StatusValue
          color={
            licenseCount.totalLicensedCameraCount <
            licenseCount.totalCameraCount
              ? COLORS.RED
              : COLORS.BUTTONS2
          }
        >
          {licenseCount.totalLicensedCameraCount}
        </StatusValue>
      </s.LicenseInfoItem>
      <s.LicenseInfoItem>/</s.LicenseInfoItem>
      <s.LicenseInfoItem>
        <StatusName className="license-title">Cameras&nbsp;&nbsp;</StatusName>
        <StatusValue>{licenseCount.totalCameraCount}</StatusValue>
      </s.LicenseInfoItem>
      {licenseCount.totalLicensedCameraCount <
        licenseCount.totalCameraCount && (
        <button
          style={{ position: "relative" }}
          onMouseOver={() => setIsBubble(true)}
          onMouseLeave={() => setIsBubble(false)}
        >
          <FaInfoCircle />
          {isBubble && (
            <BubbleContainer right>
              <InfoBubble right>
                You do not have enough camera channel licenses. <br />
                You need{" "}
                {licenseCount.totalCameraCount -
                  licenseCount.totalLicensedCameraCount}{" "}
                more to continue
                <br />
                using license exclusive features on myDW.
              </InfoBubble>
            </BubbleContainer>
          )}
        </button>
      )}
    </s.LicenseInfo>
  );
  return (
    <>
      <TableTemplate
        titleComponent={titleComponent}
        // onClickBtn={() => onModal("add")}
        // buttonLabel="Add Recorder"
        centerChildren={centerChildren}
        licenseInfo={licenseInfo}
        detail={isDetail}
        keyword={paginationInfo.keyword}
        onClickSearch={onClickSearch}
        requiredPermissions="manageRecordersAndGroups"
      >
        <mediaQuery.Default>
          <Table
            columns={columns}
            data={data}
            paginationPerPage={paginationPerPage}
            paginationTotalRows={totalRows}
            onChangePage={onChangePage}
            onRowClicked={onClickEdit}
            handleSort={handleSort}
            defaultSortFieldId="title"
            isRowClickEditable={isRowClickEditable}
            progressPending={initialPending}
          />
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          <Table
            columns={mobileColumns}
            data={data}
            paginationPerPage={paginationPerPage}
            paginationTotalRows={totalRows}
            onChangePage={onChangePage}
            onRowClicked={onClickEdit}
            handleSort={handleSort}
            defaultSortFieldId="title"
            isRowClickEditable={isRowClickEditable}
            progressPending={initialPending}
          />
        </mediaQuery.Mobile>
      </TableTemplate>
      {isAddModal && <AddListModal type="add" onModal={() => onModal("add")} />}
      {isEditModal && (
        <AddListModal2
          type="edit"
          selectRow={selectRow}
          onModal={() => onModal("edit")}
        />
      )}
    </>
  );
}
