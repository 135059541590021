import * as mediaQuery from "components/MediaQuery";
import { Text, UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import * as s from "../FilterStyled";
import { useCallback, useEffect, useRef, useState } from "react";
import TinyButton from "components/atoms/buttons/TinyButton";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  MapFilter,
  MapFilterKeyword,
  mapFilterInitialState,
  setAlerts,
} from "redux/reducers/map/mapFilter";
import { DashboardFilterAlert } from "api/interfaces/dashboardInterface.interface";
import { useQueryClient } from "react-query";
import { Tree, TreeDataNode } from "antd";
import { TreeProps } from "antd/lib";

type Props = {
  onApply: () => void;
  onChangeFiltering: (name: string, value: boolean) => void;
  onChangeFilterName: (changeFilterName: string) => void;
};

const AlertFilter = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const alertTypeFilter: DashboardFilterAlert = useAppSelector(
    (state) => state.mapFilter.filters.alerts
  );
  const filterKeyword: MapFilterKeyword = useAppSelector(
    (state) => state.mapFilter.keyword
  );
  const themeMode = useAppSelector((state) => state.theme.theme);

  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);

  const alertTypes: TreeDataNode[] = [
    {
      key: "systemAlerts",
      title: "System Alerts",
      children: [
        { title: "Not Responding", key: "systemAlerts:notResponding" },
        { title: "Abnormal Restart", key: "systemAlerts:abnormalRestart" },
        { title: "System Overheated", key: "systemAlerts:systemOverheated" },
      ],
    },
    {
      key: "diskAlerts",
      title: "Disk Alerts",
      children: [
        { title: "Hardware Failure", key: "diskAlerts:hardwareFailure" },
        { title: "Disk Overheated", key: "diskAlerts:diskOverheated" },
        { title: "BAD Sector", key: "diskAlerts:badSector" },
        { title: "Storage Status Check", key: "diskAlerts:storageStatus" },
        { title: "Storage Near Full", key: "diskAlerts:storageNearFull" },
        { title: "Re-indexing Time", key: "diskAlerts:reindexing" },
        {
          title: "Reserved Space Not Clearing",
          key: "diskAlerts:reservedSpaceNotClearing",
        },
      ],
    },
    {
      key: "videoAlerts",
      title: "Video Alerts",
      children: [
        { title: "Video Loss", key: "videoAlerts:videoLoss" },
        { title: "Recording", key: "videoAlerts:recording" },
      ],
    },
  ];

  const titleRendering = (nodeData: any) => {
    // console.log(nodeData);
    if (!nodeData.key.includes(":")) {
      return (
        <s.AlertItem>
          <Text bold fontSize={14}>
            {nodeData.title}
          </Text>
          <Text
            color={
              themeMode === "light" ? `${COLORS.BLACK}90` : `${COLORS.WHITE}60`
            }
            fontSize={12}
          >
            Advanced Settings
          </Text>
        </s.AlertItem>
      );
    } else {
      return <s.AlertSubs>{nodeData.title}</s.AlertSubs>;
    }
  };

  const onCheck: TreeProps["onCheck"] = (checkedkeys, info) => {
    // console.log("onCheck", checkedkeys, info);
    setCheckedKeys(checkedkeys as React.Key[]);
  };

  const onReset = () => {
    queryClient.invalidateQueries("searchMapFilterResult");
    dispatch(setAlerts(mapFilterInitialState.filters.alerts));
    setCheckedKeys([]);
  };

  useEffect(() => {
    let tempArr = checkedKeys
      .map((ck: React.Key) => {
        let tempKey = ck as string;
        return tempKey.split(":");
      })
      .reduce(
        (acc: DashboardFilterAlert, cur: string[]) => {
          if (cur.length === 1) {
            return {
              ...acc,
              [cur[0]]: {
                isChecked: true,
                ...acc[cur[0]],
              },
            };
          } else {
            return {
              ...acc,
              [cur[0]]: {
                ...acc[cur[0]],
                isChecked: false,
                [cur[1]]: true,
              },
            };
          }
        },
        {
          ...mapFilterInitialState.filters.alerts,
        }
      );

    let isFilter = checkedKeys.length === 0;
    props.onChangeFiltering("alerts", !isFilter);

    if (isFilter) {
      props.onChangeFilterName("Alert");
      dispatch(setAlerts({ ...mapFilterInitialState.filters.alerts }));
    } else {
      let tempFilterName: string = "Alert";
      let tempFindKey = checkedKeys.filter((ck) => {
        let tempCk = ck as string;
        return !tempCk.includes(":");
      });

      if (checkedKeys.length === 1 && tempFindKey.length === 0) {
        for (const alert of alertTypes) {
          if (alert.children) {
            for (const child of alert.children) {
              if (child.key === (checkedKeys[0] as string)) {
                tempFilterName = child.title as string;
              }
            }
          }
        }

        // tempFilterName = checkedKeys[0] as string;
      } else {
        if (tempFindKey.length === 1) {
          if (
            checkedKeys.filter((cc) => {
              let tempC = cc as string;
              return !tempC.includes(tempFindKey[0] as string);
            }).length > 0
          ) {
            tempFilterName = `${
              checkedKeys.filter((ck) => {
                let tempC = ck as string;
                return tempC.includes(":");
              }).length
            } Alerts`;
          } else {
            const foundAlert = alertTypes.find(
              (alert) => alert.key === tempFindKey[0]
            );
            tempFilterName = foundAlert ? (foundAlert.title as string) : "";
          }
        } else {
          if (
            checkedKeys.filter((ck) => {
              let tempC = ck as string;
              return !tempC.includes(":");
            }).length === 3
          ) {
            tempFilterName = "All Alerts";
          } else {
            tempFilterName = `${
              checkedKeys.filter((ck) => {
                let tempC = ck as string;
                return tempC.includes(":");
              }).length
            } Alerts`;
          }
        }
      }

      console.log(tempArr, "tempArr");
      props.onChangeFilterName(tempFilterName);
      dispatch(setAlerts(tempArr));
    }
  }, [checkedKeys]);

  useEffect(() => {
    setCheckedKeys([]);
  }, [filterKeyword]);

  return (
    <s.Container>
      <mediaQuery.Mobile>
        <s.Title>
          <Text fontSize={14} bold>
            Alert Type
          </Text>
        </s.Title>
      </mediaQuery.Mobile>
      <s.AlertContents>
        <Tree
          checkable
          autoExpandParent={true}
          selectable={false}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          titleRender={titleRendering}
          treeData={alertTypes}
          // treeData={initialAccountList}
        />
      </s.AlertContents>
      <s.Reset>
        <UnderlineText color={COLORS.LIGHTGRAY} fontSize={12} onClick={onReset}>
          Clear
        </UnderlineText>
        <TinyButton label="Done" onClickBtn={props.onApply} />
      </s.Reset>
    </s.Container>
  );
};

export default AlertFilter;
