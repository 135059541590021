import * as s from "../VideoShareStyled";
import { TableTemplate } from "components/templates/default/table/TableTemplate";

import SearchInput, {
  ClickSearch,
} from "components/atoms/input/search/SearchInput";
import StorageLabel, {
  StorageProps,
} from "components/blocks/storage/StorageLabel";
import { useEffect, useRef, useState } from "react";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import CHEVRON_RIGHT from "assets/icons/chevron_right.svg";
import CHEVRON_LEFT from "assets/icons/chevron_left.svg";
import { ReactComponent as VIDEO_SHARE_ICON } from "assets/icons/videoshare_white.svg";
import * as mediaQuery from "components/MediaQuery";
import Selects from "components/atoms/select/Select";

import VideoSharePoster from "./VideoSharePoster";
import PaginationComponent from "components/blocks/pagination";
import { isEmpty } from "lodash";
import { useOutletContext } from "react-router-dom";

// import { Props } from "./VideoShare";
import {
  VideoBasePagination,
  VideoShareFilter,
  VideoShareResult,
} from "api/interfaces/videoshareInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

export type Props = {
  tagFilters: string[];
  videoList: VideoShareResult[];
  videoTotal: number;
  isStatusFilter: VideoShareFilter;
  onChangeStatusFilter: (value: string) => void;
  listQueryInfo: VideoBasePagination;
  onChangePage: (page: number, totalRows: number) => void;
  onClickSearch: ClickSearch;
  selectedTag: string[];
  onClickTag: (item: string, event?: React.MouseEvent) => void;
  isSharePopup: string;
  onClickShare: (videoId: string, isOpen: boolean) => void;
  storageUsage: StorageProps;
  selectedAccount: ProfileAccountInfo;
};

export default function VideoSharePresenter(props: Props): JSX.Element {
  // const props: Props = useOutletContext();

  const horizontalScrollRef = useRef<any>(null);
  const centerChildren = (
    <>
      <mediaQuery.Default>
        <s.SearchWrapper>
          <SearchInput
            onClickSearch={props.onClickSearch}
            keyword={props.listQueryInfo.keyword}
          />
        </s.SearchWrapper>
      </mediaQuery.Default>

      <mediaQuery.Mobile>
        <div className="storage-label-mobile">
          <StorageLabel
            totalStorage={props.storageUsage.totalStorage}
            usedStorage={props.storageUsage.usedStorage}
          />
        </div>
      </mediaQuery.Mobile>
    </>
  );
  const sub = (
    <>
      <mediaQuery.Default>
        <div className="storage-label-default">
          <StorageLabel
            totalStorage={props.storageUsage.totalStorage}
            usedStorage={props.storageUsage.usedStorage}
          />
        </div>
      </mediaQuery.Default>

      <mediaQuery.Mobile>
        <SearchInput onClickSearch={props.onClickSearch} />
      </mediaQuery.Mobile>
    </>
  );

  const handleNextButtonClick = (nextType: "prev" | "next") => {
    // console.log(isOverflow, "isOverflow");
    // console.log(
    //   horizontalScrollRef.current.scrollLeft,
    //   horizontalScrollRef.current.offsetWidth,
    //   horizontalScrollRef.current.clientWidth,
    //   horizontalScrollRef.current.scrollLeft -
    //     horizontalScrollRef.current.clientWidth,
    //   "233"
    // );

    if (nextType === "prev") {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft -
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    } else {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft +
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (!horizontalScrollRef.current) return;
    // console.log(
    //   horizontalScrollRef.current.scrollLeft,
    //   horizontalScrollRef.current.offsetWidth,
    //   "-----"
    // );
    if (
      horizontalScrollRef.current.scrollLeft <
      horizontalScrollRef.current.offsetWidth
    ) {
      setIsOverflow(true);
    }
  }, [horizontalScrollRef]);

  const titleComponent = (
    <mediaQuery.Default>
      <s.TitleWrapper>
        <s.TitleComponent>
          <p className="icon">
            <VIDEO_SHARE_ICON />
          </p>
          Video Share
        </s.TitleComponent>
      </s.TitleWrapper>
    </mediaQuery.Default>
  );

  return (
    <>
      <TableTemplate
        titleComponent={titleComponent}
        centerChildren={centerChildren}
        noSearch
        buttons={sub}
      >
        <s.HeaderContainer>
          {/* 반응형에서는 select로 */}

          <s.RightFilter>
            <s.LeftScrollButton onClick={() => handleNextButtonClick("prev")}>
              <IoChevronBackOutline />
            </s.LeftScrollButton>
            <s.RightScrollButton onClick={() => handleNextButtonClick("next")}>
              <IoChevronForward />
            </s.RightScrollButton>
            <s.RightScroll ref={horizontalScrollRef}>
              <s.RightItem
                key={"video_tag_all"}
                isFilter={
                  props.selectedTag.includes("All") ||
                  props.selectedTag.length === 0
                }
                onClick={(e) => {
                  console.log(e);
                  props.onClickTag("All");
                }}
              >
                {"All"}
              </s.RightItem>
              {props.tagFilters.map(
                (item, index) =>
                  item !== undefined &&
                  !isEmpty(item) && (
                    <s.RightItem
                      key={"video_tag_" + index}
                      isFilter={props.selectedTag.includes(item)}
                      onClick={(e) => {
                        console.log(e);
                        props.onClickTag(item);
                      }}
                    >
                      {item}
                    </s.RightItem>
                  )
              )}
            </s.RightScroll>
          </s.RightFilter>
          {/* <mediaQuery.Default>
            <div>
              <SearchInput onClickSearch={props.onClickSearch} />
            </div>
          </mediaQuery.Default> */}
        </s.HeaderContainer>

        <s.Grid>
          {props.videoList?.map((item, index) => {
            return (
              <VideoSharePoster
                key={`video_poster_${index}`}
                item={item}
                index={index}
                selectedTag={props.selectedTag}
                onModelVisible={props.isSharePopup === item.videoId}
                onClickShare={props.onClickShare}
                selectedAccount={props.selectedAccount}
              />
            );
          })}
        </s.Grid>
        <PaginationComponent
          rowsPerPage={props.listQueryInfo.pageLimit}
          rowCount={props.videoTotal}
          onChangePage={props.onChangePage}
          currentPage={props.listQueryInfo.pageNum + 1}
          onChangeRowsPerPage={() => {
            return false;
          }}
        />
      </TableTemplate>
      {/* <mediaQuery.Mobile>
      {props.isSharePopup === item.videoId && (
              <div
                onClick={(e) => {
                  console.log("??");
                  e.stopPropagation();
                }}
              >
                <AddVS
                  small
                  ref={shareRef}
                  index={index}
                  videoId={item.videoId}
                  isShare={item.sharing}
                  onChangeModal={() => {
                    setIsShare(!isShare);
                    onClickShare(item.videoId, false);
                    return;
                  }}
                />
              </div>
            )}
      </mediaQuery.Mobile> */}
    </>
  );
}
