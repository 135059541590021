import TitleComponent from "../../common/title/TitleComponent";
import Alerts from "../../common/alerts";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useEffect, useState } from "react";
import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";
import { useQuery } from "react-query";
import { getDashboardAlertLatest } from "api/dashboardAPI";
import NoData from "components/templates/nopage/NoData";
import Spinner from "components/atoms/loader/Spinner";

type Props = {
  onDeleteWidget: (key: string) => void;
  onAssignModal: (type: string, info: string) => void;
  onSelectItem: (item: DashboarAlertLatest) => void;
};
export default function AlertsComponent(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [alertsData, setAlertsData] = useState<DashboarAlertLatest[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const getAlertLatest = useQuery(
    ["getAlertLatest", selectedAccount, searchValue],
    () =>
      getDashboardAlertLatest({
        accountId: selectedAccount.accountId,
        maxCount: 100,
        keyword: searchValue,
      }),
    {
      retry: 0,
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res, "res ---");
        if (res.result) {
          // console.log(res.result, "alert latest");
          setAlertsData(res.result);
        } else {
          setAlertsData([]);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const onClickSearch = (value: string) => {
    console.log(value);
    setSearchValue(value);
    getAlertLatest.refetch();
  };

  const onRefresh = () => {
    getAlertLatest.refetch();
  };

  return (
    <>
      <TitleComponent
        title="Alerts"
        share
        isSearch
        onDeleteWidget={() => props.onDeleteWidget("alerts")}
        onRefresh={onRefresh}
        onClickSearch={onClickSearch}
      />
      {getAlertLatest.isLoading ? (
        <Spinner />
      ) : alertsData.length === 0 ? (
        <NoData />
      ) : (
        <div className="content drag-cancel-content">
          <Alerts
            noScroll
            alertsData={alertsData}
            onAssignModal={props.onAssignModal}
            onSelectItem={props.onSelectItem}
          />
        </div>
      )}
    </>
  );
}
