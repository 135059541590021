import CLOUD_ICON from "assets/icons/cloud.svg";
import { IconBaseProps } from "react-icons";
import { IoMdCloudOutline } from "react-icons/io";
import { COLORS } from "styles/colors";

function Cloud(props: IconBaseProps): JSX.Element {
  return <IoMdCloudOutline color={COLORS.RED} size={24} {...props} />;
}

export default Cloud;
