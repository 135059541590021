import * as s from "./GoogleMapStyled";
import MapControl from "./sub/control/MapControl";
import * as mediaQuery from "components/MediaQuery";
import SubHeader from "./sub/subheader";
import RecorderListView from "./sub/leftAside/RecorderListView";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { InfoWindow, InfoWindowF } from "@react-google-maps/api";
import RecorderListInfoView from "./sub/infoView/RecorderListInfoView";
import RecorderInfoView from "./sub/infoView/RecorderInfoView";
import { NoticeState } from "redux/reducers/notices/noticeReducer";
import { ForwardedRef, useEffect, useRef, useState } from "react";
import { MapFilterKeyword } from "redux/reducers/map/mapFilter";
import {
  DashboardData,
  KeywordSearchRecorder,
} from "api/interfaces/dashboardInterface.interface";
import RecorderDetailView from "./sub/leftAside/detail/RecorderDetailView";
import { More } from "./sub/subheader/SubHeader";
import { SelectedAlertView } from "api/interfaces/alertInterface.interface";
import FloatList from "./sub/float";
import MissingList from "./sub/missingList";
import { TbChevronsUp, TbChevronsDown } from "react-icons/tb";

type Props = {
  isCollapsed: boolean;
  isRoadview: boolean;
  isViewRecorderList: boolean;

  onChangeCollapsed: () => void;
  onZoomPlus: () => void;
  onZoomOut: () => void;
  onZoomFit: () => void;
  onChangeView: () => void;
  // setIsWidget: (value: React.SetStateAction<boolean>) => void;
  // widgetRef: React.MutableRefObject<any>;
  mapRef: React.MutableRefObject<any>;
  infoWindowRef: React.RefObject<InfoWindow>;
  activeMarker: google.maps.Marker | undefined;
  closeActiveMarker: () => void;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement> | undefined;
  onClickRecorder: (id: string) => void;
  onChangeSearchMove: (checked: boolean) => void;
  onSearch: (e: MapFilterKeyword) => void;
  onResetSearchInput: () => void;
  // onClickSearchResult?: (latitude: number, longitude: number) => void;

  onCloseRecorderList: () => void;
  onSelectRecorder: (rec: DashboardData) => void;
  onClickRecorderKeyword: (rec: KeywordSearchRecorder) => void;

  // selectedRecorder: DashboardData | null;
  more: More;
  onSelectMoreFilter: (e: any) => void;
  onDeleteMoreFilter: (name: string) => void;
  selectedAlert: SelectedAlertView | null;
  onCloseRecorderView: () => void;

  isFetching: boolean;
};

const variants = {
  open: { opacity: 1, right: "15px" },
  closed: { opacity: 1, right: "-383px" },
};

export function GoogleMapPresenter(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const notice: NoticeState = useAppSelector((state) => state.notice);
  const innerRef = useRef(null);
  const [innerSize, setInnerSize] = useState<{ [key: string]: number }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setInnerSize({ width, height });
      }
    });

    if (innerRef.current) {
      resizeObserver.observe(innerRef.current);
    }

    // 컴포넌트가 언마운트될 때 observer 해제
    return () => {
      if (innerRef.current) {
        resizeObserver.unobserve(innerRef.current);
      }
    };
  }, [window, innerRef]);

  return (
    <>
      <mediaQuery.Default>
        <SubHeader
          isCollapsed={props.isCollapsed}
          // onChangeStatus={props.onChangeStatus}
          onChangeCollapsed={props.onChangeCollapsed}
          onChangeSearchMove={props.onChangeSearchMove}
          onSearch={props.onSearch}
          onResetSearchInput={props.onResetSearchInput}
          // onClickSearchResult={props.onClickSearchResult}
          more={props.more}
          onSelectMoreFilter={props.onSelectMoreFilter}
          onDeleteMoreFilter={props.onDeleteMoreFilter}
          onClickRecorder={props.onClickRecorder}
          onClickRecorderKeyword={props.onClickRecorderKeyword}
        />
      </mediaQuery.Default>
      <s.Container noticeLength={notice.notices.length}>
        <mediaQuery.Mobile>
          <s.MobileTopFloat>
            <SubHeader
              isCollapsed={props.isCollapsed}
              // onChangeStatus={props.onChangeStatus}
              onChangeCollapsed={props.onChangeCollapsed}
              onChangeSearchMove={props.onChangeSearchMove}
              onSearch={props.onSearch}
              onResetSearchInput={props.onResetSearchInput}
              // onClickSearchResult={props.onClickSearchResult}
              more={props.more}
              onSelectMoreFilter={props.onSelectMoreFilter}
              onDeleteMoreFilter={props.onDeleteMoreFilter}
              onClickRecorder={props.onClickRecorder}
              onClickRecorderKeyword={props.onClickRecorderKeyword}
            />
          </s.MobileTopFloat>
          <s.MobileBottom>
            <s.BottomFloat>
              <div>
                <MissingList />
              </div>
              <MapControl
                isRoadView={props.isRoadview}
                onZoomPlus={props.onZoomPlus}
                onZoomOut={props.onZoomOut}
                onZoomFit={props.onZoomFit}
                onChangeView={props.onChangeView}
              />
            </s.BottomFloat>
            <FloatList
              onClickRecorder={props.onClickRecorder}
              onSelectRecorder={props.onSelectRecorder}
              innerSize={innerSize}
              isViewRecorderList={props.isViewRecorderList}
              selectedAlert={props.selectedAlert}
            />
          </s.MobileBottom>
        </mediaQuery.Mobile>

        <mediaQuery.Default>
          <s.MapInner ref={innerRef}>
            {/* <mediaQuery.Default> */}
            <s.WidgetContainer
              animate={props.isCollapsed ? "open" : "closed"}
              variants={variants}
              id="widget"
            >
              <FloatList
                onClickRecorder={props.onClickRecorder}
                onSelectRecorder={props.onSelectRecorder}
                innerSize={innerSize}
                isViewRecorderList={props.isViewRecorderList}
                selectedAlert={props.selectedAlert}
              />
            </s.WidgetContainer>
            {/* </mediaQuery.Default> */}
            <div className="left-wrapper">
              {!props.isFetching && props.isViewRecorderList && (
                <RecorderListView
                  onClickRecorder={props.onClickRecorder}
                  onSelectRecorder={props.onSelectRecorder}
                  onClose={props.onCloseRecorderList}
                  selectedAlertId={props.selectedAlert?.recorderId}
                  isFetching={props.isFetching}
                />
              )}
              {props.selectedAlert !== null && (
                <RecorderDetailView
                  // recorder={props.selectedRecorder}
                  selectedAlert={props.selectedAlert}
                  isViewRecorderList={props.isViewRecorderList}
                  onModal={props.onCloseRecorderView}
                />
              )}
            </div>
            <s.ControlContainer>
              <MapControl
                isRoadView={props.isRoadview}
                onZoomPlus={props.onZoomPlus}
                onZoomOut={props.onZoomOut}
                onZoomFit={props.onZoomFit}
                onChangeView={props.onChangeView}
              />
            </s.ControlContainer>
          </s.MapInner>
        </mediaQuery.Default>

        <mediaQuery.Mobile>
          <s.MobileInfo>
            <div className="map-info-inner">
              {!props.isFetching && props.isViewRecorderList && (
                <RecorderListView
                  onClickRecorder={props.onClickRecorder}
                  onSelectRecorder={props.onSelectRecorder}
                  onClose={props.onCloseRecorderList}
                  selectedAlertId={props.selectedAlert?.recorderId}
                  isFetching={props.isFetching}
                />
              )}
              {props.selectedAlert !== null && (
                <RecorderDetailView
                  // recorder={props.selectedRecorder}
                  selectedAlert={props.selectedAlert}
                  isViewRecorderList={props.isViewRecorderList}
                  onModal={props.onCloseRecorderView}
                />
              )}
            </div>
          </s.MobileInfo>
        </mediaQuery.Mobile>

        <s.MapView ref={props.mapRef} id="map">
          {props.activeMarker !== undefined ? (
            <InfoWindowF
              anchor={props.activeMarker}
              // ref={props.infoWindowRef}
              options={{
                pixelOffset: props.activeMarker.get("popupPosition"),
                disableAutoPan: true,
              }}

              // onCloseClick={props.closeActiveMarker}
              // onUnmount={props.closeActiveMarker}
            >
              {props.activeMarker.get("systems").length > 1 ? (
                <RecorderListInfoView
                  systems={props.activeMarker.get("systems")}
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                  onSelectRecorder={props.onSelectRecorder}
                />
              ) : props.activeMarker.get("systems")[0].type === "Spectrum" &&
                props.activeMarker.get("systems")[0].recorders.length > 1 ? (
                <RecorderListInfoView
                  systems={props.activeMarker.get("systems")}
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                  onSelectRecorder={props.onSelectRecorder}
                />
              ) : (
                <RecorderInfoView
                  system={props.activeMarker.get("systems")[0]}
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                />
              )}
            </InfoWindowF>
          ) : null}
        </s.MapView>
      </s.Container>
    </>
  );
}
