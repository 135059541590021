import React, { forwardRef, HTMLProps, useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getRecorderList, getRecorderTotalList } from "api/recorderAPI";
import SearchInput from "components/atoms/input/search/SearchInput";
import ModalSubMenu, { Menus } from "components/blocks/modal/menu/ModalSubMenu";
import { SearchRecorderList, SelectedRowWithAddress } from "./SearchRecorderList";
import { SearchRecorderGroup } from "./SearcRecorderGroup";
import * as DATA from "pages/alert/temp/data";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";

export type SearchRecorderProps = {
  accountId: string;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  onSelectedGroupRowsChange?: (selectedRows: number[]) => void;
  onSelectRowChangeAddress ?:(row :SelectedRowWithAddress) =>void;
  selectedRow?: string[] | string;
  selectedGroupRow?: number[];
  isTab?: boolean;
  isSingleMode ?: boolean;
  isEditable?:boolean;
  userId: string | undefined;
};

export interface DataRow {
  id: string;
  name: string;
  cameras: number;
  location: string;
}

export function SearchRecorder(props: SearchRecorderProps): JSX.Element {
  const [searchText, setSearchText] = useState<string>("");
  const onClickSearch = (keyword: string) => {
    setSearchText(keyword);
  };
  // step 0 - recorders sub menu
  const recorderMenus = [
    {
      label: `Add Recorders (${!props.isSingleMode ? props.selectedRow !== undefined ? props.selectedRow?.length:0 :0})`,
      value: true,
      columns: DATA.addRecorderColumns,
      data: DATA.addRecorderData,
    },
    {
      label: `Add Recorder Groups (${ props.selectedGroupRow !== undefined ? props.selectedGroupRow?.length : 0})`,
      value: false,
      columns: DATA.addRecorderGroupColumns,
      data: DATA.addRecorderGroupData,
    },
  ];

  const [currentMenu, setCurrentMenu] = useState(0);
  const onChangeMenu = (index: number) => {
    setCurrentMenu(index);
  };

  const onChangeSearchText = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  return (
    <>
      {props.isTab ? (
        <s.ModalTabSearchWrapper>
          <ModalSubMenu
            menus={recorderMenus}
            currentMenu={currentMenu}
            onChange={onChangeMenu}
          />
          <div className="search-box">
            {/* search input groupt, individual 공통으로 검색되도록 */}
            <DebounceSearchInput
              placeholder="Search Recorder"
              onClickSearch={onClickSearch}
              onChange={onChangeSearchText}
            />
          </div>
        </s.ModalTabSearchWrapper>
      ) : (
        <DebounceSearchInput
          placeholder="Search Recorder"
          onClickSearch={onClickSearch}
          onChange={onChangeSearchText}
        />
      )}

      <s.ModalTableContainer>
        <div style={{ display: currentMenu === 0 ? "block" : "none" }}>
          <SearchRecorderList
            accountId={props.accountId}
            search={searchText}
            selectedRow={props.selectedRow}
            onSelectedRowsChange={props.onSelectedRowsChange}
            onSelectRowChangeAddress={props.onSelectRowChangeAddress}
            isSingleMode={props.isSingleMode}
            isEditable={props.isEditable}
            userId={props.userId}
          />
        </div>
        <div style={{ display: currentMenu === 1 ? "block" : "none" }}>
          <SearchRecorderGroup
            accountId={props.accountId}
            search={searchText}
            selectedRecorderGroupRow={props.selectedGroupRow}
            onSelectedRowsChange={props.onSelectedGroupRowsChange}
            userId={props.userId}
          />
        </div>
      </s.ModalTableContainer>
    </>
  );
}
