import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  CVVReport,
  CameraStep,
  NotifyUser,
  NotifyUserGroup,
  ReportDataRow,
} from "api/interfaces/reportInterface.interface";
import {
  deleteReport,
  postCVVReportCreate,
  putCVVReportCreate,
} from "api/reportAPI";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import { NOTIFICATION_TYPE } from "../common/AddNotification";
import AddCvvPresenter from "./AddCvvPresenter";
import { chain, isEmpty } from "lodash";
import {
  CustomError,
  REPORT_DETECT_TIME,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { selectedRecorderCountThumbnail } from "utils/ReportUtil";
import useApiError from "hook/useApiError";

export enum UPDATE_TYPE {
  AUTO_APPLY,
  RECORDER,
  RECORDER_GROUP,
  CAMERA,
  DAY_TIME,
  NIGHT_TIME,
  INTERVAL,
  WEEKLY_VALUE,
  MONTHLY_VALUE,
  IS_END_OF_MONTH,
  USER,
  USER_GROUP,
  REPORT_NAME,
  REPORT_DESC,
  REPORT_ENABLE,
}

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  reportRow?: ReportDataRow;
  data?: CVVReport;
};

export default function AddCvvContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);
  const [recorderCounts, setRecorderCounts] = useState<number>(0);
  const [cameraCounts, setCameraCounts] = useState<number>(0);

  const [reportRuleValidate, setReportRuleValidate] = useState<Validate>({
    isRecorder: false,
    isCamera: false,
    isRuleName: false,
  });
  const [reportRuleValidateMsg, setReportRuleValidateMsg] =
    useState<ValidateMsg>({
      isRecorder: "",
      isCamera: "",
      isRuleName: "",
    });

  const [cvvReport, setCvvReport] = useState<CVVReport>({
    recorder: { autoApply: false, systems: [], recorderGroupId: [] },
    recorderCameraId: [],
    notification: {
      dayTime: "09:00",
      nightTime: "21:00",
      intervalType: REPORT_DETECT_TIME.WEEK,
      weeklyValue: [true, true, true, true, true, true, true],
      monthlyValue: 1,
      isEndOfMonth: true,
      users: [],
      userGroups: [],
    },
    preferences: {
      host: auth.user.name.concat(`(${auth.user.email})`),
      title: "",
      description: "",
      isEnable: true,
      userId: undefined
    },
  });

  useEffect(() => {
    if (props.data) {
      setCvvReport(props.data);

      if (props.data.recorderCameraId !== undefined) {
        const cameraCount = props.data.recorderCameraId.reduce(
          (countCamera: number, curr: CameraStep) => {
            return countCamera + curr.camera.length;
          },
          0
        );
        setCameraCounts(cameraCount);
      } else {
        setCameraCounts(0);
      }

      const groupBySelect = selectedRecorderCountThumbnail(
        props.data.recorderCameraId
      );
      setSelectRecorderCount(groupBySelect !== undefined ? groupBySelect : 0);
    }
  }, [props.data]);

  const steps = [
    {
      label: "Recorders",
    },
    {
      label: "CVV",
    },
    {
      label: "Notification",
    },
    {
      label: "Preferences",
    },
  ];

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      console.log(name, index);
      setPage(index);
    }
  };

  const [isConfirm, SetIsConfirm] = useState<boolean>(false);

  const mutationDeleteReport = useMutation(deleteReport, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.cvv.delete.success",
          defaultMessage: "CVV report deleted successfully.",
        })
      );
      props.onModal("edit");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.cvv.delete.fail",
          defaultMessage: "Failed to delete CVV report.",
        })
      );
    },
  });

  const onConfirmDelete = () => {
    mutationDeleteReport.mutate({
      reportId: props.reportRow?.id as string,
      accountId: selectedAccount.accountId,
    });
  };
  const setIsDeleteConfirm = (confirm: boolean) => {
    SetIsConfirm(confirm);
  };
  const onDelete = (value: boolean) => {
    SetIsConfirm(value);
  };

  const mutationCreateReport = useMutation(postCVVReportCreate, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.cvv.create.success",
          defaultMessage: "CVV report created successfully.",
        })
      );
      props.onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.cvv.create.fail",
          defaultMessage: "Failed to create CVV report.",
        })
      );
    },
  });

  const mutationUpdateReport = useMutation(putCVVReportCreate, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.cvv.update.success",
          defaultMessage: "CVV report updated successfully.",
        })
      );
      props.onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.cvv.update.fail",
          defaultMessage: "Failed to update CVV report.",
        })
      );
    },
  });

  const initReportRuleValidate = useCallback((field: string) => {
    setReportRuleValidate((info) => {
      return { ...info, [field as keyof typeof reportRuleValidate]: false };
    });
    setReportRuleValidateMsg((info) => {
      return { ...info, [field as keyof typeof reportRuleValidateMsg]: "" };
    });
  }, []);

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);
    console.log("cvvReport", cvvReport);

    initReportRuleValidate("isRecorder");
    initReportRuleValidate("isCamera");
    initReportRuleValidate("isRuleName");

    if (
      (cvvReport.recorder.systems === undefined ||
        cvvReport.recorder.systems.length === 0) &&
      (cvvReport.recorder.recorderGroupId === undefined ||
        cvvReport.recorder.recorderGroupId.length === 0) &&
      cvvReport.recorder.autoApply === false
    ) {
      setReportRuleValidate((info) => {
        return { ...info, isRecorder: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRecorder: intl.formatMessage({
            id: "validateMsg.report.rule.emptyRecorder",
            defaultMessage: "Select one or more recorders",
          }) as string,
        };
      });
      setPage(0);
      return;
    }

    // cvv is optional,
    // if(cvvReport.recorderCameraId === undefined ||
    //   (cvvReport.recorderCameraId as CameraStep[]).length === 0){
    //     setReportRuleValidate((info) => {
    //       return { ...info, isCamera: true as boolean };
    //     });

    //     setReportRuleValidateMsg((info) => {
    //       return {
    //         ...info,
    //         isRecorder: intl.formatMessage({
    //           id: "validateMsg.report.rule.emptyCamera",
    //           defaultMessage: "Select one or more cameras",
    //         }) as string,
    //       };
    //     });
    //     setPage(1);
    //     return;
    //   }

    if (isEmpty(cvvReport.preferences.title)) {
      setReportRuleValidate((info) => {
        return { ...info, isRuleName: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRuleName: intl.formatMessage({
            id: "validateMsg.report.rule.emptyRuleName",
            defaultMessage: "Please enter Report Rule Name",
          }) as string,
        };
      });
      setPage(3);
      return;
    }
    if (props.isEdit) {
      mutationUpdateReport.mutate({
        payload: cvvReport,
        accountId: selectedAccount.accountId,
        reportId: props.reportRow?.id as string,
      });
    } else {
      mutationCreateReport.mutate({
        payload: cvvReport,
        accountId: selectedAccount.accountId,
      });
    }
  }, [
    cvvReport,
    initReportRuleValidate,
    intl,
    mutationCreateReport,
    mutationUpdateReport,
    props.isEdit,
    props.reportRow?.id,
    selectedAccount.accountId,
  ]);

  const onChangeNotification = useCallback(
    (
      type: NOTIFICATION_TYPE,
      value: string | number | boolean | string[] | boolean[]
    ) => {
      if (type === NOTIFICATION_TYPE.SEND_TIME) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              dayTime: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.INTERVAL) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              intervalType: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.WEEKLY_VALUE) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              weeklyValue: value as boolean[],
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.MONTHLY_VALUE) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              monthlyValue: value as number,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.IS_END_OF_MONTH) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              isEndOfMonth: value as boolean,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              users: (value as string[]).map((data) => {
                return {
                  userId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUser>,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER_GROUP) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              userGroups: (value as string[]).map((data) => {
                return {
                  userGroupId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUserGroup>,
            },
          };
        });
      }
    },
    []
  );

  const [selectRecorderCount, setSelectRecorderCount] = useState<number>(0);

  const onChangeCvvReportInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value:
        | string
        | number
        | boolean
        | string[]
        | boolean[]
        | number[]
        | CameraStep[]
    ) => {
      if (type === UPDATE_TYPE.AUTO_APPLY) {
        setCvvReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              autoApply: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER) {
        setCvvReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              systems: value as string[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUP) {
        setCvvReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              recorderGroupId: value as number[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.CAMERA) {
        setCvvReport((info) => {
          return {
            ...info,
            recorderCameraId: value as CameraStep[],
          };
        });

        const cameraCount = (value as CameraStep[]).reduce(
          (countCamera: number, curr: CameraStep) => {
            return countCamera + curr.camera.length;
          },
          0
        );
        setCameraCounts(cameraCount);

        const groupBySelect = selectedRecorderCountThumbnail(
          value as CameraStep[]
        );
        setSelectRecorderCount(groupBySelect !== undefined ? groupBySelect : 0);
      } else if (type === UPDATE_TYPE.DAY_TIME) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              dayTime: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.NIGHT_TIME) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              nightTime: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.INTERVAL) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              intervalType: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.WEEKLY_VALUE) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              weeklyValue: value as boolean[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.MONTHLY_VALUE) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              monthlyValue: value as number,
            },
          };
        });
      } else if (type === UPDATE_TYPE.IS_END_OF_MONTH) {
        setCvvReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              isEndOfMonth: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.USER) {
        setCvvReport((info) => {
          return { ...info, notifyUserMail: value as string[] };
        });
      } else if (type === UPDATE_TYPE.REPORT_NAME) {
        setCvvReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              title: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_DESC) {
        setCvvReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              description: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_ENABLE) {
        setCvvReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              isEnable: value as boolean,
            },
          };
        });
      }
    },
    []
  );

  function onChangeCamera(camera: CameraStep[]): void {
    throw new Error("Function not implemented.");
  }
  const onChangeRecorderCount = (recorderCount: number) => {
    setRecorderCounts(recorderCount);
  };

  const onChangeCameraCount = (cameraCount: number) => {
    //setCameraCounts(cameraCount);
  };
  const onChangeEnable = (value: boolean) => {
    // mutationEnableReport.mutate({
    //   reportId: props.reportRow?.id as string,
    //   payload: {
    //     enable: value,
    //   },
    // });
  };

  return (
    <AddCvvPresenter
      steps={steps}
      page={page}
      onChangePage={onChangePage}
      onChangeReportInfo={onChangeCvvReportInfo}
      onChangeNotification={onChangeNotification}
      accountId={selectedAccount.accountId}
      data={cvvReport}
      recorderCount={recorderCounts}
      cameraCount={cameraCounts}
      isEdit={props.isEdit}
      setIsDeleteConfirm={setIsDeleteConfirm}
      onConfirmDelete={onConfirmDelete}
      isConfirm={isConfirm}
      onDelete={onDelete}
      onChangeEnable={onChangeEnable}
      hostName={auth.user?.name + "(" + auth.user?.email + ")"}
      onClickCreate={onClickCreate}
      onChangeCamera={onChangeCamera}
      onChangeRecorderCount={onChangeRecorderCount}
      onChangeCameraCount={onChangeCameraCount}
      reportRuleValidate={reportRuleValidate}
      reportRuleValidateMsg={reportRuleValidateMsg}
      selectRecorderCount={selectRecorderCount}
      reportRow={props.reportRow}
    />
  );
}
