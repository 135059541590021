import * as s from "./SignupStyled";
import * as cs from "../SignCommonStyled";
import WARNING_CIRCLE_ICON from "assets/icons/warning_circle.svg";
import Marker_COOKIE_ICON from "assets/icons/marker_cookie.svg";
import CLOSE_ICON from "assets/icons/cross.svg";
import TITLE_MYDW_ICON from "assets/icons/title_myDW.svg";
import FEATHER_SHIELD_ICON from "assets/icons/feather_shield_phone.svg";
import PROFILE_ICON from "assets/icons/account_profile.svg";
import { SIGNUP_STEP, UserInfo, Validate } from "./SignupContainer";
import { useIntl } from "react-intl";
import { Ref, useEffect, useMemo, useState } from "react";
import { getOnlyNumber } from "utils/validHelper";
import Input from "components/atoms/input/Input";
import { Text, UnderlineText, ValidateWord } from "components/atoms/text/Text";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import { COLORS } from "styles/colors";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { EULAModal } from "./EULAModal";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import ONLINE_ICON from "assets/icons/online.svg";
import WARNING_ICON from "assets/icons/warning_red.svg";
import { checkPasswordNumberRegEx, checkPasswordRegEx } from "utils/regEx";
import VerfiyCode from "components/blocks/verify/VerfiyCode";
import { useAppSelector } from "redux/hooks";
import AuthPopup from "components/templates/modal/auth/AuthPopup";
import { getSpectrumAuthUrl } from "utils/AccountUtil";
import { Spin } from "antd";
import { ValidateMsg } from "../signin/LoginContainer";
import PasswordBubble from "components/blocks/bubble/PasswordBubble";

interface Props {
  onCode: (code: string, params: URLSearchParams) => void;
  onPopupClose: () => void;
  step: SIGNUP_STEP;
  checkedEmail: string;
  onClickSignup: () => void;
  onClickVerify: () => void;
  onClickLogin: () => void;
  onClickVerifyCancel: () => void;
  passwordStatus: number;
  onChangeUserInfo: (type: UPDATE_TYPE, data: string | boolean) => void;
  userInfo: UserInfo;
  verifyCode: string;
  onChangeVerifyCode: (value: string) => void;
  onblurConfirmPassword: () => void;
  isValidConfirm: boolean;
  isValidPassword: boolean;
  isFlagSelected: string;
  onClickPassword: () => void;
  onClickBackLogin: () => void;
  onSelectFlag: (code: string) => void;
  onOpenEULAModal: (isOpen: boolean) => void;
  isOpenEULA: boolean;
  isValidated: Validate;
  firstNameRef: any;
  lastNameRef: any;
  passwordRef: any;
  confirmPasswordRef: any;
  agreementRef: any;
  onClickSignPage: () => void;
  onClickCloudCreate: () => void;
  validateMsg: string;
  onClickResend: () => void;
  isValidVerify: boolean;
  isCloudAccount: boolean;
  onClickCloudLogin: () => void;
  isLoadedToken: boolean;
  isExpiredToken: boolean;
  cloudLoading: boolean;
  spectrumValidated: Validate;
  spectrumValidateMsg: ValidateMsg;
}

export enum UPDATE_TYPE {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  PASSWORD = "PASSWORD",
  CONFIRM_PASSWORD = "CONFIRM_PASSWORD",
  PHONE_NUMBER = "PHONE_NUMBER",
  AGREEMENT = "AGREEMENT",
}

export enum PASSWORD_STATUS {
  VERY_POOR,
  POOR,
  OK,
  GOOD,
  EXCELLENT,
}

interface ValidInfo {
  className: string;
  desc: string;
}

export function SignupPagePresenter(props: Props): JSX.Element {
  const {
    step,
    onCode,
    onPopupClose,
    checkedEmail,
    onClickSignup,
    onClickVerify,
    onClickLogin,
    onClickVerifyCancel,
    passwordStatus,
    onChangeUserInfo,
    userInfo,
    verifyCode,
    onChangeVerifyCode,
    onblurConfirmPassword,
    isValidConfirm,
    isValidPassword,
    onClickPassword,
    onClickBackLogin,
    isOpenEULA,
    onOpenEULAModal,
    isValidated,
    firstNameRef,
    lastNameRef,
    passwordRef,
    confirmPasswordRef,
    agreementRef,
    onClickSignPage,
    onClickCloudCreate,
    validateMsg,
    onClickResend,
    isValidVerify,
    isCloudAccount,
    onClickCloudLogin,
    isLoadedToken,
    isExpiredToken,
    cloudLoading,
    spectrumValidated,
    spectrumValidateMsg,
  } = props;
  const intl = useIntl();
  const theme = useAppSelector((state) => state.theme.theme);
  const noticeLength: number = useAppSelector(
    (state) => state.notice.notices.length
  );
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const getVliadClassName = (status: PASSWORD_STATUS): ValidInfo => {
    switch (status) {
      case PASSWORD_STATUS.VERY_POOR:
        return {
          className: "verypoor",
          desc: "Very Poor",
        };
      case PASSWORD_STATUS.POOR:
        return {
          className: "poor",
          desc: "Poor",
        };
      case PASSWORD_STATUS.OK:
        return {
          className: "ok",
          desc: "OK",
        };
      case PASSWORD_STATUS.GOOD:
        return {
          className: "good",
          desc: "Good",
        };
      case PASSWORD_STATUS.EXCELLENT:
        return {
          className: "excellent",
          desc: "Excellent",
        };
      default:
        return {
          className: "poor",
          desc: "Poor",
        };
    }
  };

  const validInfo: ValidInfo = useMemo(() => {
    return getVliadClassName(passwordStatus);
  }, [passwordStatus]);

  // const step = SIGNUP_STEP.RESET_PASSWORD;

  useEffect(() => {
    if (
      !(userInfo.password.length < 8) &&
      checkPasswordRegEx(userInfo.password) &&
      checkPasswordNumberRegEx(userInfo.password)
    ) {
      let timer = setTimeout(() => {
        setIsFocus(false);
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    } else {
      //setIsFocus(true);
    }
  }, [userInfo.password]);

  useEffect(() => {
    console.log(isFocus);
  }, [isFocus]);

  return (
    <Spin
      spinning={cloudLoading}
      size="large"
      indicator={
        <div className="progress-indicator progress-indicator--responsive" />
      }
    >
      <div>
        <cs.BG noticeLength={noticeLength}>
          <cs.Container>
            <s.TitleWrapper>
              <s.SignupTitle>
                WELCOME TO
                <img src={TITLE_MYDW_ICON} width="80" alt="MyDW Icon" />
              </s.SignupTitle>
              <p className="txt">Complete Surveillance Solutions</p>
            </s.TitleWrapper>

            {step === SIGNUP_STEP.SUCCESS && (
              <cs.LoginBox>
                <s.SuccessInner>
                  <img src={PROFILE_ICON} width="185" alt="Profile Icon" />

                  <Text fontSize={18} className="user-name">
                    {userInfo.firstName + " " + userInfo.lastName}
                  </Text>

                  <div className="success-box">
                    <div className="title">
                      {intl.formatMessage({
                        id: "signup_message",
                        defaultMessage:
                          "Successfully invited to the organization.",
                      })}
                    </div>
                    <div className="description">
                      {intl.formatMessage({
                        id: "signup_message2",
                        defaultMessage: "You may now login and use myDW.",
                      })}
                    </div>
                  </div>
                  <cs.BigButton
                    id="dw_signup_button_login"
                    type="button"
                    onClick={onClickLogin}
                  >
                    {intl.formatMessage({
                      id: "login",
                      defaultMessage: "Login",
                    })}
                  </cs.BigButton>
                </s.SuccessInner>
              </cs.LoginBox>
            )}
            {step === SIGNUP_STEP.SELECT_SIGNUP && (
              <cs.LoginBox>
                <div className="title">SIGN UP</div>
                <cs.EmailBadge>{checkedEmail}</cs.EmailBadge>
                <s.InputWrapper>
                  <cs.BigButton
                    // dw
                    id="dw_login_button_next"
                    disabled={!isLoadedToken}
                    onClick={onClickSignPage}
                  >
                    Create Account
                  </cs.BigButton>
                  <Text fontSize={12} bold>
                    OR
                  </Text>

                  <AuthPopup
                    url={getSpectrumAuthUrl(checkedEmail)}
                    onCode={onCode}
                    onClose={onPopupClose}
                    title={"DW Spectrum Web Portal"}
                    width={500}
                    height={500}
                  >
                    <cs.BigButton dw disabled={!isLoadedToken}>
                      Login with DW Cloud
                    </cs.BigButton>
                  </AuthPopup>
                  {/*           
                  <cs.BigButton dw onClick={onClickCloudCreate} disabled={!isLoadedToken}>
                    Sign in with DW Cloud
                  </cs.BigButton>
              */}
                </s.InputWrapper>
                <cs.Bottom>
                  <ValidateWord>{validateMsg}</ValidateWord>
                  {spectrumValidated.email && (
                    <ValidateWord>{spectrumValidateMsg.email}</ValidateWord>
                  )}
                </cs.Bottom>
              </cs.LoginBox>
            )}
            {step === SIGNUP_STEP.INPUT_VERIFY_CODE && (
              <cs.LoginBox>
                <s.SuccessInner>
                  <div className="verify-title">
                    <s.PasswordTitle>
                      <img
                        src={FEATHER_SHIELD_ICON}
                        width="27"
                        alt="Feather Shield Icon"
                      />
                      <p className="title">
                        {intl.formatMessage({
                          id: "verify_phone_number",
                          defaultMessage: "Verify Your Phone Number",
                        })}
                      </p>
                    </s.PasswordTitle>
                    <span className="description">
                      Enter the code sent to your phone at <br />
                      {userInfo.phoneNumber}
                    </span>
                  </div>
                  <s.VerifyWrapper>
                    <VerfiyCode
                      verifyCode={verifyCode}
                      onChange={onChangeVerifyCode}
                      onClickOtpEnter={onClickVerify}
                    />

                    <UnderlineText
                      color={COLORS.BUTTONS2}
                      fontSize={14}
                      onClick={onClickResend}
                    >
                      {intl.formatMessage({
                        id: "resend_code",
                        defaultMessage: "Resend Code",
                      })}
                    </UnderlineText>
                  </s.VerifyWrapper>
                  {isValidVerify && (
                    <cs.Bottom>
                      <ValidateWord>{validateMsg}</ValidateWord>
                    </cs.Bottom>
                  )}
                  <cs.MarginBottom>
                    <cs.BigButton
                      id="dw_signup_button_verifyCode"
                      type="button"
                      onClick={onClickVerify}
                    >
                      {intl.formatMessage({
                        id: "verify",
                        defaultMessage: "Verify",
                      })}
                    </cs.BigButton>
                    <cs.BigButton
                      cancel
                      id="dw_signup_button_forward_signup"
                      type="button"
                      className="return"
                      onClick={onClickVerifyCancel}
                    >
                      {intl.formatMessage({
                        id: "return_to_signup",
                        defaultMessage: "Return to Sign-Up Page",
                      })}
                    </cs.BigButton>
                  </cs.MarginBottom>

                  {/* <div className="infomation">
                <div className="content">
                  {intl.formatMessage({
                    id: "signup_information",
                    defaultMessage:
                      "By signing up you agree to a bunch of stuff that we, at Digital Watchdog cameup with for various legal reasons. Chances are you aren't reading this but you probably should. I mean it is pretty important. That's why it's here. Yes sir.",
                  })}
                </div>
              </div> */}
                </s.SuccessInner>
              </cs.LoginBox>
            )}
            {step === SIGNUP_STEP.RESET_PASSWORD && (
              <cs.LoginBox>
                <div className="title">Reset Password</div>
                <cs.EmailBadge>{checkedEmail}</cs.EmailBadge>

                <cs.InputWrapper>
                  {!isExpiredToken && (
                    <>
                      <s.PasswordInputInner>
                        <Input
                          id="dw_signup_input_password"
                          ref={passwordRef}
                          type="password"
                          autoComplete="new-password"
                          className="input-field"
                          placeholder="Password"
                          value={userInfo.password}
                          onChange={(e) =>
                            onChangeUserInfo(
                              UPDATE_TYPE.PASSWORD,
                              e.target.value
                            )
                          }
                          isValidated={isValidated.password}
                          onFocus={() => setIsFocus(true)}
                          onBlur={() => setIsFocus(false)}
                        />
                        {isFocus && (
                          <s.PasswordBubble>
                            <PasswordBubble password={userInfo.password} />
                          </s.PasswordBubble>
                        )}
                      </s.PasswordInputInner>

                      <s.PasswordInputInner>
                        <Input
                          id="dw_signup_input_confirmPassword"
                          ref={confirmPasswordRef}
                          type="password"
                          autoComplete="new-password"
                          className="input-field"
                          placeholder="Confirm Password"
                          value={userInfo.confirmPassword}
                          onChange={(e) =>
                            onChangeUserInfo(
                              UPDATE_TYPE.CONFIRM_PASSWORD,
                              e.target.value
                            )
                          }
                          onBlur={(e) => onblurConfirmPassword()}
                          isValidated={isValidConfirm}
                          autoFocus={isValidConfirm}
                        />
                      </s.PasswordInputInner>
                    </>
                  )}
                  {isValidConfirm && (
                    <cs.Bottom>
                      <ValidateWord>
                        {intl.formatMessage({
                          id: "validateMsg.signup.passwordMismatch",
                          defaultMessage: "Password you entered mismatch",
                        })}
                      </ValidateWord>
                    </cs.Bottom>
                  )}
                  {isValidPassword && (
                    <cs.Bottom>
                      <ValidateWord>
                        {intl.formatMessage({
                          id: "validateMsg.reset.fail",
                          defaultMessage: "Failed to reset password.",
                        })}
                      </ValidateWord>
                    </cs.Bottom>
                  )}
                  <cs.Bottom>
                    <ValidateWord>{validateMsg}</ValidateWord>
                  </cs.Bottom>
                </cs.InputWrapper>

                {/* strength 없어져서 주석 처리*/}
                {/* <div className="strength">
              <div className="indicator">
                {[...Array(passwordStatus + 1)].map((_e) => (
                  <div className={`block ${validInfo.className}`}></div>
                ))}
              </div>
              <div className={`desc ${validInfo.className}`}>
                {intl.formatMessage({
                  id: "password_strength",
                  defaultMessage: "Password Strength",
                })}
                : {validInfo.desc}
              </div>
            </div> */}
                <div className="submit">
                  {!isExpiredToken && (
                    <cs.BigButton
                      id="dw_signup_button_resetPassword"
                      cancel={isExpiredToken}
                      onClick={() => {
                        if (!isExpiredToken) {
                          onClickPassword();
                        }
                      }}
                    >
                      {intl.formatMessage({
                        id: "reset_password",
                        defaultMessage: "Reset Password",
                      })}
                    </cs.BigButton>
                  )}
                  {isExpiredToken && (
                    <cs.BigButton
                      id="dw_signup_button_forward_login"
                      type="button"
                      onClick={onClickBackLogin}
                    >
                      {intl.formatMessage({
                        id: "email_sent_back",
                        defaultMessage: "Back to Login Screen",
                      })}
                    </cs.BigButton>
                  )}
                </div>
              </cs.LoginBox>
            )}
            {step === SIGNUP_STEP.RESET_PASSWORD_SUCCESS && (
              <cs.LoginBox>
                {/* <s.SuccessInner> */}
                <div className="title">PASSWORD RESET SUCCESSFUL</div>
                <div className="description">
                  Your password has been reset. <br />
                  You can now go back to the login screen and login to myDW.
                </div>

                <cs.BigButton
                  id="dw_signup_button_forward_login"
                  type="button"
                  onClick={onClickBackLogin}
                >
                  {intl.formatMessage({
                    id: "email_sent_back",
                    defaultMessage: "Back to Login Screen",
                  })}
                </cs.BigButton>
                {/* </s.SuccessInner> */}
              </cs.LoginBox>
            )}
            {step === SIGNUP_STEP.INPUT_INFO && (
              <cs.LoginBox>
                {/* <div id="signup-passowrd-speech-bubble">
                  <div>
                    Password must contain:
                    <ul>
                      <li>One lowercase letter (a-z)</li>
                      <li>One uppercase letter (A-Z)</li>
                      <li>One number (0-9)</li>
                      <li>One Special Character (@#! etc.)</li>
                    </ul>
                  </div>
                </div> */}

                <div className="title">SIGN UP</div>
                {/* <div className="id-reserved">ID: {checkedEmail}</div> */}

                <s.InputWrapper>
                  <Input
                    id="dw_signup_input_email"
                    type="text"
                    className="input-field"
                    defaultValue={checkedEmail}
                    //value="dwadmin@digital-watchdog.com"
                    disabled
                  />
                  <Input
                    id="dw_signup_input_firstName"
                    ref={firstNameRef}
                    type="text"
                    className="input-field"
                    placeholder="First name"
                    value={userInfo.firstName}
                    onChange={(e) =>
                      onChangeUserInfo(UPDATE_TYPE.FIRST_NAME, e.target.value)
                    }
                    onBlur={(e) => onblurConfirmPassword()}
                    isValidated={isValidated.firstName}
                  />
                  <Input
                    id="dw_signup_input_lastName"
                    ref={lastNameRef}
                    type="text"
                    className="input-field"
                    placeholder="Last name"
                    value={userInfo.lastName}
                    onChange={(e) =>
                      onChangeUserInfo(UPDATE_TYPE.LAST_NAME, e.target.value)
                    }
                    onBlur={(e) => onblurConfirmPassword()}
                    isValidated={isValidated.lastName}
                    autoFocus={isValidated.lastName}
                  />
                  <PhoneInput
                    login
                    // selected={props.isFlagSelected}
                    // onSelect={props.onSelectFlag}
                    selected={userInfo.phoneNumber}
                    placeholder="Phone number (Optional)"
                    onChange={(value: string) => {
                      onChangeUserInfo(UPDATE_TYPE.PHONE_NUMBER, value);
                    }}
                    onEnterKeyPress={() => console.log("on enter")}
                    isValidated={isValidated.phoneNnumber}
                  />
                  <s.PasswordInputInner>
                    <Input
                      id="dw_signup_input_password"
                      ref={passwordRef}
                      type="password"
                      autoComplete="new-password"
                      className="input-field"
                      placeholder="Password"
                      value={userInfo.password}
                      onChange={(e) =>
                        onChangeUserInfo(UPDATE_TYPE.PASSWORD, e.target.value)
                      }
                      isValidated={isValidated.password}
                      onFocus={() => setIsFocus(true)}
                      onBlur={() => setIsFocus(false)}
                    />
                    {isFocus && (
                      <s.PasswordBubble>
                        <PasswordBubble password={userInfo.password} />
                      </s.PasswordBubble>
                    )}
                  </s.PasswordInputInner>

                  <Input
                    id="dw_signup_input_confirmPassword"
                    ref={confirmPasswordRef}
                    type="password"
                    className="input-field"
                    placeholder="Confirm Password"
                    value={userInfo.confirmPassword}
                    onChange={(e) =>
                      onChangeUserInfo(
                        UPDATE_TYPE.CONFIRM_PASSWORD,
                        e.target.value
                      )
                    }
                    onBlur={(e) => onblurConfirmPassword()}
                    isValidated={isValidConfirm}
                    autoFocus={isValidConfirm}
                  />
                  {isValidConfirm && (
                    <cs.Bottom>
                      <ValidateWord>
                        Password you entered do not mismatch{" "}
                      </ValidateWord>
                    </cs.Bottom>
                  )}
                  <cs.Bottom>
                    <ValidateWord>{validateMsg}</ValidateWord>
                  </cs.Bottom>
                </s.InputWrapper>
                <s.AgreementWrapper>
                  <input
                    id="dw_signup_input_userAgree"
                    key="signup_input_userAgree"
                    type="checkbox"
                    ref={agreementRef}
                    checked={userInfo.agreement}
                    onBlur={(e) => onblurConfirmPassword()}
                    onChange={(e) => {
                      onChangeUserInfo(UPDATE_TYPE.AGREEMENT, e.target.checked);
                    }}
                  />
                  I agree to the
                  <UnderlineText
                    color={theme === "light" ? COLORS.COMPANY : COLORS.SELECTED}
                    onClick={() => onOpenEULAModal(true)}
                    fontSize={14}
                  >
                    End User License Agreement.
                  </UnderlineText>
                </s.AgreementWrapper>
                <s.SubmitWrapper>
                  {/* <cs.ValidateWrapper>
                    <ValidateWord>{validateMsg}</ValidateWord>
                  </cs.ValidateWrapper> */}
                  <cs.BigButton
                    id="dw_signup_button_signup"
                    onClick={onClickSignup}
                  >
                    Sign Up
                  </cs.BigButton>
                </s.SubmitWrapper>
              </cs.LoginBox>
            )}
          </cs.Container>
        </cs.BG>
        {isOpenEULA && (
          <EULAModal onOpenEULAModal={() => onOpenEULAModal(false)} />
        )}
      </div>
    </Spin>
  );
}
