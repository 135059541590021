import * as s from "./LicenseStyled";
import { Text } from "components/atoms/text/Text";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import Input from "components/atoms/input/Input";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import { useAuth } from "components/Auth";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { ButtonWrapper } from "components/templates/modal/ModalTemplateStyled";
import { LicenseInfo } from "api/interfaces/licenseInterface.interface";
import { useMutation } from "react-query";
import { checkPassword } from "api/authAPI";
import { useCallback, useState } from "react";
import { postChannelLicense, postStorageLicense } from "api/licenseAPI";
import { notify } from "components/atoms/notification/Notification";
import { changeFirstWordToUppercase, formatBytes } from "utils/functions";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { useQueryClient } from "react-query";
import { useIntl } from "react-intl";

export type RequestLicenseType = "none" | "standard" | "premium";
export interface Request {
  current: number;
  requested: number;
  message: string;
  licenseType: RequestLicenseType;
}
interface Props {
  accountId: string;
  requestType: "license" | "storage";
  request: LicenseInfo;
  onChangeModal: () => void;
}

export default function LicenseRequestModal(props: Props): JSX.Element {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const auth = useAuth();
  const [password, setPassword] = useState<string>("");
  const [validateMsg, setValidateMsg] = useState<string>("");
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const mutationCheckPassword = useMutation(checkPassword, {
    onSuccess: (res: any) => {
      if (res.result.isExist) {
        setValidateMsg("");
        setIsValidated(false);
        onPostLicense();
      } else {
        setIsValidated(true);
        setValidateMsg(
          intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          })
        );
      }
    },
    onError: () => {
      setIsValidated(true);
      setValidateMsg(
        intl.formatMessage({
          id: "validateMsg.signup.loginIncorrect",
          defaultMessage: "Incorrect Login Credentials.",
        })
      );
    },
  });

  const mutationStorageLicense = useMutation(postStorageLicense, {
    onSuccess: (res: any) => {
      props.onChangeModal();
      notify(
        "success",
        intl.formatMessage({
          id: "label.license.notify.request.success",
          defaultMessage: "License requested successfully.",
        })
      );
      // console.log(res, "mutationStorageLicense");
    },
    onError: (e: CustomError) => {
      console.log(e);
      if (e.response?.data !== undefined) {
        if (e.response?.data.error === 6001) {
          notify("error", `Error : ${e.response?.data.errorString}`);
          props.onChangeModal();
        } else {
          notify(
            "error",
            intl.formatMessage({
              id: "label.license.notify.request.fail",
              defaultMessage: "Fail to request license.",
            })
          );
        }
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.license.notify.request.fail",
            defaultMessage: "Fail to request license.",
          })
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("license_info");
    },
  });

  const mutationChannelLicense = useMutation(postChannelLicense, {
    onSuccess: (res: any) => {
      props.onChangeModal();
      notify(
        "success",
        intl.formatMessage({
          id: "label.license.notify.request.success",
          defaultMessage: "License requested successfully.",
        })
      );
      // console.log(res, "mutationChannelLicense");
    },
    onError: (e: CustomError) => {
      console.log(e);
      if (e.response?.data !== undefined) {
        if (e.response?.data.error === 6001) {
          notify("error", `Error : ${e.response?.data.errorString}`);
          props.onChangeModal();
        } else {
          notify(
            "error",
            intl.formatMessage({
              id: "label.license.notify.request.fail",
              defaultMessage: "Fail to request license.",
            })
          );
        }
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.license.notify.request.fail",
            defaultMessage: "Fail to request license.",
          })
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("license_info");
    },
  });

  const onPostLicense = () => {
    if (props.requestType === "license") {
      mutationChannelLicense.mutate({
        accountId: props.accountId,
        payload: {
          cartLicenseType: props.request.cartLicenseType,
          channelLicenseQuota: props.request.channelLicenseQuota,
          channelNewAmount: props.request.channelNewAmount,
          channelOldAmount: props.request.channelOldAmount,
          cartMessage: props.request.cartMessage,
        },
      });
    } else {
      mutationStorageLicense.mutate({
        accountId: props.accountId,
        payload: {
          storageQuotaInBytes: props.request.storageQuotaInBytes,
          storageOldAmount: props.request.storageOldAmount,
          storageNewAmount: props.request.storageNewAmount,
          storageMessage: props.request.storageMessage,
        },
      });
    }
  };

  const onClickCheckPassword = useCallback(() => {
    if (password === "") {
      setValidateMsg("PASSWORD IS BLANK");
      setIsValidated(true);
    } else {
      setValidateMsg("");
      setIsValidated(false);

      mutationCheckPassword.mutate({
        payload: {
          email: auth.user?.email,
          password: password,
        },
      });
    }
  }, [auth.user?.email, mutationCheckPassword, password]);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const buttons = (
    <ButtonWrapper>
      <PrimaryButton
        buttonType="cancel"
        label="Cancel"
        onClickBtn={props.onChangeModal}
      />
      <PrimaryButton label="Confirm" onClickBtn={onClickCheckPassword} />
    </ButtonWrapper>
  );

  return (
    <SmallPopupDefaultTemplate button={buttons} onModal={props.onChangeModal}>
      <s.ModalWrapper>
        <Text bold>
          Please enter your login credentials to confirm your request:
        </Text>
        <div className="inner">
          <s.ModalRequestContents>
            <li>
              <span className="title">License Type:&nbsp;</span>
              {props.requestType === "license"
                ? changeFirstWordToUppercase(props.request.cartLicenseType)
                : "Cloud Storage"}
            </li>
            <li>
              <span className="title">Current Amount:&nbsp;</span>
              {props.requestType === "license"
                ? props.request.channelLicenseQuota
                : `${formatBytes(props.request.storageQuotaInBytes)}`}
            </li>
            <li>
              <span className="title">Requested Amount:&nbsp;</span>
              {props.requestType === "license"
                ? props.request.channelNewAmount
                : `${props.request.storageNewAmount}TB`}
            </li>
            <li>
              <span className="title">Message to Installer:&nbsp;</span>
              {props.requestType === "license"
                ? props.request.cartMessage === ""
                  ? "-"
                  : props.request.cartMessage
                : props.request.storageMessage === ""
                ? "-"
                : props.request.storageMessage}
            </li>
          </s.ModalRequestContents>

          <ModalInput label="User ID" noBold>
            <Input disabled value={auth.user.email} />
          </ModalInput>
          <ModalInput
            label="Password"
            noBold
            essential
            isValidated={isValidated}
            validatedWord={validateMsg}
          >
            <Input
              type="password"
              value={password}
              onChange={onChangeInput}
              isValidated={isValidated}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickCheckPassword();
                }
              }}
            />
          </ModalInput>
        </div>
      </s.ModalWrapper>
    </SmallPopupDefaultTemplate>
  );
}
